import * as tslib_1 from "tslib";
import { AdminSettingsService } from './../services/admin-settings/admin-settings.service';
import { SharedService } from './../services/shared/shared.service';
import { ServicesFeatureService } from './../services/services-feature/services-feature.service';
import { BrandsService } from './../services/brands/brands.service';
import { OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Events, MenuController, Platform, ToastController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { ProductService } from '../services/product/product.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { WalletService } from '../services/wallet/wallet.service';
import { ChatBotPage } from '../chat-bot/chat-bot.page';
import { ConfigService } from '../services/config/config.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ReferralService } from '../services/referral/referral.service';
import { encodeURL } from '../services/parseURL';
import { ProductSubscriptionsService } from '../services/product-subscriptions/product-subscriptions.service';
var SidemenuPage = /** @class */ (function () {
    function SidemenuPage(storage, events, router, userService, productService, menuCtrl, socialSharing, platform, toastController, walletService, brandsService, servicesFeatureService, modalController, sharedService, configService, utilsService, referralService, adminSettingsService, productSubscriptionsService) {
        this.storage = storage;
        this.events = events;
        this.router = router;
        this.userService = userService;
        this.productService = productService;
        this.menuCtrl = menuCtrl;
        this.socialSharing = socialSharing;
        this.platform = platform;
        this.toastController = toastController;
        this.walletService = walletService;
        this.brandsService = brandsService;
        this.servicesFeatureService = servicesFeatureService;
        this.modalController = modalController;
        this.sharedService = sharedService;
        this.configService = configService;
        this.utilsService = utilsService;
        this.referralService = referralService;
        this.adminSettingsService = adminSettingsService;
        this.productSubscriptionsService = productSubscriptionsService;
        this.showCategories = false;
        this.categoryPlusClicked = false;
        this.categoriesData = [];
        this.loginStatus = false;
        this.role = 'user';
        this.listOfSubcategories = {};
        this.appStoreUrl = '';
        this.playstoreUrl = '';
        this.isWalletActive = false;
        this.listOfSubcategoriesInView = {};
        this.showBrands = false;
        this.brands = [];
        this.brandPlusClicked = false;
        this.isServiceActive = false;
        this.showCategoriesLoader = false;
        this.showBrandsLoader = false;
        this.subscriptionFeature = false;
        this.RFQFeature = false;
        this.isReferralActive = false;
        this.isMembership = false;
    }
    SidemenuPage.prototype.ngOnInit = function () {
    };
    SidemenuPage.prototype.menuOpen = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var walletSettings, serviceStatus, subSettings, referralSettings, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.storage.get('appUrls').then(function (urls) {
                            if (urls) {
                                if (_this.platform.is('ios')) {
                                    _this.isAndroid = false;
                                    _this.appStoreUrl = urls.appStoreUrl;
                                }
                                else {
                                    _this.isAndroid = true;
                                    _this.playstoreUrl = urls.playstoreUrl;
                                }
                            }
                        });
                        this.storage.get('storeInfo').then(function (data) {
                            _this.storeName = data.storeName;
                            _this.storePhone = data.storePhone;
                        });
                        this.getUserData();
                        setTimeout(function () {
                            var userId = _this.userService.getUserId();
                            if (userId === '') {
                                _this.loginStatus = false;
                            }
                            else {
                                _this.loginStatus = true;
                            }
                        }, 100);
                        this.isPriceReqFeature = this.configService.environment.priceReqFeature;
                        return [4 /*yield*/, this.walletService.getWalletSettings('sidemenu')];
                    case 1:
                        walletSettings = _b.sent();
                        this.isWalletActive = walletSettings && walletSettings.active ? walletSettings.active : false;
                        return [4 /*yield*/, this.servicesFeatureService.getServicesActiveStatus('sidemenu')];
                    case 2:
                        serviceStatus = _b.sent();
                        this.isServiceActive = serviceStatus && serviceStatus.isActive ? serviceStatus.isActive : false;
                        if (!this.configService.environment.subscriptionFeature) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.productSubscriptionsService.getSettings('return')];
                    case 3:
                        subSettings = _b.sent();
                        this.subscriptionFeature = subSettings && subSettings.isActive ? true : false;
                        _b.label = 4;
                    case 4:
                        this.RFQFeature = this.configService.environment.RFQFeature;
                        return [4 /*yield*/, this.referralService.getReferralSettings('sidemenu')];
                    case 5:
                        referralSettings = _b.sent();
                        this.isReferralActive = referralSettings && referralSettings.active ? referralSettings.active : false;
                        this.isMembership = this.configService.environment.membership;
                        this.appointmentFeature = this.configService.environment.appointmentFeature;
                        _a = this;
                        return [4 /*yield*/, this.brandsService.getAllBrandsForSideMenu()];
                    case 6:
                        _a.brands = (_b.sent()) || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    SidemenuPage.prototype.menuClose = function () {
    };
    SidemenuPage.prototype.getUserData = function () {
        var _this = this;
        this.storage.get('userName').then(function (name) {
            _this.userName = name;
        });
        this.storage.get('userPic').then(function (url) {
            _this.userPic = url;
        });
        this.storage.get('userRole').then(function (role) {
            if (!role) {
                _this.role = 'user';
            }
            else {
                _this.role = role;
            }
        });
    };
    SidemenuPage.prototype.goToPage = function (page) {
        this.menuCtrl.close();
        this.router.navigate([page]);
    };
    SidemenuPage.prototype.getAllCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.categoryPlusClicked === false)) return [3 /*break*/, 2];
                        this.showCategories = true;
                        _a = this;
                        return [4 /*yield*/, this.productService.getAllCategoriesForSideMenu()];
                    case 1:
                        _a.categoriesData = _b.sent();
                        //// console.log('categoriesData', this.categoriesData);
                        this.categoryPlusClicked = true;
                        return [3 /*break*/, 3];
                    case 2:
                        this.showCategories = false;
                        this.categoryPlusClicked = false;
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SidemenuPage.prototype.getSubCategories = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategories;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.listOfSubcategories.hasOwnProperty(cid)) return [3 /*break*/, 2];
                        subcategories = [];
                        return [4 /*yield*/, this.productService.getSubcategoriesInNewProduct(cid)];
                    case 1:
                        subcategories = _a.sent();
                        this.listOfSubcategories[cid] = subcategories;
                        //// console.log('listOfSubcategories', this.listOfSubcategories);
                        this.listOfSubcategoriesInView[cid] = { active: true };
                        return [3 /*break*/, 3];
                    case 2:
                        if (!this.listOfSubcategoriesInView[cid].active) {
                            this.listOfSubcategoriesInView[cid].active = true;
                        }
                        else {
                            this.listOfSubcategoriesInView[cid].active = false;
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SidemenuPage.prototype.urlSlug = function (value) {
        return encodeURL(value);
    };
    SidemenuPage.prototype.onClickCategory = function (category) {
        var cname = this.urlSlug(category.name);
        // this.sharedService.manageExclusiveCategories(category.isExclusive || false, category.id, 'parentCategory');
        if (category.isSubcategories) {
            this.router.navigate(["shop-subcategories/" + cname + "/" + category.id]);
        }
        else {
            this.router.navigate(["shop/category/" + cname + "/" + category.id]);
        }
    };
    SidemenuPage.prototype.onClickSubcategory = function (category, subcat) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // let exclusiveObj = await this.storage.get('exclusiveObj');
                // const isExclusive = exclusiveObj.categoryType === 'parentCategory' && exclusiveObj.exclusivity ? false : category.isExclusive;
                // this.sharedService.manageExclusiveCategories(isExclusive || false, category.id, 'subCategory');
                this.router.navigate(['/shop/category/', this.urlSlug(category.name), category.id, this.urlSlug(subcat.name), subcat.id]);
                return [2 /*return*/];
            });
        });
    };
    SidemenuPage.prototype.getAllBrands = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.brandPlusClicked === false)) return [3 /*break*/, 3];
                        this.showBrandsLoader = true;
                        if (!!this.brands.length) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.brandsService.getAllBrandsForSideMenu()];
                    case 1:
                        _a.brands = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.showBrands = true;
                        this.brandPlusClicked = true;
                        this.showBrandsLoader = false;
                        return [3 /*break*/, 4];
                    case 3:
                        this.showBrands = false;
                        this.brandPlusClicked = false;
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SidemenuPage.prototype.onClickBrand = function (brand) {
        this.menuCtrl.close();
        var brandname = encodeURL(brand.name);
        this.router.navigate(["shop/brand/" + brandname + "/" + brand.id]);
        //this.router.navigate(['shop'], navigationExtras);
    };
    SidemenuPage.prototype.logout = function () {
        this.menuCtrl.close();
        this.events.publish('auth:logout');
        this.events.publish('makeUnreadAdminMsgsZero');
    };
    SidemenuPage.prototype.openLoginModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.menuCtrl.close();
                this.utilsService.openLoginModal();
                return [2 /*return*/];
            });
        });
    };
    SidemenuPage.prototype.openChatModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ChatBotPage,
                            cssClass: "chat-modal",
                            showBackdrop: false,
                            backdropDismiss: false
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SidemenuPage.prototype.shareApp = function () {
        var appUrl = '';
        if (this.isAndroid) {
            appUrl = this.playstoreUrl;
        }
        else {
            appUrl = this.appStoreUrl;
        }
        if (appUrl) {
            this.socialSharing.share(this.storeName + ' link:', '', this.configService.environment.appSharingImg, appUrl).then(function () {
                //// console.log('app sharing works!');
            }).catch(function () {
                //// console.log('app sharing not works!');
            });
        }
        else {
            this.presentToast('There is some problem in sharing of app.');
        }
    };
    SidemenuPage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SidemenuPage;
}());
export { SidemenuPage };
