import * as tslib_1 from "tslib";
import { Events } from "@ionic/angular";
import { AngularFirestore, } from "@angular/fire/firestore";
import { first, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LogglyLoggerService } from "../loggly-logger/loggly-logger.service";
import { ConfigService } from "../config/config.service";
import { SharedService } from "../shared/shared.service";
import { Storage } from "@ionic/storage";
import { convertSnaps } from "../db-utils";
import { SearchEngineService } from "../search-engine/search-engine.service";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../config/config.service";
import * as i5 from "../shared/shared.service";
import * as i6 from "@ionic/storage";
import * as i7 from "../search-engine/search-engine.service";
var FiltersService = /** @class */ (function () {
    function FiltersService(events, afs, logglyService, configService, sharedService, storage, searchEngineService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.configService = configService;
        this.sharedService = sharedService;
        this.storage = storage;
        this.searchEngineService = searchEngineService;
        this.productsData = [];
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.allFilters = "";
        this.callCount = 0;
        this.filtersRef = this.afs
            .collection("features")
            .doc("filters")
            .collection("list");
    }
    FiltersService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe("filters:sortByAttribute", function (id, type, attribute, sortingOrder) {
            _this.sortByAttribute(id, type, attribute, sortingOrder);
        });
        this.events.subscribe("filters:sortLoadMoreProducts", function (id, type, attribute, sortingOrder) {
            _this.sortLoadMoreProducts(id, type, attribute, sortingOrder);
        });
        this.events.subscribe("filters:filterByAttributes", function (data, id, type, page) {
            _this.filterByAttributes(data, id, type, page);
        });
        // admin
        this.events.subscribe("filters:saveFilter", function (filterData) {
            _this.saveFilter(filterData);
        });
        this.events.subscribe("filters:toggleFiltersActive", function (status) {
            _this.toggleFiltersActive(status);
        });
        this.events.subscribe("filters:getActiveStatus", function () {
            _this.getActiveStatus();
        });
        this.events.subscribe("filters:getAllFilters", function () {
            _this.getAllFilters();
        });
        this.events.subscribe("filters:toggleSingleFilterActive", function (status, id) {
            _this.toggleSingleFilterActive(status, id);
        });
        this.events.subscribe("filters:deleteFilter", function (id) {
            _this.deleteFilter(id);
        });
        this.events.subscribe("filters:getAllActiveFilters", function () {
            _this.getAllActiveFilters();
        });
        this.events.subscribe("product:removeSusbcriptions", function () {
            if (_this.productSub) {
                // console.log('in removeSusbcriptions unsubscribe');
                _this.productSub.unsubscribe();
            }
        });
    };
    FiltersService.prototype.sortByAttribute = function (id, type, attribute, sortingOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productRef, region, vendorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // console.log('in getProducts', id, type);
                        this.productsData = [];
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        vendorId = region.vendorId;
                        productRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("" + type, "array-contains", id)
                                .where("status", "==", true)
                                .orderBy(attribute, sortingOrder)
                                .limit(_this.configService.environment.shopProductsLimit);
                        });
                        if (vendorId && vendorId !== "") {
                            productRef = this.afs.collection("products", function (ref) {
                                return ref
                                    .where("" + type, "array-contains", id)
                                    .where("status", "==", true)
                                    .where("vendorId", "==", vendorId)
                                    .orderBy(attribute, sortingOrder)
                                    .limit(_this.configService.environment.shopProductsLimit);
                            });
                        }
                        this.productSub = productRef.snapshotChanges().subscribe(function (response) {
                            if (!response.length) {
                                // console.log('No Data Available');
                                _this.events.publish("product:noProductAvailable");
                                return false;
                            }
                            _this.productsData = [];
                            _this.lastInResponse = response[response.length - 1].payload.doc;
                            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                                var product = response_1[_i];
                                _this.productsData.push({
                                    id: product.payload.doc.id,
                                    data: product.payload.doc.data(),
                                });
                            }
                            // console.log('productsData in product service', this.productsData);
                            if (_this.productsData.length !== 0) {
                                // console.log('publishProducts');
                                _this.events.publish("product:publishProducts", _this.productsData);
                            }
                            else {
                                // console.log('noDataAvailable');
                                _this.events.publish("product:noProductAvailable");
                            }
                        }, function (error) {
                            console.dir(error);
                            error["location"] = "filters-service:sortByAttribute";
                            _this.logglyService.log(error);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.sortLoadMoreProducts = function (id, type, attribute, sortingOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productRef, region, vendorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        vendorId = region.vendorId;
                        productRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("" + type, "array-contains", id)
                                .where("status", "==", true)
                                .orderBy(attribute, sortingOrder)
                                .limit(_this.configService.environment.shopProductsLimit)
                                .startAfter(_this.lastInResponse);
                        });
                        if (vendorId && vendorId !== "") {
                            productRef = this.afs.collection("products", function (ref) {
                                return ref
                                    .where("" + type, "array-contains", id)
                                    .where("status", "==", true)
                                    .where("vendorId", "==", vendorId)
                                    .orderBy(attribute, sortingOrder)
                                    .limit(_this.configService.environment.shopProductsLimit)
                                    .startAfter(_this.lastInResponse);
                            });
                        }
                        productRef.snapshotChanges().subscribe(function (response) {
                            if (!response.length) {
                                // console.log('No Data Available');
                                _this.events.publish("product:productsLimitReached");
                                return false;
                            }
                            _this.lastInResponse = response[response.length - 1].payload.doc;
                            // console.log('response in loadmore', response);
                            for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                                var product = response_2[_i];
                                _this.productsData.push({
                                    id: product.payload.doc.id,
                                    data: product.payload.doc.data(),
                                });
                            }
                            // console.log('load more products', this.productsData);
                            _this.events.publish("product:publishProducts", _this.productsData);
                        }, function (error) {
                            error["location"] = "filters-service:sortLoadMoreProducts";
                            _this.logglyService.log(error);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.getCategoryName = function (categoryID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var category;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs
                            .collection("categories")
                            .doc(categoryID)
                            .valueChanges()
                            .pipe(first())
                            .toPromise()];
                    case 1:
                        category = _a.sent();
                        // console.log('filter category', categoryID, category)
                        return [2 /*return*/, category.name || ""];
                }
            });
        });
    };
    //algolia filter
    // async filterByAttributes(data: any, id: string, type: string, page: number) {
    //     if (page === 0) {
    //         this.productsData = [];
    //         const region = await this.sharedService.checkRegionIdForApi();
    //         let vendorId = region.vendorId;
    //         let regionId = region.regionId;
    //         let filters = `status:true AND ${type}:"${id}" AND discountedPrice:${data.priceRange.lower} TO ${data.priceRange.upper} AND discount:${data.discountRange[0]} TO ${data.discountRange[1]}`;
    //         if (data.ratingRange[0] > 0) {
    //             filters += ` AND rating.avgRating:${data.ratingRange[0]} TO ${data.ratingRange[1]}`;
    //         }
    //         if (data.parentFilterObj.ids.length) {
    //             if (data.parentFilterObj.ids.length === 1) {
    //                 filters += ` AND ${data.parentFilterObj.type}:"${data.parentFilterObj.ids[0]}"`;
    //             } else {
    //                 let idFilter = '';
    //                 data.parentFilterObj.ids.map((id, index) => {
    //                     if (index === 0) {
    //                         idFilter += ` (${data.parentFilterObj.type}:"${id}"`;
    //                     } else if (index > 0 && index !== data.parentFilterObj.ids.length - 1) {
    //                         idFilter += ` OR ${data.parentFilterObj.type}:"${id}"`;
    //                     } else {
    //                         idFilter += ` OR ${data.parentFilterObj.type}:"${id}")`;
    //                     }
    //                 });
    //                 filters += ` AND ${idFilter}`;
    //             }
    //         }
    //         if (regionId) {
    //             filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`
    //         }
    //         if (vendorId) {
    //             filters += ` AND vendorId:${vendorId}`
    //         }
    //         if (data.adminFilters.length) {
    //             let adminFilters = {};
    //             data.adminFilters.forEach(filter => {
    //                 filter.values.forEach(v => {
    //                     if (v.isChecked) {
    //                         if (adminFilters.hasOwnProperty(filter.name)) {
    //                             let valArr = adminFilters[filter.name];
    //                             valArr.push(v.value);
    //                             adminFilters[filter.name] = valArr;
    //                         } else {
    //                             adminFilters[filter.name] = [v.value];
    //                         }
    //                     }
    //                 });
    //             });
    //             let keys = Object.keys(adminFilters);
    //             if (keys.length > 0) {
    //                 keys.forEach(key => {
    //                     let values = adminFilters[key];
    //                     let valueFilter = ' AND';
    //                     values.map((value, index) => {
    //                         if (values.length === 1) {
    //                             valueFilter += ` (filters.${key}:"${value}")`
    //                         } else {
    //                             if (index === 0) {
    //                                 valueFilter += ` (filters.${key}:"${value}"`;
    //                             } else if (index > 0 && index !== values.length - 1) {
    //                                 valueFilter += ` OR filters.${key}:"${value}"`;
    //                             } else {
    //                                 valueFilter += ` OR filters.${key}:"${value}")`;
    //                             }
    //                         }
    //                     });
    //                     filters += valueFilter;
    //                 });
    //             }
    //         }
    //         this.allFilters = filters;
    //     }
    //     // console.log('allFilters', this.allFilters);
    //     this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
    //     this.index = this.client.initIndex(this.APP_PROJECT_ID);
    //     this.index.search('', { page: page, filters: this.allFilters }).then((result) => {
    //         // console.log(result);
    //         if (result.nbPages === 0) {
    //             this.events.publish('product:noProductAvailable');
    //         } else if (result.hits.length === 0 && page === result.nbPages) {
    //             this.events.publish('product:productsLimitReached');
    //         } else {
    //             result.hits.forEach(h => {
    //                 this.productsData.push({ id: h.objectID, data: h });
    //             });
    //             console.log('this.productsData',this.productsData)
    //             this.events.publish('product:publishProducts', this.productsData);
    //         }
    //     }).catch(async (error) => {
    //         console.dir(error);
    //         error['location'] = 'filters-service:filterByAttributes';
    //         this.logglyService.log(error);
    //     });
    // }
    //typesense filter
    FiltersService.prototype.filterByAttributes = function (data, id, type, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters_1, adminFilters_1, keys, res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page += 1;
                        if (page === 1) {
                            this.productsData = [];
                            filters_1 = "status:true && " + type + ":=" + id + " && discountedPrice:=[" + data.priceRange.lower + ".." + data.priceRange.upper + "] && discount:=[" + data.discountRange[0] + ".." + data.discountRange[1] + "]";
                            if (data.ratingRange[0] > 0) {
                                filters_1 += " && rating.avgRating:=[" + data.ratingRange[0] + ".." + data.ratingRange[1] + "]";
                            }
                            // if (vendorId) {
                            //     filters += ` && vendorId:=${vendorId}`;
                            // }
                            if (data.parentFilterObj.ids.length) {
                                filters_1 += " && " + data.parentFilterObj.type + ":=[" + data.parentFilterObj.ids + "]";
                            }
                            // if (regionId) {
                            //     filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
                            // }
                            if (data.adminFilters.length) {
                                adminFilters_1 = {};
                                data.adminFilters.forEach(function (filter) {
                                    filter.values.forEach(function (v) {
                                        if (v.isChecked) {
                                            if (adminFilters_1.hasOwnProperty(filter.name)) {
                                                var valArr = adminFilters_1[filter.name];
                                                valArr.push(v.value);
                                                adminFilters_1[filter.name] = valArr;
                                            }
                                            else {
                                                adminFilters_1[filter.name] = [v.value];
                                            }
                                        }
                                    });
                                });
                                keys = Object.keys(adminFilters_1);
                                if (keys.length > 0) {
                                    keys.forEach(function (key) {
                                        var values = adminFilters_1[key];
                                        var valueFilter = " &&";
                                        values.map(function (value, index) {
                                            if (index === 0) {
                                                valueFilter += " filters." + key + ":=\"" + value + "\"";
                                            }
                                            else if (index > 0 && index !== values.length - 1) {
                                                valueFilter += " && filters." + key + ":=\"" + value + "\"";
                                            }
                                            else {
                                                valueFilter += " && filters." + key + ":=\"" + value + "\"";
                                            }
                                        });
                                        filters_1 += valueFilter;
                                    });
                                }
                            }
                            this.allFilters = filters_1;
                        }
                        console.log("allFilters", this.allFilters);
                        if (!this.configService.environment.useTypesense) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.searchEngineService.getSearchProductsFromTypesense("*", page, type, this.allFilters)];
                    case 1:
                        res = _a.sent();
                        if (res.status === "available") {
                            res.products.forEach(function (product) {
                                product.data = product;
                                _this.productsData.push(product);
                            });
                            this.events.publish("product:publishProducts", this.productsData);
                        }
                        else if (res.status === "no_products") {
                            this.events.publish("product:noProductAvailable");
                        }
                        else {
                            this.events.publish("product:productsLimitReached");
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.saveFilter = function (filterData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filterId, filterClone, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        filterId = "";
                        filterClone = JSON.parse(JSON.stringify(filterData));
                        if (filterClone.hasOwnProperty("id")) {
                            filterId = filterClone.id;
                            delete filterData.id;
                        }
                        else {
                            filterId = this.filtersRef.ref.doc().id;
                        }
                        return [4 /*yield*/, this.filtersRef.doc(filterId).set(filterData)];
                    case 1:
                        _a.sent();
                        this.events.publish("filters:filterSaved");
                        this.events.publish("filters:getAllFilters");
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1["location"] = "filters-service:saveFilter";
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.toggleFiltersActive = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("features")
                                .doc("filters")
                                .set({ active: status })];
                    case 1:
                        _a.sent();
                        this.events.publish("filters:filtersActiveChanged");
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2["location"] = "filters-service:toggleFiltersActive";
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.getActiveStatus = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filtersDoc, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("features")
                                .doc("filters")
                                .valueChanges()
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        filtersDoc = _a.sent();
                        if (route === "service") {
                            return [2 /*return*/, filtersDoc];
                        }
                        else {
                            this.events.publish("filters:publishActiveStatus", filtersDoc);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        error_3["location"] = "filters-service:getActiveStatus";
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.getAllFilters = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.filtersRef
                                .snapshotChanges()
                                .pipe(map(function (actions) {
                                return actions.map(function (a) {
                                    var data = a.payload.doc.data();
                                    var id = a.payload.doc.id;
                                    return tslib_1.__assign({ id: id }, data);
                                });
                            }))
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        filters = _a.sent();
                        // console.log('filters', filters);
                        this.events.publish("filters:publishAllFilters", filters);
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        error_4["location"] = "filters-service:getAllFilters";
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.toggleSingleFilterActive = function (status, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.filtersRef.doc(id).update({ active: status })];
                    case 1:
                        _a.sent();
                        this.events.publish("filters:singleFilterActiveChanged");
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        error_5["location"] = "filters-service:toggleSingleFilterActive";
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.deleteFilter = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.filtersRef.doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish("filters:filterDeleted");
                        this.events.publish("filters:getAllFilters");
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6["location"] = "filters-service:deleteFilter";
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.getAllActiveFilters = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("features")
                                .doc("filters")
                                .collection("list", function (ref) { return ref.where("active", "==", true); })
                                .snapshotChanges()
                                .pipe(map(function (actions) {
                                return actions.map(function (a) {
                                    var data = a.payload.doc.data();
                                    var id = a.payload.doc.id;
                                    return tslib_1.__assign({ id: id }, data);
                                });
                            }))
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        filters = _a.sent();
                        // console.log('filters', filters);
                        return [2 /*return*/, filters];
                    case 2:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        error_7["location"] = "filters-service:getAllActiveFilters";
                        this.logglyService.log(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FiltersService.prototype.getCategoriesWithSubcategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                list = [];
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var categories, _i, categories_1, c, subcategories, sublist, _a, subcategories_1, sc;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, this.afs
                                        .collection("categories", function (ref) {
                                        return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                                    })
                                        .snapshotChanges()
                                        .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                        .pipe(first())
                                        .toPromise()];
                                case 1:
                                    categories = _b.sent();
                                    _i = 0, categories_1 = categories;
                                    _b.label = 2;
                                case 2:
                                    if (!(_i < categories_1.length)) return [3 /*break*/, 6];
                                    c = categories_1[_i];
                                    if (!c.isSubcategories) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.getSubcategories(c.id)];
                                case 3:
                                    subcategories = _b.sent();
                                    sublist = [];
                                    if (subcategories.length) {
                                        for (_a = 0, subcategories_1 = subcategories; _a < subcategories_1.length; _a++) {
                                            sc = subcategories_1[_a];
                                            sublist.push({ id: sc.id, name: sc.name, active: false });
                                        }
                                    }
                                    list.push({ id: c.id, name: c.name, sublist: sublist, active: false });
                                    return [3 /*break*/, 5];
                                case 4:
                                    list.push({ id: c.id, name: c.name, sublist: [], active: false });
                                    _b.label = 5;
                                case 5:
                                    _i++;
                                    return [3 /*break*/, 2];
                                case 6:
                                    resolve(list);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    FiltersService.prototype.getSubcategories = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var subcategories;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.afs
                                        .collection("categories")
                                        .doc(cid)
                                        .collection("subcategories", function (ref) {
                                        return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                                    })
                                        .snapshotChanges()
                                        .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                        .pipe(first())
                                        .toPromise()];
                                case 1:
                                    subcategories = _a.sent();
                                    resolve(subcategories);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    FiltersService.prototype.getBrands = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                list = [];
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var brands, _i, brands_1, b;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.afs
                                        .collection("brands", function (ref) {
                                        return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                                    })
                                        .snapshotChanges()
                                        .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                        .pipe(first())
                                        .toPromise()];
                                case 1:
                                    brands = _a.sent();
                                    for (_i = 0, brands_1 = brands; _i < brands_1.length; _i++) {
                                        b = brands_1[_i];
                                        list.push({ id: b.id, name: b.name, sublist: [], active: false });
                                    }
                                    resolve(list);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    FiltersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FiltersService_Factory() { return new FiltersService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.SharedService), i0.ɵɵinject(i6.Storage), i0.ɵɵinject(i7.SearchEngineService)); }, token: FiltersService, providedIn: "root" });
    return FiltersService;
}());
export { FiltersService };
