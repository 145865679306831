import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
var AdminSettingsService = /** @class */ (function () {
    function AdminSettingsService(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
    }
    AdminSettingsService.prototype.initializeSubscriptions = function () {
        //('admin service initializeSubscriptions')
        var _this = this;
        this.events.subscribe('admin-settings:saveSettingsData', function (data, authSign, shopLogo) {
            _this.saveSettingsData(data, authSign, shopLogo);
        });
        this.events.subscribe('admin-settings:getSettingsData', function () {
            _this.getSettingsData();
        });
        this.events.subscribe('admin-settings:getInvoiceData', function () {
            _this.getInvoiceData();
        });
        this.events.subscribe('admin-settings:getStatesData', function () {
            _this.getStatesData();
        });
        this.events.subscribe('admin-settings:savePaymentSettingsData', function (data) {
            _this.savePaymentSettingsData(data);
        });
        this.events.subscribe('admin-settings:getPaytmData', function () {
            _this.getPaytmData();
        });
        this.events.subscribe('admin-settings:getRazorPayData', function () {
            _this.getRazorPayData();
        });
        this.events.subscribe('admin-settings:getPaymentInfoData', function () {
            _this.getPaymentInfoData();
        });
        this.events.subscribe('admin-settings:saveTermsAndPrivacy', function (data) {
            _this.saveTermsAndPrivacy(data);
        });
        this.events.subscribe('admin-settings:getTermsAndPrivacyData', function () {
            //// console.log('service subscribe admin-settings:getTermsAndPrivacyData')
            _this.getTermsAndPrivacyData();
        });
        this.events.subscribe('admin-settings:getStoreInfo', function () {
            _this.getStoreInfo();
        });
        this.events.subscribe('admin-settings:getPlanDetails', function () {
            _this.getPlanDetails();
        });
        this.events.subscribe('admin-settings:getSubscribedStoreInfo', function () {
            _this.getSubscribedStoreInfo();
        });
        this.events.subscribe('admin-settings:getWebsiteSEOData', function () {
            _this.getSEOData();
        });
        this.getAppInfo();
        this.getStoreInfo;
    };
    AdminSettingsService.prototype.getSEOData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var seoData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('seo').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        seoData = _a.sent();
                        //// console.log(seoData);
                        if (seoData) {
                            this.events.publish('admin-settings:publishSEOData', seoData);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.saveSettingsData = function (data, authSign, shopLogo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settingsRef_1, invoiceRef_1, invoice_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    settingsRef_1 = this.afs.collection('settings').doc('store');
                    invoiceRef_1 = this.afs.collection('settings').doc('invoice');
                    settingsRef_1.get().subscribe(function (doc) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!doc.exists) return [3 /*break*/, 2];
                                    return [4 /*yield*/, settingsRef_1.update(data)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, settingsRef_1.set(data)];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); });
                    invoice_1 = {
                        shopLogo: shopLogo,
                        signature: authSign
                    };
                    invoiceRef_1.get().subscribe(function (doc) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!doc.exists) return [3 /*break*/, 2];
                                    return [4 /*yield*/, invoiceRef_1.update(invoice_1)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, invoiceRef_1.set(invoice_1)];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); });
                    this.events.publish('admin-settings:saveSettingsDataSuccess');
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AdminSettingsService.prototype.getSettingsData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var settingsData, error_2;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    settingsData = _a.sent();
                                    console.log(settingsData);
                                    resolve(settingsData);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_2 = _a.sent();
                                    console.dir(error_2);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AdminSettingsService.prototype.getInvoiceData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var invoiceData, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('invoice').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        invoiceData = _a.sent();
                        //// console.log(invoiceData);
                        this.events.publish('admin-settings:publishInvoiceData', invoiceData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getStatesData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var statesData, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('states').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        statesData = _a.sent();
                        //// console.log(statesData);
                        this.events.publish('admin-settings:publishStatesData', statesData.codes);
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.savePaymentSettingsData = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paytmRef, razorpayRef, infoRef, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        paytmRef = this.afs.collection('payment').doc('paytm');
                        razorpayRef = this.afs.collection('payment').doc('razorpay');
                        infoRef = this.afs.collection('payment').doc('info');
                        return [4 /*yield*/, paytmRef.set({
                                active: data.paytmActive,
                                merchantId: data.paytmMerchantId,
                                key: data.paytmKey
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, razorpayRef.set({
                                active: data.razorpayActive,
                                id: data.razorpayId,
                                keySecret: data.razorpayKeySecret
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, infoRef.set({
                                autoConfirmOrder: data.autoConfirmOrder,
                                gstNo: data.gstNo,
                                defaultGst: data.defaultGst,
                                minOrderAmount: data.minOrderAmount,
                                maxOrderAmount: data.maxOrderAmount,
                                isPackagingCharges: data.isPackagingCharges,
                                packaging: {
                                    label: data.packaging.label,
                                    price: data.packaging.price
                                },
                                isGstApplicable: data.isGstApplicable,
                                panNo: data.panNo,
                                isCod: data.isCod
                            })];
                    case 3:
                        _a.sent();
                        this.events.publish('admin-settings:savePaymentSettingsDataSuccess');
                        return [3 /*break*/, 5];
                    case 4:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getPaytmData = function (callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paytmData, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc('paytm').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        paytmData = _a.sent();
                        if (callType && callType === 'return') {
                            return [2 /*return*/, paytmData];
                        }
                        else {
                            this.events.publish('admin-settings:publishPaytmData', paytmData);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getRazorPayData = function (callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var razorpayData, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc('razorpay').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        razorpayData = _a.sent();
                        if (callType && callType === 'return') {
                            return [2 /*return*/, razorpayData];
                        }
                        else {
                            this.events.publish('admin-settings:publishRazorPayData', razorpayData);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getPaymentInfoData = function (callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var infoData, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc('info').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        infoData = _a.sent();
                        if (callType && callType === 'return') {
                            return [2 /*return*/, infoData];
                        }
                        else {
                            this.events.publish('admin-settings:publishPaymentInfoData', infoData);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.saveTermsAndPrivacy = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settingsRef_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    settingsRef_2 = this.afs.collection('settings').doc('policies');
                    settingsRef_2.get().subscribe(function (doc) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!doc.exists) return [3 /*break*/, 2];
                                    return [4 /*yield*/, settingsRef_2.update({
                                            terms: data.terms,
                                            privacy: data.privacy
                                        })];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, settingsRef_2.set({
                                        terms: data.terms,
                                        privacy: data.privacy
                                    })];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); });
                    this.events.publish('admin-settings:saveTermsAndPrivacySuccess');
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AdminSettingsService.prototype.getTermsAndPrivacyData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var policiesData, error_9;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('policies').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        policiesData = _a.sent();
                        //// console.log('getTermsAndPrivacyData', policiesData);
                        this.storage.set('policiesData', policiesData).then(function (result) {
                            //// console.log('policiesData set to storage')
                            _this.events.publish('admin-settings:publishTermsAndPrivacyData');
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getPlanDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.afs.collection('settings').doc('plan').valueChanges()
                        .subscribe(function (res) {
                        _this.events.publish('admin-settings:publishPlanDetails', res);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AdminSettingsService.prototype.getStoreInfo = function (callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storeInfo, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        storeInfo = _a.sent();
                        this.storage.set('storeInfo', storeInfo);
                        if (callType === 'return') {
                            return [2 /*return*/, storeInfo];
                        }
                        else {
                            this.events.publish('admin-settings:publishStoreInfo');
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_10 = _a.sent();
                        console.dir(error_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getStoreInfoDataInSessionStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(sessionStorage.getItem("storeInfoData")) || {};
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("storeInfoData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AdminSettingsService.prototype.getSubscribedStoreInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.afs.collection('settings').doc('store')
                        .valueChanges()
                        .subscribe(function (response) {
                        _this.storage.set('storeInfo', response);
                        _this.events.publish('admin-settings:storeInfo', response);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AdminSettingsService.prototype.getAppInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.afs.collection('settings').doc('app').valueChanges()
                        .subscribe(function (res) {
                        _this.storage.set('appInfo', res).then(function (result) {
                            _this.events.publish('admin-settings:publishAppInfo');
                        });
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AdminSettingsService.prototype.getStripeData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stripeData, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc('stripe').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        stripeData = _a.sent();
                        return [2 /*return*/, stripeData && Object.keys(stripeData).length ? stripeData : { active: false }];
                    case 2:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        return [2 /*return*/, { active: false }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.prototype.getPaymentGateway = function (gatewayChoice) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var gatewayData, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc(gatewayChoice).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        gatewayData = _a.sent();
                        return [2 /*return*/, gatewayData && Object.keys(gatewayData).length ? gatewayData : { active: false }];
                    case 2:
                        error_12 = _a.sent();
                        console.dir(error_12);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminSettingsService_Factory() { return new AdminSettingsService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: AdminSettingsService, providedIn: "root" });
    return AdminSettingsService;
}());
export { AdminSettingsService };
