import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { DragulaModule } from 'ng2-dragula';
import { AngularFireModule } from '@angular/fire';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { from } from 'rxjs';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ImageModalPageModule } from './image-modal/image-modal.module';
import { IonicStorageModule } from '@ionic/storage';
// import { WebView } from '@ionic-native/ionic-webview/ngx';
import { SidemenuPage } from './sidemenu/sidemenu.page';
// import { IonicImageLoader } from 'ionic-image-loader';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { CKEditorModule } from 'ng2-ckeditor';
import { PricelistModalPageModule } from './pricelist-modal/pricelist-modal.module';
//import { FileOpener } from '@ionic-native/file-opener/ngx';
//import { File } from '@ionic-native/file/ngx';
import { HomePageModule } from './home/home.module';
import { SelectAddressPageModule } from './select-address/select-address.module';
import { OrderPaymentPageModule } from './order-payment/order-payment.module';
import { ChatBotPageModule } from './chat-bot/chat-bot.module';
import { NewAddressPageModule } from './new-address/new-address.module';
import { AutoConfirmPaymentPageModule } from './auto-confirm-payment/auto-confirm-payment.module';
import { StatesModalPageModule } from './states-modal/states-modal.module';
import { OfferDetailsPageModule } from './offer-details/offer-details.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ConfigService } from './services/config/config.service';
import { APP_INITIALIZER } from '@angular/core';
import { PromoModalPageModule } from './promo-modal/promo-modal.module';
import { StarRatingModule } from 'ionic4-star-rating';
import { RateProductPageModule } from './rating/rate-product/rate-product.module';
import { CreateSubscriptionPageModule } from './user-product-subscription/create-subscription/create-subscription.module';
import { ShopFiltersPageModule } from './shop-filters/shop-filters.module';
import { VideoModalPageModule } from './pages/video-modal/video-modal.module';
import { SelectLanguagePageModule } from './pages/select-language/select-language.module';
import { UpiManualPaymentPageModule } from './pages/upi-manual-payment/upi-manual-payment.module';
import { CancelledReasonPageModule } from './pages/cancelled-reason/cancelled-reason.module';
import { CouponsListPageModule } from './pages/coupons-list/coupons-list.module';
import { RateOrderPageModule } from './rating/rate-order/rate-order.module';
import { CustomPaymentOptionPageModule } from './pages/custom-payment-option/custom-payment-option.module';
import { StripePageModule } from './pages/payment-ui/stripe/stripe.module';
import { PaypalPageModule } from './pages/payment-ui/paypal/paypal.module';
import { PickDropMapPageModule } from './pick-and-drop/pick-drop-map/pick-drop-map.module';
import { PickDropDetailsPageModule } from './pick-and-drop/pick-drop-details/pick-drop-details.module';
import { MapPageModule } from './new-address/map/map.module';
import { PlaceModalPageModule } from './pages/place-modal/place-modal.module';
import { AppointmentSchedulesPageModule } from './appointment-schedules/appointment-schedules.module';
import { VendorInvoicesPageModule } from './pages/vendor-invoices/vendor-invoices.module';
import { TestPagePageModule } from './pages/test-page/test-page.module';
import { SizeChartModalPageModule } from './pages/size-chart-modal/size-chart-modal.module';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SelectAddonPageModule } from './pages/select-addon/select-addon.module';
import { RepeatAddonChoicePageModule } from './pages/select-addon/repeat-addon-choice/repeat-addon-choice.module';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const appConfig = (config: ConfigService) => {
  return () => {
    return config.loadConfig();
  };
};



@NgModule({
  declarations: [AppComponent, SidemenuPage],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot({ mode: 'md' }), AppRoutingModule, FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    ImageModalPageModule, IonicStorageModule.forRoot(),
    PricelistModalPageModule,
    ReactiveFormsModule,
    SuperTabsModule.forRoot(),
    CKEditorModule,
    HomePageModule,
    SelectAddressPageModule,
    OrderPaymentPageModule,
    ChatBotPageModule,
    NewAddressPageModule,
    AutoConfirmPaymentPageModule,
    StatesModalPageModule,
    OfferDetailsPageModule,
    PromoModalPageModule,
    RateProductPageModule,
    CreateSubscriptionPageModule,
    VideoModalPageModule,
    SelectLanguagePageModule,
    ShopFiltersPageModule,
    HttpClientModule,
    StarRatingModule,
    UpiManualPaymentPageModule,
    CustomPaymentOptionPageModule,
    CancelledReasonPageModule,
    SelectAddonPageModule,
    CouponsListPageModule,
    RateOrderPageModule,
    RateProductPageModule,
    StripePageModule,
    PaypalPageModule,
    PickDropMapPageModule,
    PickDropDetailsPageModule,
    MapPageModule,
    PlaceModalPageModule,
    AppointmentSchedulesPageModule,
    VendorInvoicesPageModule,
    TestPagePageModule,
    SizeChartModalPageModule,
    RepeatAddonChoicePageModule,
    Ng2TelInputModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FirebaseAuthentication,
    Keyboard,
    SocialSharing,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig,
      multi: true,
      deps: [ConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
