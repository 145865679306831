import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { convertSnaps } from '../db-utils';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { UtilsService } from '../utils/utils.service';
import { LabelService } from '../label/label.service';
import { ConfigService } from '../config/config.service';
import * as moment from 'moment';
import { VendorService } from '../vendor/vendor.service';
import { Router } from '@angular/router';
import { SharedNewService } from '../shared-new/shared-new.service';
import { RepeatAddonChoicePage } from 'src/app/pages/select-addon/repeat-addon-choice/repeat-addon-choice.page';
import { SelectAddonPage } from 'src/app/pages/select-addon/select-addon.page';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../loggly-logger/loggly-logger.service";
import * as i3 from "@ionic/storage";
import * as i4 from "@ionic/angular";
import * as i5 from "../label/label.service";
import * as i6 from "../utils/utils.service";
import * as i7 from "../config/config.service";
import * as i8 from "../vendor/vendor.service";
import * as i9 from "@angular/router";
import * as i10 from "../shared-new/shared-new.service";
var CartService = /** @class */ (function () {
    function CartService(afs, logglyService, storage, alertController, labelService, loadingController, utilsService, configService, vendorService, router, sharedNewService, modalController) {
        this.afs = afs;
        this.logglyService = logglyService;
        this.storage = storage;
        this.alertController = alertController;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.utilsService = utilsService;
        this.configService = configService;
        this.vendorService = vendorService;
        this.router = router;
        this.sharedNewService = sharedNewService;
        this.modalController = modalController;
        this.cartProductsUpdated = new Subject();
        this.cartProducts = [];
        this.userRef = this.afs.collection('users');
        this.currentCategory = null;
        this.exclusiveVendorId = '';
    }
    CartService.prototype.setCartProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var role, uid, cartRef, cartProducts, _a, cartStorage, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 9, , 10]);
                        return [4 /*yield*/, this.storage.get('userRole')];
                    case 1:
                        role = _b.sent();
                        if (!(role === 'user')) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        uid = _b.sent();
                        if (!(uid && uid !== undefined)) return [3 /*break*/, 5];
                        cartRef = this.afs.collection('users').doc(uid).collection('cart');
                        return [4 /*yield*/, cartRef.snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 3:
                        cartProducts = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.getCartProductsFromStorage(cartProducts)];
                    case 4:
                        _a.cartProducts = _b.sent();
                        this.cartProductsUpdated.next(this.cartProducts.slice());
                        this.setExclusivity();
                        console.log('cart in cartService ->', this.cartProducts);
                        return [2 /*return*/, this.cartProducts];
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.storage.get('cart')];
                    case 7:
                        cartStorage = _b.sent();
                        console.log('cartStorage', cartStorage);
                        if (cartStorage && cartStorage.length) {
                            this.cartProducts = cartStorage;
                            this.cartProductsUpdated.next(this.cartProducts.slice());
                            this.setExclusivity();
                            return [2 /*return*/, this.cartProducts];
                        }
                        _b.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        err_1 = _b.sent();
                        console.dir(err_1);
                        err_1['location'] = 'cart-service:setUserCartProducts';
                        this.logglyService.log(err_1);
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.getCartProductsFromStorage = function (dbCartProducts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cartStorage, _loop_1, _i, cartStorage_1, cartStorageObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('cart')];
                    case 1:
                        cartStorage = _a.sent();
                        if (cartStorage && cartStorage.length) {
                            _loop_1 = function (cartStorageObj) {
                                if (dbCartProducts && dbCartProducts.length) {
                                    var sameProducts = dbCartProducts.filter(function (dbCart) { return dbCart.productId === cartStorageObj.productId; });
                                    var isMultipleProduct_1 = false;
                                    if (sameProducts.length) {
                                        if (!cartStorageObj.pack) {
                                            isMultipleProduct_1 = true;
                                        }
                                        else {
                                            sameProducts.forEach(function (p) {
                                                if (p.pack && (p.pack.weight === cartStorageObj.pack.weight)) {
                                                    isMultipleProduct_1 = true;
                                                }
                                            });
                                        }
                                    }
                                    if (!isMultipleProduct_1) {
                                        dbCartProducts.push(cartStorageObj);
                                    }
                                }
                                else {
                                    dbCartProducts.push(cartStorageObj);
                                }
                            };
                            for (_i = 0, cartStorage_1 = cartStorage; _i < cartStorage_1.length; _i++) {
                                cartStorageObj = cartStorage_1[_i];
                                _loop_1(cartStorageObj);
                            }
                        }
                        return [2 /*return*/, dbCartProducts];
                }
            });
        });
    };
    CartService.prototype.fetchCartProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, cartRef, cartProducts;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        uid = _a.sent();
                        cartRef = this.afs.collection('users').doc(uid).collection('cart');
                        return [4 /*yield*/, cartRef.snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                    case 2:
                        cartProducts = _a.sent();
                        return [2 /*return*/, cartProducts];
                }
            });
        });
    };
    CartService.prototype.getCartProductsListener = function () {
        return this.cartProductsUpdated.asObservable();
    };
    CartService.prototype.getCartProducts = function () {
        return this.cartProducts;
    };
    CartService.prototype.updateCartProduct = function (cartID, qty) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, index, updatedData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        uid = _a.sent();
                        if (!uid)
                            return [2 /*return*/];
                        index = this.cartProducts.findIndex(function (c) { return c.id === cartID; });
                        updatedData = {
                            quantity: qty
                        };
                        if ('addOns' in this.cartProducts[index]) {
                            updatedData = tslib_1.__assign({}, this.cartProducts[index]);
                            if (updatedData.cartUpdateTimer) {
                                delete updatedData.cartUpdateTimer;
                            }
                        }
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).collection('cart').doc(cartID).update(updatedData)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.deleteCartProduct = function (cartID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        uid = _a.sent();
                        index = this.cartProducts.findIndex(function (c) { return c.id === cartID; });
                        this.cartProducts.splice(index, 1);
                        this.cartProductsUpdated.next(this.cartProducts.slice());
                        if (!this.cartProducts.length) {
                            this.exclusiveVendorId = '';
                        }
                        if (!uid) {
                            this.storage.set('cart', this.cartProducts.slice());
                            return [2 /*return*/];
                        }
                        ;
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).collection('cart').doc(cartID).delete()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.updateCartState = function (cartID, qty) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index, updatedProductWithAddonsIfAny, uid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        index = this.cartProducts.findIndex(function (c) { return c.id === cartID; });
                        return [4 /*yield*/, this.repeatOrAddNewAddons(this.cartProducts[index], qty)];
                    case 1:
                        updatedProductWithAddonsIfAny = _a.sent();
                        console.log('updatedProductWithAddonsIfAny', updatedProductWithAddonsIfAny);
                        if (!updatedProductWithAddonsIfAny) {
                            return [2 /*return*/, false];
                        }
                        this.cartProducts[index] = updatedProductWithAddonsIfAny;
                        this.cartProducts[index].quantity = qty;
                        this.cartProductsUpdated.next(this.cartProducts.slice());
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        uid = _a.sent();
                        if (!uid) {
                            this.storage.set('cart', this.cartProducts.slice());
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    CartService.prototype.repeatOrAddNewAddons = function (product, updatedQty) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastAddonIndex, lastAddon, choice, _a, addOnsPrice_1, addOns, lastAddonPrice_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!product.addOns || !Object.keys(product.addOns.options).length) {
                            return [2 /*return*/, product];
                        }
                        lastAddonIndex = Object.keys(product.addOns.options).length - 1;
                        lastAddon = product.addOns.options[lastAddonIndex];
                        if (!(product.quantity < updatedQty)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.getRepeatOrAddNewAddonsChoice({
                                name: product.name, lastAddon: lastAddon
                            })];
                    case 1:
                        choice = _b.sent();
                        _a = choice;
                        switch (_a) {
                            case 'repeat': return [3 /*break*/, 2];
                            case 'new': return [3 /*break*/, 3];
                            case '': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 6];
                    case 2:
                        addOnsPrice_1 = 0;
                        lastAddon.forEach(function (addOn) {
                            addOn.quantity += 1;
                            addOn.options.forEach(function (option) {
                                addOnsPrice_1 += option.price;
                            });
                        });
                        product.addOns.totalPrice += addOnsPrice_1;
                        product.addOns.options[lastAddonIndex] = lastAddon;
                        // product.price += addOnsPrice;
                        return [3 /*break*/, 6];
                    case 3: return [4 /*yield*/, this.getAddonsIfAny(tslib_1.__assign({}, product, { quantity: 1 }))];
                    case 4:
                        addOns = _b.sent();
                        if (addOns.selectedOptions.length > 0) {
                            product.addOns.options[Object.keys(product.addOns.options).length] = addOns.selectedOptions;
                            product.addOns.totalPrice += addOns.totalPrice;
                            // product.price += addOns.totalPrice;
                            return [3 /*break*/, 6];
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        _b.label = 5;
                    case 5: return [2 /*return*/, null];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        lastAddonPrice_1 = 0;
                        lastAddon.forEach(function (addOn) {
                            addOn.options.forEach(function (option) {
                                lastAddonPrice_1 += option.price;
                            });
                        });
                        lastAddon[0].quantity -= 1;
                        if (lastAddon[0].quantity === 0) {
                            delete product.addOns.options[lastAddonIndex];
                        }
                        product.addOns.totalPrice -= lastAddonPrice_1;
                        _b.label = 8;
                    case 8: return [2 /*return*/, product];
                }
            });
        });
    };
    CartService.prototype.getRepeatOrAddNewAddonsChoice = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var modal;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.modalController.create({
                                        component: RepeatAddonChoicePage,
                                        cssClass: 'repeat-addon-modal',
                                        componentProps: tslib_1.__assign({}, data)
                                    })];
                                case 1:
                                    modal = _a.sent();
                                    modal.onDidDismiss().then(function (res) {
                                        if (res && res.data && res.data.choice) {
                                            resolve(res.data.choice);
                                        }
                                        else {
                                            resolve('');
                                        }
                                    });
                                    return [4 /*yield*/, modal.present()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.presentCartLoader = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                cssClass: 'cart-loader',
                                spinner: null,
                                duration: 1000
                            })];
                    case 1:
                        _a.cartLoader = _b.sent();
                        return [4 /*yield*/, this.cartLoader.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.addProductToCart = function (cartObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var action, addOns, uid, cartId, cartRef;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.presentCartLoader()];
                    case 1:
                        _a.sent();
                        action = 'add';
                        if (this.productAlreadyAddedToCart(cartObj)) {
                            action = 'not_add';
                            this.alreadyAddedAlert();
                        }
                        if (!(action === 'add')) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.checkCartExclusivity(cartObj)];
                    case 2:
                        action = _a.sent();
                        if (!(action === 'add')) return [3 /*break*/, 8];
                        if (!!('addOns' in cartObj)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getAddonsIfAny(cartObj)];
                    case 3:
                        addOns = _a.sent();
                        console.log('addOns', addOns);
                        if (addOns.required) {
                            if (addOns.selectedOptions.length > 0) {
                                cartObj.addOns = {
                                    totalPrice: addOns.totalPrice,
                                    options: { 0: addOns.selectedOptions }
                                };
                                // cartObj.price += addOns.totalPrice; 
                            }
                            else {
                                return [2 /*return*/];
                            }
                        }
                        _a.label = 4;
                    case 4:
                        console.log('cartObj', cartObj);
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 5:
                        uid = _a.sent();
                        cartId = '';
                        if (!uid) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).collection('cart').add(cartObj)];
                    case 6:
                        cartRef = _a.sent();
                        cartId = cartRef.id;
                        _a.label = 7;
                    case 7:
                        if (!cartId) {
                            cartId = this.getCartIdIfNoLogin(cartObj);
                        }
                        this.cartProducts.push(tslib_1.__assign({ id: cartId }, cartObj));
                        this.cartProductsUpdated.next(this.cartProducts.slice());
                        if (!uid) {
                            this.storage.set('cart', this.cartProducts.slice());
                        }
                        return [2 /*return*/, cartId];
                    case 8:
                        if (this.cartLoader) {
                            this.cartLoader.dismiss();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.getAddonsIfAny = function (cartObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var addOns, template, modal;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    addOns = { totalPrice: 0, selectedOptions: [], required: false };
                                    if (!cartObj.templateId) return [3 /*break*/, 5];
                                    return [4 /*yield*/, this.presentLoading()];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, this.sharedNewService.getTemplateData(cartObj.templateId)];
                                case 2:
                                    template = _a.sent();
                                    this.loading.dismiss();
                                    if (!template) {
                                        resolve(addOns);
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, this.modalController.create({
                                            component: SelectAddonPage,
                                            // cssClass: 'region-modal',
                                            componentProps: {
                                                item: {
                                                    name: cartObj.name,
                                                    price: cartObj.price,
                                                    id: cartObj.productId,
                                                    templateId: cartObj.templateId,
                                                    description: cartObj.prodShortDesc,
                                                    mrp: cartObj.mrpPrice,
                                                    quantity: cartObj.quantity
                                                },
                                                template: template
                                            }
                                        })];
                                case 3:
                                    modal = _a.sent();
                                    modal.onDidDismiss().then(function (res) {
                                        if (res && res.data && !res.data.closed) {
                                            addOns = res.data;
                                        }
                                        addOns.required = true;
                                        resolve(addOns);
                                    });
                                    return [4 /*yield*/, modal.present()];
                                case 4:
                                    _a.sent();
                                    return [3 /*break*/, 6];
                                case 5:
                                    resolve(addOns);
                                    _a.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.getCartIdIfNoLogin = function (cartObj) {
        if (!('pack' in cartObj)) {
            return cartObj.productId;
        }
        else {
            return cartObj.productId + "-variant" + cartObj.pack.weight;
        }
    };
    CartService.prototype.alreadyAddedAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.alreadyAddedAlertPresent) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.alertController.create({
                                message: this.labelService.labels['PRODUCT_DETAILS']['already_added_to_cart'],
                                buttons: [{
                                        text: this.labelService.labels['PRODUCT_DETAILS']['continue_alert_btn'],
                                        handler: function () { }
                                    }, {
                                        text: this.labelService.labels['PRODUCT_DETAILS']['go_to_cart_alert_btn'],
                                        handler: function () {
                                            _this.router.navigate(['user-cart']);
                                        }
                                    }]
                            })];
                    case 1:
                        alert_1 = _a.sent();
                        this.alreadyAddedAlertPresent = true;
                        return [4 /*yield*/, alert_1.present()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        setTimeout(function () {
                            _this.alreadyAddedAlertPresent = false;
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.productAlreadyAddedToCart = function (cartObj) {
        var added = false;
        if ('pack' in cartObj) {
            added = this.cartProducts.some(function (c) { return c.productId === cartObj.productId && c.pack.weight === cartObj.pack.weight; });
        }
        else {
            added = this.cartProducts.some(function (c) { return c.productId === cartObj.productId; });
        }
        return added;
    };
    CartService.prototype.getCartPrice = function (cartProducts) {
        var price = 0;
        for (var _i = 0, cartProducts_1 = cartProducts; _i < cartProducts_1.length; _i++) {
            var pdt = cartProducts_1[_i];
            if (pdt.status && (pdt.totalQty === '0' && !pdt.stopWhenNoQty || pdt.totalQty !== '0')) {
                price += (pdt.price * pdt.quantity) + ('addOns' in pdt ? pdt.addOns.totalPrice : 0);
            }
        }
        return price;
    };
    CartService.prototype.removeHtml = function (desc) {
        var tagsRemoved = desc.replace(/(<([^>]+)>)/ig, '');
        var plainTxt = document.createElement('textarea');
        plainTxt.innerHTML = tagsRemoved;
        return plainTxt.value;
    };
    CartService.prototype.isProductInCart = function (product) {
        if (!product.hasOwnProperty('cartId') || product.cartId === '') {
            return false;
        }
        else {
            return this.cartProducts.some(function (c) { return c.id === product.cartId; });
        }
    };
    CartService.prototype.checkCartAvailability = function (inactiveByVendor) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var role;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (inactiveByVendor) {
                            this.presentAlert('', "" + this.labelService.labels['SHARED']['vendor_product_inactive']);
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.storage.get('userRole')];
                    case 1:
                        role = _a.sent();
                        if (role === 'admin') {
                            this.presentAlert('', 'Cart is available only for user not for admin.');
                            return [2 /*return*/, false];
                        }
                        else if (role === 'deliveryAgent') {
                            this.presentAlert('', 'Cart is available only for user not for delivery agent.');
                            return [2 /*return*/, false];
                        }
                        else {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.labelService.labels['SHARED']['please_wait'],
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.presentAlert = function (subHeader, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            subHeader: subHeader,
                            message: msg,
                            buttons: [this.labelService.labels['SHARED']['ok']],
                            cssClass: 'alert-msg-w-header'
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.resetCart = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var batch, uid, cartRef, _i, _a, product;
                        var _this = this;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    batch = this.afs.firestore.batch();
                                    return [4 /*yield*/, this.storage.get('uid')];
                                case 1:
                                    uid = _b.sent();
                                    cartRef = this.afs.collection('users').doc(uid).collection('cart');
                                    for (_i = 0, _a = this.cartProducts; _i < _a.length; _i++) {
                                        product = _a[_i];
                                        batch.delete(cartRef.doc(product.id).ref);
                                    }
                                    batch.commit().then(function () {
                                        _this.cartProducts = [];
                                        _this.cartProductsUpdated.next([]);
                                        resolve(true);
                                    }).catch(function (error) {
                                        console.log(error);
                                        resolve(false);
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.checkCartExclusivity = function (cartObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var res, exclusiveVendors, isExclusive, res, res;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(!cartObj.vendorId && this.exclusiveVendorId)) return [3 /*break*/, 2];
                                    console.log('no vendorId but cart is exc');
                                    return [4 /*yield*/, this.showReplaceAlert()];
                                case 1:
                                    res = _a.sent();
                                    if (res === 'add') {
                                        this.exclusiveVendorId = '';
                                    }
                                    resolve(res);
                                    return [3 /*break*/, 12];
                                case 2:
                                    if (!(!cartObj.vendorId && !this.exclusiveVendorId)) return [3 /*break*/, 3];
                                    console.log('no vendorid and cart is not exc');
                                    resolve('add');
                                    return [3 /*break*/, 12];
                                case 3:
                                    exclusiveVendors = this.vendorService.vendorSettings.exclusiveVendors;
                                    if (!exclusiveVendors.length) return [3 /*break*/, 11];
                                    if (!!this.cartProducts.length) return [3 /*break*/, 4];
                                    if (cartObj.vendorId && exclusiveVendors.includes(cartObj.vendorId)) {
                                        console.log('product is exc and no product in cart');
                                        this.exclusiveVendorId = cartObj.vendorId;
                                    }
                                    resolve('add');
                                    return [3 /*break*/, 10];
                                case 4:
                                    if (!(this.exclusiveVendorId === cartObj.vendorId)) return [3 /*break*/, 5];
                                    console.log('same vendor product');
                                    resolve('add');
                                    return [3 /*break*/, 10];
                                case 5:
                                    isExclusive = exclusiveVendors.includes(cartObj.vendorId);
                                    if (!isExclusive) return [3 /*break*/, 7];
                                    console.log('product is exc and cart has products');
                                    return [4 /*yield*/, this.showReplaceAlert()];
                                case 6:
                                    res = _a.sent();
                                    if (res === 'add') {
                                        this.exclusiveVendorId = cartObj.vendorId;
                                    }
                                    resolve(res);
                                    return [3 /*break*/, 10];
                                case 7:
                                    console.log('product is not exc');
                                    if (!this.exclusiveVendorId) return [3 /*break*/, 9];
                                    console.log('but cart is exc');
                                    return [4 /*yield*/, this.showReplaceAlert()];
                                case 8:
                                    res = _a.sent();
                                    if (res === 'add') {
                                        this.exclusiveVendorId = '';
                                    }
                                    resolve(res);
                                    return [3 /*break*/, 10];
                                case 9:
                                    console.log('and cart is also not exc');
                                    this.exclusiveVendorId = '';
                                    resolve('add');
                                    _a.label = 10;
                                case 10: return [3 /*break*/, 12];
                                case 11:
                                    console.log('no exc vendors');
                                    this.exclusiveVendorId = '';
                                    resolve('add');
                                    _a.label = 12;
                                case 12:
                                    console.log('this.exclusiveVendorId', this.exclusiveVendorId);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.showReplaceAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var confirm, res;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.replaceCartAlert()];
                                case 1:
                                    confirm = _a.sent();
                                    if (!confirm) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.presentLoading()];
                                case 2:
                                    _a.sent();
                                    return [4 /*yield*/, this.resetCart()];
                                case 3:
                                    res = _a.sent();
                                    this.loading.dismiss();
                                    if (res) {
                                        resolve('add');
                                    }
                                    else {
                                        this.presentAlert(this.labelService.labels['SHARED']['oops'], this.labelService.labels['SHARED']['some_issue_msg']);
                                        resolve('not_add');
                                    }
                                    return [3 /*break*/, 5];
                                case 4:
                                    resolve('not_add');
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.replaceCartAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var alert;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.alertController.create({
                                        subHeader: this.labelService.labels['CART_SERVICE']['replace_alert_header'],
                                        message: this.labelService.labels['CART_SERVICE']['replace_alert_msg'],
                                        cssClass: 'alert-msg-w-header',
                                        buttons: [
                                            {
                                                text: this.labelService.labels['CART_SERVICE']['no'],
                                                handler: function () { return resolve(false); }
                                            },
                                            {
                                                text: this.labelService.labels['CART_SERVICE']['yes'],
                                                handler: function () { return resolve(true); }
                                            }
                                        ]
                                    })];
                                case 1:
                                    alert = _a.sent();
                                    return [4 /*yield*/, alert.present()];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.setExclusivity = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cartVendorId, exclusiveVendors, isExclusive;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cartVendorId = '';
                        this.cartProducts.forEach(function (product) {
                            if (product.vendorId) {
                                cartVendorId = product.vendorId;
                            }
                        });
                        return [4 /*yield*/, this.vendorService.getAllExclusiveVendors()];
                    case 1:
                        exclusiveVendors = _a.sent();
                        if (exclusiveVendors.length) {
                            isExclusive = exclusiveVendors.includes(cartVendorId);
                            if (isExclusive) {
                                this.exclusiveVendorId = cartVendorId;
                            }
                        }
                        else {
                            this.exclusiveVendorId = '';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.getCartObj = function (product, productID, quantity) {
        var cartObj = {
            name: product.prodName,
            quantity: quantity || 1,
            img: product.coverPic,
            productId: productID,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.maxQty ? product.maxQty : 0,
            minQty: product.minQty ? product.minQty : 1,
            gst: product.gst ? product.gst : 0,
            status: typeof product.status !== 'undefined' ? product.status : true,
            stopWhenNoQty: product.hasOwnProperty('stopWhenNoQty') && typeof product.stopWhenNoQty !== 'undefined' ? product.stopWhenNoQty : false,
            totalQty: product.productQty ? product.productQty : '',
            hsn: product.hsnCode ? product.hsnCode : '',
            sku: product.productCode ? product.productCode : '',
            barcode: product.barcode ? product.barcode : '',
            shippingWt: product.shippingWeight || 0,
            barcodeNo: product.barcodeNo || '',
            gstExclusive: product.gstExclusive || false,
            extraCharges: ('extraCharges' in product) && (typeof product.extraCharges === 'object') && product.extraCharges.active ? product.extraCharges : { charge: 0 },
            isCod: 'isCod' in product ? product.isCod : true,
            vendorId: product.vendorId || '',
            priceSlabs: 'priceSlabs' in product ? { active: product.priceSlabs.active } : { active: false },
            templateId: product.templateId || ''
        };
        var desc = this.removeHtml(product.prodDesc);
        cartObj['description'] = desc;
        if (product.discountedPrice && (product.discountedPrice !== product.prodPrice)) {
            cartObj['mrpPrice'] = product.prodPrice;
            cartObj['price'] = product.discountedPrice;
        }
        else {
            cartObj['price'] = product.prodPrice;
        }
        if (product.hasOwnProperty('color') && product.color.hasOwnProperty('name')) {
            cartObj['color'] = product.color;
        }
        cartObj = this.priceSlabsCheck(cartObj, product);
        return cartObj;
    };
    CartService.prototype.getPriceListCartObj = function (product, variantIndex, quantity) {
        if (variantIndex === void 0) { variantIndex = 0; }
        if (quantity === void 0) { quantity = 1; }
        var cartObj = {
            name: product.data.prodName,
            quantity: quantity,
            img: this.getCoverPic(product.data, variantIndex),
            description: product.data.priceList[variantIndex].weight,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.data.maxQty ? product.data.maxQty : 0,
            minQty: product.data.minQty ? product.data.minQty : 1,
            gst: product.data.gst ? product.data.gst : 0,
            status: typeof product.data.status !== 'undefined' ? product.data.status : true,
            stopWhenNoQty: product.data.hasOwnProperty('stopWhenNoQty') && typeof product.data.stopWhenNoQty !== 'undefined' ? product.data.stopWhenNoQty : false,
            totalQty: product.data.priceList[variantIndex].totalQuantity ? product.data.priceList[variantIndex].totalQuantity : '',
            hsn: product.data.hsnCode ? product.data.hsnCode : '',
            sku: 'sku' in product.data.priceList[variantIndex] ? product.data.priceList[variantIndex].sku : product.data.productCode ? product.data.productCode : '',
            barcode: product.data.priceList[variantIndex].barcode ? product.data.priceList[variantIndex].barcode : '',
            shippingWt: product.data.priceList[variantIndex].shippingWeight || 0,
            barcodeNo: product.data.priceList[variantIndex].barcodeNo || '',
            gstExclusive: product.data.gstExclusive || false,
            extraCharges: ('extraCharges' in product.data) && (typeof product.data.extraCharges === 'object') && product.data.extraCharges.active ? product.data.extraCharges : { charge: 0 },
            isCod: 'isCod' in product.data ? product.data.isCod : true,
            vendorId: product.data.vendorId || '',
            priceSlabs: 'priceSlabs' in product.data ? { active: product.data.priceSlabs.active } : { active: false },
            templateId: product.data.templateId || '',
            pack: {
                weight: product.data.priceList[variantIndex].weight,
                variantType: product.data.variantType ? product.data.variantType : 'variant'
            }
        };
        if (product.data.variantType && product.data.variantType === 'pieces') {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].discountedPrice;
            }
            else {
                cartObj['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].price;
            }
        }
        else {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price;
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice;
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice;
            }
            else {
                cartObj['price'] = product.data.priceList[variantIndex].price;
                cartObj.pack['price'] = product.data.priceList[variantIndex].price;
            }
        }
        if (product.data.hasOwnProperty('color') && product.data.color.hasOwnProperty('name')) {
            cartObj['color'] = product.data.color;
        }
        if (product.parentProductId) {
            cartObj['parentProductId'] = product.parentProductId;
            cartObj['productId'] = product.id;
        }
        else {
            cartObj['productId'] = product.id;
        }
        cartObj = this.priceSlabsCheck(cartObj, product.data);
        return cartObj;
    };
    CartService.prototype.getCoverPic = function (product, index) {
        var variant = product.priceList[index];
        return 'images' in variant && variant.images.length ? variant.images[0] : product.coverPic;
    };
    CartService.prototype.updatedCartFromBackend = function (cartProducts, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var products, cartPdts, pdtsIds, subPdtIds, pdts, subPdts, updatedCartPdts, subscribedPdts, membership, _i, products_1, p, index, productRef, index, subProductRef, allUpdatedPdts, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 11, , 12]);
                                    products = JSON.parse(JSON.stringify(cartProducts));
                                    cartPdts = [];
                                    pdtsIds = [];
                                    subPdtIds = [];
                                    pdts = [];
                                    subPdts = [];
                                    updatedCartPdts = [];
                                    subscribedPdts = [];
                                    membership = [];
                                    for (_i = 0, products_1 = products; _i < products_1.length; _i++) {
                                        p = products_1[_i];
                                        if (p.orderType === 'subscription') {
                                            subscribedPdts.push(p);
                                        }
                                        else if (p.orderType === 'membership') {
                                            membership.push(p);
                                        }
                                        else {
                                            cartPdts.push(p);
                                            if (!p.parentProductId) {
                                                pdtsIds.push(p.productId);
                                            }
                                            else {
                                                subPdtIds.push({
                                                    parentId: p.parentProductId,
                                                    pid: p.productId
                                                });
                                            }
                                        }
                                    }
                                    if (!pdtsIds.length) return [3 /*break*/, 4];
                                    index = 0;
                                    _a.label = 1;
                                case 1:
                                    if (!(index < pdtsIds.length)) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.afs.collection('products').doc(pdtsIds[index]).valueChanges().pipe(first()).toPromise()];
                                case 2:
                                    productRef = _a.sent();
                                    if (productRef) {
                                        productRef['id'] = pdtsIds[index];
                                        pdts.push(productRef);
                                    }
                                    _a.label = 3;
                                case 3:
                                    index++;
                                    return [3 /*break*/, 1];
                                case 4:
                                    if (!subPdtIds.length) return [3 /*break*/, 8];
                                    index = 0;
                                    _a.label = 5;
                                case 5:
                                    if (!(index < subPdtIds.length)) return [3 /*break*/, 8];
                                    return [4 /*yield*/, this.afs.collection('products').doc(subPdtIds[index].parentId).collection('options').doc(subPdtIds[index].pid).valueChanges().pipe(first()).toPromise()];
                                case 6:
                                    subProductRef = _a.sent();
                                    if (subProductRef) {
                                        subProductRef['id'] = subPdtIds[index].pid;
                                        subPdts.push(subProductRef);
                                    }
                                    _a.label = 7;
                                case 7:
                                    index++;
                                    return [3 /*break*/, 5];
                                case 8:
                                    allUpdatedPdts = pdts.concat(subPdts);
                                    if (!allUpdatedPdts.length) return [3 /*break*/, 10];
                                    return [4 /*yield*/, this.getUpdatedPdts(allUpdatedPdts, cartPdts, page)];
                                case 9:
                                    updatedCartPdts = _a.sent();
                                    _a.label = 10;
                                case 10:
                                    if (subscribedPdts.length > 0) {
                                        updatedCartPdts = updatedCartPdts.concat(subscribedPdts);
                                    }
                                    if (membership.length > 0) {
                                        updatedCartPdts = updatedCartPdts.concat(membership);
                                    }
                                    resolve(updatedCartPdts);
                                    return [3 /*break*/, 12];
                                case 11:
                                    error_1 = _a.sent();
                                    console.dir(error_1);
                                    error_1['location'] = 'cart-service:updatedCartFromBackend';
                                    this.logglyService.log(error_1);
                                    resolve([]);
                                    return [3 /*break*/, 12];
                                case 12: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.getUpdatedPdts = function (pdts, cartPdts, page) {
        var _this = this;
        return new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _loop_2, this_1, _i, cartPdts_1, c;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _loop_2 = function (c) {
                            var productIndex, dbProduct, deal, charge;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        productIndex = pdts.findIndex(function (p) { return p.id === c.productId; });
                                        if (!(productIndex !== -1)) return [3 /*break*/, 2];
                                        dbProduct = pdts[productIndex];
                                        c.name = dbProduct.prodName;
                                        c.maxQty = dbProduct.maxQty ? dbProduct.maxQty : 0;
                                        c.minQty = dbProduct.minQty ? dbProduct.minQty : 1;
                                        c.status = dbProduct.inactiveByVendor ? false : typeof dbProduct.status !== undefined ? dbProduct.status : true;
                                        c.stopWhenNoQty = dbProduct.hasOwnProperty('stopWhenNoQty') && typeof dbProduct.stopWhenNoQty !== undefined ? dbProduct.stopWhenNoQty : false;
                                        c.retailDiscount = dbProduct.hasOwnProperty('retailDiscount') ? dbProduct.retailDiscount : 0;
                                        c.retailDiscountType = dbProduct.retailDiscountType || 'percentage';
                                        c.extraCharges = ('extraCharges' in dbProduct) && (typeof dbProduct.extraCharges === 'object') && dbProduct.extraCharges.active ? dbProduct.extraCharges : { charge: 0 };
                                        c.img = dbProduct.coverPic;
                                        c.priceSlabs = 'priceSlabs' in dbProduct ? { active: dbProduct.priceSlabs.active } : { active: false };
                                        return [4 /*yield*/, this_1.checkLimitedTimeDeal(dbProduct)];
                                    case 1:
                                        deal = _a.sent();
                                        if (deal.dealAllowed) {
                                            if (deal.discount > 0) {
                                                c.dealDiscount = deal.discount;
                                            }
                                        }
                                        if (!c.hasOwnProperty('pack')) {
                                            c.totalQty = dbProduct.productQty ? dbProduct.productQty : '';
                                            if (parseInt(dbProduct.productQty) && (c.quantity > parseInt(dbProduct.productQty))) {
                                                c.quantity = parseInt(dbProduct.productQty);
                                            }
                                            if (dbProduct.discountedPrice && dbProduct.discountedPrice !== dbProduct.prodPrice) {
                                                c.price = dbProduct.discountedPrice;
                                                c.mrpPrice = dbProduct.prodPrice;
                                            }
                                            else {
                                                c.price = dbProduct.prodPrice;
                                            }
                                        }
                                        else {
                                            if (c.pack.variantType !== 'pieces') {
                                                dbProduct.priceList.forEach(function (pl) {
                                                    if (pl.weight === c.pack.weight) {
                                                        c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                                        if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                                            c.quantity = parseInt(pl.totalQuantity);
                                                        }
                                                        if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                                            c.price = pl.discountedPrice;
                                                            c.mrpPrice = pl.price;
                                                            c.pack.price = pl.price;
                                                        }
                                                        else {
                                                            c.price = pl.price;
                                                            c.pack.price = pl.price;
                                                        }
                                                    }
                                                });
                                            }
                                            else {
                                                dbProduct.priceList.forEach(function (pl) {
                                                    if (pl.weight === c.pack.weight) {
                                                        c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                                        if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                                            c.quantity = parseInt(pl.totalQuantity);
                                                        }
                                                        if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                                            c.price = pl.discountedPrice * parseInt(pl.weight);
                                                            c.mrpPrice = pl.price * parseInt(pl.weight);
                                                            c.pack.price = pl.discountedPrice * parseInt(pl.weight);
                                                            c.pack.perPcPrice = pl.discountedPrice;
                                                        }
                                                        else {
                                                            c.price = pl.price * parseInt(pl.weight);
                                                            c.pack.price = pl.price * parseInt(pl.weight);
                                                            c.pack.perPcPrice = pl.price;
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        if ((page && page === 'order-summary') && ('extraCharges' in c) && (c.extraCharges.charge > 0)) {
                                            charge = c.extraCharges.chargeAllQty ? c.extraCharges.charge : c.extraCharges.charge / c.quantity;
                                            c.price += charge;
                                            if ('mrpPrice' in c) {
                                                c.mrpPrice += charge;
                                            }
                                            if ('pack' in c) {
                                                c.pack.price += charge;
                                            }
                                        }
                                        //update price according to price slabs
                                        c = this_1.priceSlabsCheck(c, dbProduct);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        c.status = false;
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, cartPdts_1 = cartPdts;
                        _a.label = 1;
                    case 1:
                        if (!(_i < cartPdts_1.length)) return [3 /*break*/, 4];
                        c = cartPdts_1[_i];
                        return [5 /*yield**/, _loop_2(c)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        resolve(cartPdts);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    CartService.prototype.priceSlabsCheck = function (cartObj, product) {
        if ('priceSlabs' in product && product.priceSlabs.active) {
            if (product.isPriceList) {
                var variantSlabs = product.priceSlabs.variantSlabs || {};
                console.log('variantSlabs', variantSlabs);
                if (Object.keys(variantSlabs).length) {
                    if (Object.keys(variantSlabs).includes(cartObj.pack.weight)) {
                        var slabs = variantSlabs[cartObj.pack.weight];
                        console.log('slabs', slabs);
                        if (slabs.length) {
                            var finalRange = {};
                            for (var _i = 0, slabs_1 = slabs; _i < slabs_1.length; _i++) {
                                var element = slabs_1[_i];
                                console.log('element', element);
                                if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                                    finalRange = element;
                                    break;
                                }
                            }
                            if (Object.keys(finalRange).length) {
                                if (cartObj.pack.variantType === 'pieces') {
                                    cartObj.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.pack.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.mrpPrice = finalRange.mrp;
                                }
                                else {
                                    cartObj.price = finalRange.price;
                                    cartObj.pack.price = finalRange.price;
                                    cartObj.mrpPrice = finalRange.mrp;
                                }
                            }
                        }
                    }
                }
            }
            else {
                var singleSlabs = product.priceSlabs.singleSlabs || {};
                if (Object.keys(singleSlabs).length) {
                    var finalRange = {};
                    for (var _a = 0, singleSlabs_1 = singleSlabs; _a < singleSlabs_1.length; _a++) {
                        var element = singleSlabs_1[_a];
                        if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                            finalRange = element;
                            break;
                        }
                    }
                    if (Object.keys(finalRange).length) {
                        cartObj.price = finalRange.price;
                        cartObj.mrpPrice = finalRange.mrp;
                    }
                }
            }
        }
        return cartObj;
    };
    CartService.prototype.checkLimitedTimeDeal = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var discount, uid_1, index, currentTime, startDate, startTime, endDate, endTime, startDateTime, endDateTime;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data.hasOwnProperty('deal') && data.deal.isAllowed)) return [3 /*break*/, 3];
                        discount = data.deal.discount;
                        if (!('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        uid_1 = _a.sent();
                        index = data.deal.specificUsers.users.findIndex(function (u) { return u.id === uid_1; });
                        if (index === -1) {
                            return [2 /*return*/, { dealAllowed: false, discount: null }];
                        }
                        else {
                            discount = data.deal.specificUsers.users[index].discount;
                        }
                        _a.label = 2;
                    case 2:
                        currentTime = moment();
                        startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
                        startTime = moment(data.deal.start.time).format('HH:mm');
                        endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
                        endTime = moment(data.deal.end.time).format('HH:mm');
                        startDateTime = moment(startDate + " " + startTime);
                        endDateTime = moment(endDate + " " + endTime);
                        if (moment(currentTime).isBetween(startDateTime, endDateTime)) {
                            return [2 /*return*/, { dealAllowed: true, discount: discount }];
                        }
                        else {
                            return [2 /*return*/, { dealAllowed: false, discount: null }];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, { dealAllowed: false, discount: null }];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.compareCartWithUpdatedCart = function (cartProducts, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var priceForRetail, subRole, products, updatedCart, updateFields, cartList, cartUpdated;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        priceForRetail = this.configService.environment.priceForRetail;
                        return [4 /*yield*/, this.storage.get('userSubRole')];
                    case 1:
                        subRole = _a.sent();
                        products = cartProducts.filter(function (p) { return p.orderType !== 'free'; });
                        return [4 /*yield*/, this.updatedCartFromBackend(products, page)];
                    case 2:
                        updatedCart = _a.sent();
                        updateFields = ['maxQty', 'minQty', 'price', 'status', 'gst', 'shippingWt'];
                        updatedCart.map(function (el) {
                            if (el.hasOwnProperty('dealDiscount')) {
                                var actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                                el.price = actualPrice - (actualPrice * (el.dealDiscount / 100));
                                el.mrpPrice = actualPrice;
                            }
                            if (subRole && subRole === 'retailer' && priceForRetail) {
                                var retailDiscount = el.retailDiscount ? el.retailDiscount : 0;
                                var retailDiscountType = el.retailDiscountType || 'percentage';
                                if (retailDiscount) {
                                    var actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                                    if (retailDiscountType === 'percentage') {
                                        el.price = actualPrice - (actualPrice * (retailDiscount / 100));
                                    }
                                    else {
                                        el.price = actualPrice - retailDiscount;
                                    }
                                    el.mrpPrice = actualPrice;
                                }
                            }
                            el.price = _this.sharedNewService.getPriceBasedOnExhangeRate(el.price);
                            el.mrpPrice = _this.sharedNewService.getPriceBasedOnExhangeRate(el.mrpPrice);
                        });
                        cartList = [];
                        cartUpdated = false;
                        products.map(function (cart) {
                            var index = updatedCart.findIndex(function (uc) { return uc.id === cart.id; });
                            if (index !== -1) {
                                console.log('index present', cart.id);
                                var updatedProduct = updatedCart[index];
                                var quantity = updatedProduct.quantity, change = tslib_1.__rest(updatedProduct, ["quantity"]);
                                change['quantity'] = cart.quantity;
                                for (var _i = 0, _a = Object.keys(cart); _i < _a.length; _i++) {
                                    var key = _a[_i];
                                    if (!cartUpdated && updateFields.includes(key)) {
                                        cartUpdated = cart[key] !== change[key];
                                    }
                                    cart[key] = change[key];
                                }
                                cartList.push(cart);
                            }
                            else {
                                console.log('index not present', cart.id);
                                cartUpdated = true;
                            }
                        });
                        return [2 /*return*/, { cartList: cartList, cartUpdated: cartUpdated }];
                }
            });
        });
    };
    CartService.prototype.inventoryManagement = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var manageInventory;
                        return tslib_1.__generator(this, function (_a) {
                            manageInventory = firebase.functions().httpsCallable('orders-manageInventory');
                            manageInventory({ products: data.products, orderId: data.orderId }).then(function (res) {
                                resolve(res.data.status);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    CartService.prototype.getFreeProductLimits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var uid_2, allLimits, limitsRef, limitDoc, limits, _loop_3, this_2, _i, limits_1, limit, error_2;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 8, , 9]);
                                    return [4 /*yield*/, this.storage.get('uid')];
                                case 1:
                                    uid_2 = _a.sent();
                                    allLimits = [];
                                    limitsRef = this.afs.collection('settings').doc('freeProductsLimit');
                                    return [4 /*yield*/, this.afs.collection('settings').doc('freeProductsLimit').valueChanges().pipe(first()).toPromise()];
                                case 2:
                                    limitDoc = _a.sent();
                                    if (!(limitDoc && limitDoc.active)) return [3 /*break*/, 7];
                                    return [4 /*yield*/, limitsRef.collection('limits', function (ref) { return ref.where('active', '==', true); })
                                            .snapshotChanges().pipe(map(function (snaps) { return convertSnaps(snaps); })).pipe(first()).toPromise()];
                                case 3:
                                    limits = _a.sent();
                                    if (!(limits && limits.length)) return [3 /*break*/, 7];
                                    _loop_3 = function (limit) {
                                        var usage, product, variantIndex, freeProduct, freeProduct;
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, limitsRef.collection('limits').doc(limit.id).collection('usage', function (ref) { return ref.where('userId', '==', uid_2); }).valueChanges().pipe(first()).toPromise()];
                                                case 1:
                                                    usage = _a.sent();
                                                    if (!(usage && usage.length < limit.perUser)) return [3 /*break*/, 3];
                                                    return [4 /*yield*/, this_2.afs.collection('products').doc(limit.product.id).valueChanges().pipe(first()).toPromise()];
                                                case 2:
                                                    product = _a.sent();
                                                    if (product) {
                                                        if (product.isPriceList && limit.product.type === 'variant') {
                                                            if (product.priceList.length) {
                                                                variantIndex = product.priceList.findIndex(function (v) { return v.weight === limit.product.variant; });
                                                                if (variantIndex !== -1) {
                                                                    if (product.priceList[variantIndex].totalQuantity !== '0') {
                                                                        freeProduct = this_2.getPriceListCartObj({ data: product, id: limit.product.id }, variantIndex);
                                                                        freeProduct.price = 0;
                                                                        freeProduct.pack.price = 0;
                                                                        if (freeProduct.pack.variantType === 'pieces') {
                                                                            freeProduct.pack.perPcPrice = 0;
                                                                        }
                                                                        allLimits.push(tslib_1.__assign({}, limit, { freeProduct: freeProduct }));
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            if (product.productQty !== '0') {
                                                                freeProduct = this_2.getCartObj(product, limit.product.id);
                                                                freeProduct.price = 0;
                                                                allLimits.push(tslib_1.__assign({}, limit, { freeProduct: freeProduct }));
                                                            }
                                                        }
                                                    }
                                                    _a.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    };
                                    this_2 = this;
                                    _i = 0, limits_1 = limits;
                                    _a.label = 4;
                                case 4:
                                    if (!(_i < limits_1.length)) return [3 /*break*/, 7];
                                    limit = limits_1[_i];
                                    return [5 /*yield**/, _loop_3(limit)];
                                case 5:
                                    _a.sent();
                                    _a.label = 6;
                                case 6:
                                    _i++;
                                    return [3 /*break*/, 4];
                                case 7:
                                    if (allLimits.length) {
                                        allLimits.sort(function (a, b) {
                                            return a.orderAmount < b.orderAmount ? 1 : -1;
                                        });
                                    }
                                    resolve(allLimits);
                                    return [3 /*break*/, 9];
                                case 8:
                                    error_2 = _a.sent();
                                    console.log(error_2);
                                    resolve([]);
                                    return [3 /*break*/, 9];
                                case 9: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.LogglyLoggerService), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.AlertController), i0.ɵɵinject(i5.LabelService), i0.ɵɵinject(i4.LoadingController), i0.ɵɵinject(i6.UtilsService), i0.ɵɵinject(i7.ConfigService), i0.ɵɵinject(i8.VendorService), i0.ɵɵinject(i9.Router), i0.ɵɵinject(i10.SharedNewService), i0.ɵɵinject(i4.ModalController)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
