/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./sidemenu/sidemenu.page.ngfactory";
import * as i5 from "./sidemenu/sidemenu.page";
import * as i6 from "@ionic/storage";
import * as i7 from "@angular/router";
import * as i8 from "./services/user/user.service";
import * as i9 from "./services/product/product.service";
import * as i10 from "@ionic-native/social-sharing/ngx/index";
import * as i11 from "./services/wallet/wallet.service";
import * as i12 from "./services/brands/brands.service";
import * as i13 from "./services/services-feature/services-feature.service";
import * as i14 from "./services/shared/shared.service";
import * as i15 from "./services/config/config.service";
import * as i16 from "./services/utils/utils.service";
import * as i17 from "./services/referral/referral.service";
import * as i18 from "./services/admin-settings/admin-settings.service";
import * as i19 from "./services/product-subscriptions/product-subscriptions.service";
import * as i20 from "@angular/common";
import * as i21 from "./app.component";
import * as i22 from "./services/rating/rating.service";
import * as i23 from "./services/auth/auth.service";
import * as i24 from "./services/media/media.service";
import * as i25 from "./services/chat.service";
import * as i26 from "./services/search-engine/search-engine.service";
import * as i27 from "./services/delivery/delivery.service";
import * as i28 from "./services/order/order.service";
import * as i29 from "./services/banners/banners.service";
import * as i30 from "./services/best-sellers/best-sellers.service";
import * as i31 from "./services/coupon-codes/coupon-codes.service";
import * as i32 from "./services/delivery-settings/delivery-settings.service";
import * as i33 from "./services/help/help.service";
import * as i34 from "./services/variants/variants.service";
import * as i35 from "./services/product-options/product-options.service";
import * as i36 from "./services/price-request/price-request.service";
import * as i37 from "./services/offer/offer.service";
import * as i38 from "./services/feedback/feedback.service";
import * as i39 from "./services/wishlist/wishlist.service";
import * as i40 from "@ngx-translate/core";
import * as i41 from "./services/label/label.service";
import * as i42 from "./services/filters/filters.service";
import * as i43 from "./services/widgets/widgets.service";
import * as i44 from "./services/membeship/membership.service";
import * as i45 from "./services/language/language.service";
import * as i46 from "./services/theme/theme.service";
import * as i47 from "./services/design-studio/design-studio.service";
import * as i48 from "./services/multi-region/multi-region.service";
import * as i49 from "./services/multi-countries/multi-countries.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-sidemenu", [], null, null, null, i4.View_SidemenuPage_0, i4.RenderType_SidemenuPage)), i1.ɵdid(3, 114688, null, 0, i5.SidemenuPage, [i6.Storage, i3.Events, i7.Router, i8.UserService, i9.ProductService, i3.MenuController, i10.SocialSharing, i3.Platform, i3.ToastController, i11.WalletService, i12.BrandsService, i13.ServicesFeatureService, i3.ModalController, i14.SharedService, i15.ConfigService, i16.UtilsService, i17.ReferralService, i18.AdminSettingsService, i19.ProductSubscriptionsService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "ion-router-outlet", [["id", "content1"]], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.IonRouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i20.Location, i1.ElementRef, i7.Router, i1.NgZone, i7.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i21.AppComponent, [i3.Platform, i22.RatingService, i23.AuthService, i9.ProductService, i24.MediaService, i25.ChatService, i8.UserService, i26.SearchEngineService, i27.DeliveryService, i18.AdminSettingsService, i28.OrderService, i29.BannersService, i30.BestSellersService, i31.CouponCodesService, i32.DeliverySettingsService, i33.HelpService, i34.VariantsService, i35.ProductOptionsService, i36.PriceRequestService, i11.WalletService, i37.OfferService, i3.Events, i3.ModalController, i12.BrandsService, i38.FeedbackService, i13.ServicesFeatureService, i39.WishlistService, i6.Storage, i40.TranslateService, i41.LabelService, i15.ConfigService, i42.FiltersService, i19.ProductSubscriptionsService, i17.ReferralService, i43.WidgetsService, i44.MembershipService, i45.LanguageService, i46.ThemeService, i47.DesignStudioService, i48.MultiRegionService, i49.MultiCountriesService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i21.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
