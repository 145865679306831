import { Component, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { Events } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
    selector: 'app-custom-payment-option',
    templateUrl: './custom-payment-option.page.html',
    styleUrls: ['./custom-payment-option.page.scss'],
})
export class CustomPaymentOptionPage implements OnInit {

    SHARED_LABELS: any;
    headerText: any;
    order: any;
    customOption: any;
    backButtonSubscription: any;
    userScreenShot = '';
    CUSTOM_PAYMENT_OPTION_LABELS: any;
    textDetails = '';

    constructor(private labelService: LabelService,
        private events: Events,
        private sharedService: SharedService) {
    }

    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.CUSTOM_PAYMENT_OPTION_LABELS = this.labelService.labels['CUSTOM_PAYMENT_OPTION'];
        this.headerText = this.customOption.name;
    }

    async uploadPaymentSS(files: FileList){
        for (let i = 0; i < files.length; i++) {
            this.userScreenShot = await this.readBase64(files.item(i));
            console.log('this.userScreenShot', this.userScreenShot);
        }
    }
    readBase64(file) {
        return new Promise<string>(async (resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onload = (event:any) => { // called once readAsDataURL is completed
            resolve(event.target.result);
          }
        });
    }

    removeSS() {
        this.userScreenShot = '';
    }

    completePayment() {
        if(this.customOption.textMandatory && !this.textDetails) {
          this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['add_text_alert']);
          return;
        }
        if(this.customOption.imageMandatory && !this.userScreenShot) {
          this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['upload_img_alert']);
          return;
        }
        this.events.publish('order:ac_completeCustomOptionPayment', this.order, {textDetails: this.textDetails, image: this.userScreenShot, optionName: this.customOption.name});
    }

}
