import { Component, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { ImageModalPage } from 'src/app/image-modal/image-modal.page';
import { Events, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-upi-manual-payment',
  templateUrl: './upi-manual-payment.page.html',
  styleUrls: ['./upi-manual-payment.page.scss'],
})
export class UpiManualPaymentPage implements OnInit {
  SHARED_LABELS: any;
  UPI_MANUAL_PAYMENT_LABELS: any;
  headerText: any;
  upiManual: any;
  userScreenShot = '';
  order: any;

  constructor(private labelService: LabelService,
              private modalController: ModalController,
              private events: Events) {}

  ngOnInit() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.UPI_MANUAL_PAYMENT_LABELS = this.labelService.labels['UPI_MANUAL_PAYMENT'];
    this.headerText = this.UPI_MANUAL_PAYMENT_LABELS['header_text'];
  }

  ionViewWillEnter() {
  }

  ionViewWillLeave() {
  }

  removeSsreenShot() {
    this.userScreenShot = '';
  }

  completePayment() {
    this.events.publish('order:ac_completeUPIManualPayment', this.order, this.userScreenShot);
  }

  imgZoom(img: any) {
    this.modalController.create({
      component: ImageModalPage,
      cssClass:'photo-modal-class',
      componentProps: {
        imgs: [{url: img}],
        index: 0
      }
    }).then(modal => modal.present());
  }

  uploadPaymentSS(files: FileList){
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(files.item(i))
      reader.onload = (event:any) => { // called once readAsDataURL is completed
        let base64Image:any = event.target.result;
        this.userScreenShot = base64Image;
      }
    }
  }

  downloadFile() {
    window.location.href = this.upiManual.qrCode;
    //this.sharedService.getPermission(this.upiManual.qrCode, 'UPI-QR-Code.png');
  }
}
