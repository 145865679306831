import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LabelService } from '../services/label/label.service';
import { SharedService } from '../services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { WidgetsService } from '../services/widgets/widgets.service';
import { encodeURL } from '../services/parseURL';
var PromoModalPage = /** @class */ (function () {
    function PromoModalPage(router, utilsService, modalController, labelService, ngZone, sharedService, widgetService) {
        this.router = router;
        this.utilsService = utilsService;
        this.modalController = modalController;
        this.labelService = labelService;
        this.ngZone = ngZone;
        this.sharedService = sharedService;
        this.widgetService = widgetService;
    }
    PromoModalPage.prototype.ngOnInit = function () {
        // console.log(this.popup);
        this.PROMO_POPUP_LABELS = this.labelService.labels['PROMO_POPUP'];
        this.CO_CATEGORY_GRID_LABELS = this.labelService.labels['CO_CATEGORY_GRID'];
    };
    PromoModalPage.prototype.onClickImgDocument = function (event) {
        var _this = this;
        console.log('onClickImgDocument');
        if (!this.imageBox.nativeElement.contains(event.target)) { // or some similar check
            this.ngZone.run(function () {
                _this.closePopup();
            });
        }
    };
    PromoModalPage.prototype.closePopup = function () {
        this.modalController.dismiss();
    };
    PromoModalPage.prototype.onClickPopup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var linkType, id, name, cname, brandname, res, navigationExtras, userId, navigationExtras, productName, userId, userId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.closePopup();
                        linkType = this.popup.link.type;
                        id = this.popup.link.id;
                        name = this.popup.link.name;
                        if (!(linkType === this.PROMO_POPUP_LABELS['category'])) return [3 /*break*/, 1];
                        cname = encodeURL(name);
                        if (this.popup.link.hasOwnProperty('isSubcategories') && this.popup.link.isSubcategories) {
                            this.router.navigate(["shop-subcategories/" + cname + "/" + id]);
                        }
                        else {
                            this.router.navigate(["shop/category/" + cname + "/" + id]);
                        }
                        return [3 /*break*/, 15];
                    case 1:
                        if (!(linkType === this.PROMO_POPUP_LABELS['brand'])) return [3 /*break*/, 2];
                        brandname = encodeURL(name);
                        this.router.navigate(["shop/brand/" + brandname + "/" + id]);
                        return [3 /*break*/, 15];
                    case 2:
                        if (!(linkType === this.PROMO_POPUP_LABELS['service'])) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sharedService.checkLoginStatus("" + this.CO_CATEGORY_GRID_LABELS['must_be_user_to_access_services_msg'])];
                    case 3:
                        res = _a.sent();
                        if (res) {
                            navigationExtras = {
                                state: {
                                    serviceData: this.popup.link.serviceData
                                }
                            };
                            this.router.navigate(['service-response'], navigationExtras);
                        }
                        return [3 /*break*/, 15];
                    case 4:
                        if (!(linkType === this.PROMO_POPUP_LABELS['refer_and_earn'] || linkType === 'referEarn')) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 5:
                        userId = _a.sent();
                        if (!userId) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['user-referral']);
                        }
                        return [3 /*break*/, 15];
                    case 6:
                        if (!(linkType === this.PROMO_POPUP_LABELS['search'])) return [3 /*break*/, 7];
                        navigationExtras = {
                            state: {
                                searchTxt: name
                            }
                        };
                        this.router.navigate(['search-items'], navigationExtras);
                        return [3 /*break*/, 15];
                    case 7:
                        if (!(linkType === this.PROMO_POPUP_LABELS['product'])) return [3 /*break*/, 8];
                        productName = encodeURL(name);
                        this.router.navigate(["product-details/" + productName + "/" + id]);
                        return [3 /*break*/, 15];
                    case 8:
                        if (!(linkType === this.PROMO_POPUP_LABELS['membership'] || linkType === 'membership')) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 9:
                        userId = _a.sent();
                        if (!userId) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['membership']);
                        }
                        return [3 /*break*/, 15];
                    case 10:
                        if (!(linkType === 'external')) return [3 /*break*/, 11];
                        if (this.popup.link.url) {
                            window.open(this.popup.link.url, '_blank');
                        }
                        return [3 /*break*/, 15];
                    case 11:
                        if (!(linkType === 'contactUs')) return [3 /*break*/, 12];
                        this.router.navigate(['contact']);
                        return [3 /*break*/, 15];
                    case 12:
                        if (!(linkType === 'feedback')) return [3 /*break*/, 14];
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 13:
                        userId = _a.sent();
                        if (!userId) {
                            this.utilsService.openLoginModal();
                        }
                        else {
                            this.router.navigate(['user-feedback']);
                        }
                        return [3 /*break*/, 15];
                    case 14:
                        if (linkType === 'offers') {
                            this.router.navigate(['all-offers']);
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        _a.label = 15;
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    return PromoModalPage;
}());
export { PromoModalPage };
