import * as tslib_1 from "tslib";
import { Events, NavController, LoadingController, AlertController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Storage } from '@ionic/storage';
import { UserService } from '../user/user.service';
import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
import * as i4 from "../user/user.service";
import * as i5 from "../config/config.service";
var WalletService = /** @class */ (function () {
    function WalletService(events, afs, storage, userService, navController, loadingController, alertController, configService) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.userService = userService;
        this.navController = navController;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.configService = configService;
        this.transcations = [];
    }
    WalletService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('wallet:getWalletSettings', function () {
            _this.getWalletSettings();
        });
        this.events.subscribe('wallet:saveWalletSettings', function (data) {
            _this.saveWalletSettings(data);
        });
        this.events.subscribe('wallet:getCashbackList', function () {
            _this.getCashbackList();
        });
        this.events.subscribe('wallet:addNewCashback', function (data) {
            _this.addNewCashback(data);
        });
        this.events.subscribe('wallet:deleteCashback', function (cid) {
            _this.deleteCashback(cid);
        });
        this.events.subscribe('wallet:addAmountToUsersByAdmin', function (amount) {
            _this.addAmountToUsersByAdmin(amount);
        });
        this.events.subscribe('wallet:addMoneyToSingleUserWallet', function (amount, uid) {
            _this.addMoneyToSingleUserWallet(amount, uid);
        });
        this.events.subscribe('wallet:getUserWalletDetails', function (uid) {
            _this.getUserWalletDetails(uid);
        });
        this.events.subscribe('wallet:getWalletTrans', function (uid) {
            _this.getWalletTrans(uid);
        });
        this.events.subscribe('wallet:getMoreWalletTrans', function (uid) {
            _this.getMoreWalletTrans(uid);
        });
        this.events.subscribe('wallet:addMoneyWithRazorPay', function (uid, amount, rid, mode, balance) {
            _this.addMoneyWithRazorPay(uid, amount, rid, mode, balance);
        });
        this.events.subscribe('wallet:addMoneyWithPaytm', function (uid, amount, balance) {
            _this.addMoneyWithPaytm(uid, amount, balance);
        });
    };
    WalletService.prototype.getWalletSettings = function (routeFrom) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var walletData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('wallet').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        walletData = _a.sent();
                        if (!routeFrom) {
                            this.events.publish('wallet:publishWalletSettings', walletData);
                        }
                        else {
                            return [2 /*return*/, walletData];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.saveWalletSettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('wallet').set(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('wallet:saveWalletSettingsSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.getCashbackList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cashbacksRef, cashbacksSnap;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    cashbacksRef = this.afs.collection('settings').doc('wallet').collection('cashbacks', function (ref) { return ref.orderBy('createdAt', 'desc'); });
                    cashbacksSnap = cashbacksRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    }); }));
                    cashbacksSnap.subscribe(function (res) {
                        _this.events.publish('wallet:publishCashbackList', res);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    WalletService.prototype.addNewCashback = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cashback, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        cashback = {
                            createdAt: new Date(),
                            orderAmount: parseInt(data.orderAmnt),
                            cashback: parseInt(data.cashback),
                            perUser: parseInt(data.perUser)
                        };
                        return [4 /*yield*/, this.afs.collection('settings').doc('wallet').collection('cashbacks').add(cashback)];
                    case 1:
                        _a.sent();
                        this.events.publish('wallet:addNewCashbackSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.deleteCashback = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('wallet').collection('cashbacks').doc(cid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('wallet:deleteCashbackSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.addAmountToUsersByAdmin = function (amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.storage.get('storeInfo').then(function (data) {
                        var amntData = {
                            amount: amount,
                            storeName: data.storeName
                        };
                        var addAmount = firebase.functions().httpsCallable('wallet-addAmountToUsersByAdmin');
                        addAmount(amntData).then(function (res) {
                            //// console.log(res.data);
                            if (res.data.status) {
                                _this.events.publish('wallet:addAmountToUsersByAdminSuccess', "<strong>Rs." + amount + "</strong> has been added to all users wallet successfully");
                            }
                            else {
                                _this.events.publish('wallet:addAmountToUsersByAdminSuccess', 'There is some problem in adding money to all users wallet. Please try again later.');
                            }
                        });
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    WalletService.prototype.addMoneyToSingleUserWallet = function (amount, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.storage.get('storeInfo').then(function (data) {
                        var amntData = {
                            amount: amount,
                            storeName: data.storeName,
                            uid: uid
                        };
                        var addMoney = firebase.functions().httpsCallable('wallet-addMoneyToSingleUserWallet');
                        addMoney(amntData).then(function (res) {
                            //// console.log(res.data);
                            if (res.data.status) {
                                _this.events.publish('wallet:addMoneyToSingleUserWalletSuccess', "<strong>Rs." + amount + "</strong> has been added to the user wallet successfully");
                            }
                            else {
                                _this.events.publish('wallet:addMoneyToSingleUserWalletSuccess', 'There is some problem in adding money to the user wallet. Please try again later.');
                            }
                        });
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    WalletService.prototype.getUserWalletDetails = function (uid, route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    if (route && route == 'wallet') {
                        userDetails = this.afs.collection('users').doc(uid).valueChanges().pipe(first()).toPromise();
                        return [2 /*return*/, userDetails];
                    }
                    else {
                        this.afs.collection('users').doc(uid).valueChanges().subscribe(function (res) {
                            //// console.log(res);
                            _this.events.publish('wallet:publishUserWalletDetails', res);
                        });
                    }
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    WalletService.prototype.getWalletTrans = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var txns, _i, txns_1, txn, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.transcations = [];
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).collection('walletTransactions', function (ref) { return ref
                                .orderBy('createdAt', 'desc')
                                .limit(_this.configService.environment.scrollLimit); }).snapshotChanges().pipe(first()).toPromise()];
                    case 1:
                        txns = _a.sent();
                        if (txns.length > 0) {
                            this.lastTxn = txns[txns.length - 1].payload.doc;
                            for (_i = 0, txns_1 = txns; _i < txns_1.length; _i++) {
                                txn = txns_1[_i];
                                this.transcations.push(tslib_1.__assign({ id: txn.payload.doc.id }, txn.payload.doc.data()));
                            }
                        }
                        this.events.publish('wallet:publishWalletTrans', this.transcations);
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.getMoreWalletTrans = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var txns, _i, txns_2, txn, error_6;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).collection('walletTransactions', function (ref) { return ref
                                .orderBy('createdAt', 'desc')
                                .limit(_this.configService.environment.scrollLimit)
                                .startAfter(_this.lastTxn); }).snapshotChanges().pipe(first()).toPromise()];
                    case 1:
                        txns = _a.sent();
                        if (txns.length > 0) {
                            this.lastTxn = txns[txns.length - 1].payload.doc;
                            for (_i = 0, txns_2 = txns; _i < txns_2.length; _i++) {
                                txn = txns_2[_i];
                                this.transcations.push(tslib_1.__assign({ id: txn.payload.doc.id }, txn.payload.doc.data()));
                            }
                        }
                        else {
                            this.events.publish('wallet:noMoreWalletTrans');
                        }
                        this.events.publish('wallet:publishWalletTrans', this.transcations);
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.addMoneyWithRazorPay = function (uid, amount, razorpayId, method, balance) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createOrderInRazorpay, createOrderRes, razorpayOrderId, storeInfo, options, razorpay, error_7;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                        return [4 /*yield*/, createOrderInRazorpay({ amount: Math.round(amount * 100), orderDocId: '' })];
                    case 2:
                        createOrderRes = _a.sent();
                        razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                        console.log('razorpayOrderId', razorpayOrderId);
                        if (!razorpayOrderId) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.storage.get('storeInfo')];
                    case 3:
                        storeInfo = _a.sent();
                        options = {
                            order_id: razorpayOrderId,
                            description: this.configService.environment.razorpay.description,
                            currency: this.configService.environment.razorpay.currency,
                            key: razorpayId,
                            amount: Math.round(amount * 100),
                            name: storeInfo.storeName ? storeInfo.storeName : '',
                            image: this.configService.environment.razorpay.image,
                            prefill: {
                                method: method,
                                contact: this.userService.getPhoneNo(),
                                name: this.userService.getUserName(),
                                email: this.userService.getUserEmail() || '',
                            },
                            theme: this.configService.environment.razorpay.theme,
                            modal: {
                                ondismiss: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        return [2 /*return*/];
                                    });
                                }); }
                            },
                            handler: function (handlerResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var verifySignature, apiBody, verifySignatureRes, walletPaymentObj, txnId, addMoneyToWalletByUser;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            console.log('handlerResponse', handlerResponse);
                                            verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                                            apiBody = {
                                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                                razorpay_signature: handlerResponse.razorpay_signature,
                                            };
                                            return [4 /*yield*/, verifySignature(apiBody)];
                                        case 1:
                                            verifySignatureRes = _a.sent();
                                            console.log('verifySignatureRes', verifySignatureRes);
                                            if (verifySignatureRes.data.signatureIsValid) {
                                                walletPaymentObj = {
                                                    uid: uid,
                                                    mode: 'razorpay',
                                                    txnDetails: { paymentId: apiBody.razorpay_payment_id },
                                                    amount: amount,
                                                    balance: balance
                                                };
                                                txnId = this.afs.collection('users').doc(uid).collection('walletTransactions').ref.doc().id;
                                                walletPaymentObj['txnId'] = txnId;
                                                addMoneyToWalletByUser = firebase.functions().httpsCallable('wallet-addMoneyToWalletByUser');
                                                addMoneyToWalletByUser(walletPaymentObj).then(function (res) {
                                                    //// console.log(res.data);
                                                    _this.loading.dismiss();
                                                    if (res.data.status && res.data.status === 'success') {
                                                        _this.presentAlert("<strong>Rs." + amount + "</strong> successfully added to your wallet.");
                                                    }
                                                    else {
                                                        _this.presentAlert('Payment failed. Any amount debited will be refunded in 4 - 5 working days.');
                                                    }
                                                });
                                            }
                                            else {
                                                this.loading.dismiss();
                                                this.presentFailureAlert();
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); },
                        };
                        razorpay = new Razorpay(options);
                        razorpay.open();
                        razorpay.on('payment.failed', function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                console.log('failure response', response);
                                this.loading.dismiss();
                                this.presentFailureAlert();
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        this.events.publish('order:paymentFailure');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.addMoneyWithPaytm = function (uid, amount, balance) {
        var _this = this;
        var mobileNo = this.userService.getPhoneNo().slice(3);
        //// console.log(typeof mobileNo, mobileNo);
        var txnId = this.afs.collection('users').doc(uid).collection('walletTransactions').ref.doc().id;
        var getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: txnId,
            customerId: uid,
            phoneNo: mobileNo,
            txnAmount: amount.toString(),
        }).then(function (result) {
            //// console.log('checksum:', result.data.checksum);
            var paytmParams = {
                MID: result.data.mid,
                ORDER_ID: txnId,
                CUST_ID: uid,
                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: amount.toString(),
                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + txnId,
                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
            };
            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var walletPaymentObj, addMoneyToWalletByUser;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 2];
                            walletPaymentObj = {
                                uid: uid,
                                mode: 'paytm',
                                txnDetails: response,
                                amount: amount,
                                balance: balance,
                                txnId: txnId
                            };
                            return [4 /*yield*/, this.presentLoading()];
                        case 1:
                            _a.sent();
                            addMoneyToWalletByUser = firebase.functions().httpsCallable('wallet-addMoneyToWalletByUser');
                            addMoneyToWalletByUser(walletPaymentObj).then(function (res) {
                                //// console.log(res.data);
                                if (res.data.status && res.data.status === 'success') {
                                    _this.loading.dismiss();
                                    _this.presentAlert("<strong>Rs." + amount + "</strong> successfully added to your wallet.");
                                }
                                else {
                                    _this.loading.dismiss();
                                    _this.presentAlert('Payment failed. Any amount debited will be refunded in 4 - 5 working days.');
                                }
                            });
                            return [3 /*break*/, 3];
                        case 2:
                            this.presentFailureAlert();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            var failureCallback = function (error) {
                _this.presentFailureAlert();
            };
            paytm.startPayment(paytmParams, successCallback, failureCallback);
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    };
    WalletService.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: 'Ok',
                                    handler: function () {
                                        _this.navController.navigateRoot(['user-wallet']);
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WalletService.prototype.presentFailureAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: 'Payment is failed! Please try again.',
                            buttons: ['Try Again']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i5.ConfigService)); }, token: WalletService, providedIn: "root" });
    return WalletService;
}());
export { WalletService };
