import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavParams, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NewAddressPage } from "../new-address/new-address.page";
import { UtilsService } from '../services/utils/utils.service';
var SelectAddressPage = /** @class */ (function () {
    function SelectAddressPage(events, router, alertController, loadingController, storage, navParams, UtilsService, modalController, route) {
        this.events = events;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.storage = storage;
        this.navParams = navParams;
        this.UtilsService = UtilsService;
        this.modalController = modalController;
        this.route = route;
        this.addressesData = [];
        this.showLoader = true;
        this.showAddAddressBtn = false;
        this.addressType = 'shipping';
        this.addressType = navParams.get('type');
    }
    SelectAddressPage.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeSubscription();
        this.events.publish('user:getAllSavedAddresses');
        setTimeout(function () {
            _this.showAddAddressBtn = true;
        }, 500);
    };
    SelectAddressPage.prototype.ionViewWillEnter = function () {
        var _this = this;
        this.storage.get('userDefaultAddress').then(function (address) {
            _this.addressFromStorage = address;
        });
    };
    SelectAddressPage.prototype.ngOnDestroy = function () {
        this.removeSubscription();
    };
    SelectAddressPage.prototype.initializeSubscription = function () {
        var _this = this;
        this.events.subscribe('user:publishAllSavedAddresses', function (allAddresses) {
            _this.addressesData = allAddresses;
            _this.UtilsService.consoleLog('address modal address', _this.addressesData);
            _this.showLoader = false;
        });
    };
    /*radioSelect(event) {
      //// console.log(event.target.value);
      this.selectedAddress = event.target.value;
    }*/
    SelectAddressPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    SelectAddressPage.prototype.selectAddress = function (address) {
        this.selectedAddress = address;
    };
    SelectAddressPage.prototype.goToPage = function (page) {
        var navigationExtras = {
            state: {
                routeFromSelectAddress: true,
                type: this.addressType
            }
        };
        this.router.navigate([page], navigationExtras);
    };
    SelectAddressPage.prototype.addNewAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: NewAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                routeFromSelectAddress: true,
                                type: this.addressType
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectAddressPage.prototype.editAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: NewAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                addressData: address,
                                addressLength: this.addressesData.length,
                                routeFromSelectAddress: true,
                                type: this.addressType
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectAddressPage.prototype.onClickDeliverHere = function () {
        if (!this.selectedAddress) {
            this.selectedAddress = this.addressesData[0];
        }
        if (this.addressType === 'shipping') {
            this.storage.set('userDefaultAddress', this.selectedAddress);
        }
        else {
            this.storage.set('userBillingAddress', this.selectedAddress);
        }
        this.modalDismiss();
    };
    SelectAddressPage.prototype.removeSubscription = function () {
        this.events.unsubscribe('user:publishAllSavedAddresses');
    };
    return SelectAddressPage;
}());
export { SelectAddressPage };
