import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StatesModalPage } from '../../states-modal/states-modal.page';
var markerDragged = false;
var PickDropMapPage = /** @class */ (function () {
    function PickDropMapPage(modalController, loadingController, deliveryService, sharedService, labelService) {
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.deliveryService = deliveryService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.lat = 0;
        this.lng = 0;
        this.address = {
            address: '',
            city: '',
            state: '',
            pincode: '',
        };
        this.showMap = false;
        this.pickupDate = new Date().toISOString();
        console.log('choice:', this.choice);
        this.address.state = this.pickupState;
    }
    PickDropMapPage.prototype.ngOnInit = function () {
    };
    PickDropMapPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.PICK_AND_DROP_MAP = this.labelService.labels['PICK_AND_DROP_MAP'];
                this.SHARED = this.labelService.labels['SHARED'];
                return [2 /*return*/];
            });
        });
    };
    PickDropMapPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    PickDropMapPage.prototype.openStateModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: StatesModalPage,
                            backdropDismiss: false,
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss()
                            .then(function (res) {
                            if (res.data) {
                                _this.address.state = res.data.state;
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PickDropMapPage.prototype.disableShowArea = function () {
        for (var property in this.address) {
            if (this.address[property] == '') {
                return true;
            }
        }
        return false;
    };
    PickDropMapPage.prototype.disableSave = function () {
        if (this.lat == 0 || this.lng == 0) {
            return true;
        }
        return false;
    };
    PickDropMapPage.prototype.getLatLong = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLngObj;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.presentLoading("" + this.PICK_AND_DROP_MAP['fetching_area']);
                        return [4 /*yield*/, this.deliveryService.getLatLngFromAddress(this.address)];
                    case 1:
                        latLngObj = _a.sent();
                        console.log('latLngObj:', latLngObj);
                        if (latLngObj) {
                            this.lat = latLngObj.results[0].geometry.location.lat;
                            this.lng = latLngObj.results[0].geometry.location.lng,
                                this.showMap = true;
                            setTimeout(function () {
                                _this.loadMap();
                            }, 500);
                            this.loading.dismiss();
                        }
                        else {
                            this.loading.dismiss();
                            this.sharedService.presentAlert("" + this.PICK_AND_DROP_MAP['please_try_again_later']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PickDropMapPage.prototype.saveArea = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLngObj, address, obj_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.pickupTime && this.choice == 'pickup')) return [3 /*break*/, 1];
                        this.sharedService.presentAlert("" + this.PICK_AND_DROP_MAP['please_select_pickup_time']);
                        return [3 /*break*/, 5];
                    case 1: return [4 /*yield*/, this.presentLoading("" + this.SHARED['please_wait'])];
                    case 2:
                        _a.sent();
                        latLngObj = {
                            lat: this.marker.getPosition().lat(),
                            lng: this.marker.getPosition().lng()
                        };
                        if (!markerDragged) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.deliveryService.getAddressFromLatLng(latLngObj)];
                    case 3:
                        address = _a.sent();
                        this.address.address = address.results[0].formatted_address;
                        _a.label = 4;
                    case 4:
                        obj_1 = {
                            address: this.address,
                            latLng: latLngObj,
                            pickupDate: this.pickupDate,
                            pickupTime: this.pickupTime,
                        };
                        this.loading.dismiss();
                        setTimeout(function () {
                            _this.modalController.dismiss(obj_1);
                        }, 500);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PickDropMapPage.prototype.presentAlert = function () {
        this.sharedService.presentAlert("" + this.PICK_AND_DROP_MAP['state_and_city_same']);
    };
    PickDropMapPage.prototype.presentLoading = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg,
                                duration: 3000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        this.loading.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    PickDropMapPage.prototype.loadMap = function () {
        var latLng = new google.maps.LatLng(this.lat, this.lng);
        var mapOptions = {
            center: latLng,
            zoom: 15,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
        var marker = new google.maps.Marker({
            map: this.map,
            animation: google.maps.Animation.DROP,
            draggable: true,
            position: this.map.getCenter()
        });
        this.marker = marker;
        new google.maps.event.addListener(this.marker, 'dragend', function (event) {
            markerDragged = true;
        });
    };
    return PickDropMapPage;
}());
export { PickDropMapPage };
