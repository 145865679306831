import * as tslib_1 from "tslib";
import { AngularFirestore, } from "@angular/fire/firestore";
import { Events } from "@ionic/angular";
import { map, first } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage/";
import { environment } from "src/environments/environment";
import * as firebase from "firebase";
import { ConfigService } from "../config/config.service";
import { Storage } from "@ionic/storage";
import algoliasearch from "algoliasearch";
import { SharedService } from "../shared/shared.service";
import { convertSnaps } from "../db-utils";
import { SearchEngineService } from "../search-engine/search-engine.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic/storage";
import * as i4 from "../config/config.service";
import * as i5 from "@angular/fire/storage";
import * as i6 from "../shared/shared.service";
import * as i7 from "../search-engine/search-engine.service";
var ProductService = /** @class */ (function () {
    // lastResponseOfProducts: any;
    // productsWithoutCategoryData: any[] = [];
    function ProductService(afs, events, storage, configService, fbStorage, sharedService, searchEngineService) {
        this.afs = afs;
        this.events = events;
        this.storage = storage;
        this.configService = configService;
        this.fbStorage = fbStorage;
        this.sharedService = sharedService;
        this.searchEngineService = searchEngineService;
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.image = {
            url: null,
            size: null,
            uploadedAt: null,
            productId: null,
        };
        this.productsData = [];
        this.productsDataForAdminProducts = [];
    }
    ProductService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe("product:addProduct", function (product, listofImages, barcode) {
            _this.addProduct(product, listofImages, barcode);
        });
        this.events.subscribe("product:getProducts", function (id, type) {
            _this.getProducts(id, type);
        });
        this.events.subscribe("product:getProductWithId", function (id) {
            _this.getProductWithId(id);
        });
        this.events.subscribe("product:updateStatus", function (id) {
            _this.updateStatus(id);
        });
        this.events.subscribe("product:editProduct", function (editdata, pid, listOfImages, barcode) {
            _this.editProduct(editdata, pid, listOfImages, barcode);
        });
        this.events.subscribe("product:deleteProduct", function (id) {
            _this.deleteProduct(id);
        });
        this.events.subscribe("product:loadMoreProducts", function (id, type) {
            _this.loadMoreProducts(id, type);
        });
        this.events.subscribe("product:addCategory", function (catgeory, categoryImage, status, banner) {
            _this.addCategory(catgeory, categoryImage, status, banner);
        });
        this.events.subscribe("product:getAllCategories", function () {
            _this.getAllCategories();
        });
        this.events.subscribe("product:getAllCategoriesForShop", function () {
            _this.getAllCategoriesForShop();
        });
        this.events.subscribe("product:deleteCategory", function (catgeoryId) {
            _this.deleteCategory(catgeoryId);
        });
        this.events.subscribe("product:editCategory", function (categoryData, categoryImage, status, banner) {
            _this.editCategory(categoryData, categoryImage, status, banner);
        });
        this.events.subscribe("product:getProductsForCategory", function (cid) {
            _this.getProductsForCategory(cid);
        });
        this.events.subscribe("product:getProductsForCategory", function (cid) {
            _this.getProductsForCategory(cid);
        });
        this.events.subscribe("product:getProductsForAdminProducts", function () {
            _this.getProductsForAdminProducts();
        });
        this.events.subscribe("product:loadMoreProductsForAdminProducts", function () {
            _this.loadMoreProductsForAdminProducts();
        });
        this.events.subscribe("product:loadPreviousProductsForAdminProducts", function () {
            _this.loadPreviousProductsForAdminProducts();
        });
        this.events.subscribe("product:getSubcategories", function (id) {
            _this.getSubcategories(id);
        });
        this.events.subscribe("product:getSubcategoriesForUser", function (id) {
            _this.getSubcategoriesForUser(id);
        });
        this.events.subscribe("product:addSubcategory", function (data, image, categoryId, banner) {
            _this.addSubcategory(data, image, categoryId, banner);
        });
        this.events.subscribe("product:editSubcategory", function (data, image, subcatId, categoryId, banner) {
            _this.editSubcategory(data, image, subcatId, categoryId, banner);
        });
        this.events.subscribe("product:deleteSubcategory", function (subcatId, categoryId) {
            _this.deleteSubcategory(subcatId, categoryId);
        });
        this.events.subscribe("product:getProductsForSubcategory", function (subcatId) {
            _this.getProductsForSubcategory(subcatId);
        });
        this.events.subscribe("product:changeSubcategoriesStatus", function (status, catId) {
            _this.changeSubcategoriesStatus(status, catId);
        });
        this.events.subscribe("product:getAnalyticsProductsCount", function () {
            _this.getAnalyticsProductsCount();
        });
        this.events.subscribe("product:makeProductCopies", function (copies, product) {
            _this.makeProductCopies(copies, product);
        });
        this.events.subscribe("product:getCategoriesData", function (cid) {
            _this.getCategoriesData(cid);
        });
        this.events.subscribe("product:getSubCategoriesData", function (cid, scid) {
            _this.getSubCategoriesData(cid, scid);
        });
        this.setCategoriesInLocalStorage();
        this.events.subscribe("product:removeSusbcriptions", function () {
            if (_this.productSub) {
                _this.productSub.unsubscribe();
            }
        });
        this.lopRef = this.afs.doc("listofProducts/list");
        this.mediaRef = this.afs.collection("media");
        this.productRef = this.afs.collection("products", function (ref) {
            return ref.orderBy("sortedAt", "desc");
        });
        this.userRef = this.afs.collection("users");
    };
    ProductService.prototype.addProduct = function (product, listofImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colorObj, priceList, _i, _a, pl, doc_ref, imgRef, downloadURL, imgRef, downloadURL, _b, priceList_1, pl, imgRef, downloadURL, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 21, , 22]);
                        colorObj = {};
                        priceList = [];
                        if (barcode === "") {
                            product["barcode"] = "";
                        }
                        if (product.color.hasOwnProperty("image") &&
                            product.color.image !== "" &&
                            product.color.image.includes("data:image/jpeg;base64,")) {
                            colorObj = tslib_1.__assign({}, product.color);
                            delete product.color.image;
                        }
                        if (product.isPriceList) {
                            priceList = JSON.parse(JSON.stringify(product.priceList));
                            for (_i = 0, _a = product.priceList; _i < _a.length; _i++) {
                                pl = _a[_i];
                                if (pl.hasOwnProperty("barcode")) {
                                    delete pl.barcode;
                                }
                            }
                        }
                        return [4 /*yield*/, this.afs.collection("products").add(product)];
                    case 1:
                        doc_ref = _c.sent();
                        if (!(listofImages.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.addimgtoFirebase(doc_ref.id, listofImages)];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.afs
                            .collection("products")
                            .doc(doc_ref.id)
                            .update({
                            coverPic: {
                                url: "../../assets/img/placeholder-img.png",
                                mob: "../../assets/img/placeholder-img.png",
                                thumb: "../../assets/img/placeholder-img.png",
                            },
                        })];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5:
                        if (!(barcode !== "")) return [3 /*break*/, 9];
                        imgRef = this.fbStorage.ref("products/" + doc_ref.id + "/barcode/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(barcode, "data_url")];
                    case 6:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 7:
                        downloadURL = _c.sent();
                        return [4 /*yield*/, this.afs.collection("products").doc(doc_ref.id).update({
                                barcode: downloadURL,
                            })];
                    case 8:
                        _c.sent();
                        _c.label = 9;
                    case 9:
                        if (!colorObj.hasOwnProperty("image")) return [3 /*break*/, 13];
                        imgRef = this.fbStorage.ref("products/" + doc_ref.id + "/color/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(colorObj.image, "data_url")];
                    case 10:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 11:
                        downloadURL = _c.sent();
                        return [4 /*yield*/, this.afs
                                .collection("products")
                                .doc(doc_ref.id)
                                .update({
                                color: {
                                    image: downloadURL,
                                    name: colorObj.name,
                                    code: colorObj.code,
                                },
                            })];
                    case 12:
                        _c.sent();
                        _c.label = 13;
                    case 13:
                        if (!priceList.length) return [3 /*break*/, 20];
                        _b = 0, priceList_1 = priceList;
                        _c.label = 14;
                    case 14:
                        if (!(_b < priceList_1.length)) return [3 /*break*/, 18];
                        pl = priceList_1[_b];
                        if (!(pl.hasOwnProperty("barcode") && pl.barcode !== "")) return [3 /*break*/, 17];
                        imgRef = this.fbStorage.ref("products/" + doc_ref.id + "/barcode/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(pl.barcode, "data_url")];
                    case 15:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 16:
                        downloadURL = _c.sent();
                        pl.barcode = downloadURL;
                        _c.label = 17;
                    case 17:
                        _b++;
                        return [3 /*break*/, 14];
                    case 18: return [4 /*yield*/, this.afs.collection("products").doc(doc_ref.id).update({
                            priceList: priceList,
                        })];
                    case 19:
                        _c.sent();
                        _c.label = 20;
                    case 20:
                        this.events.publish("product:addSuccess", "Success", "Product added successfully. The images will be uploaded in just couple of minutes.");
                        return [3 /*break*/, 22];
                    case 21:
                        error_1 = _c.sent();
                        this.events.publish("product:addFailure", "Failure", "Product not added successfully");
                        return [3 /*break*/, 22];
                    case 22: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.editProduct = function (editdata, pid, listOfImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colorObj, priceList, _i, _a, pl, imgRef, downloadURL, imgRef, downloadURL, _b, priceList_2, pl, imgRef, downloadURL, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        colorObj = {};
                        priceList = [];
                        if (editdata.color.hasOwnProperty("image") &&
                            editdata.color.image.includes("data:image/jpeg;base64,")) {
                            colorObj = tslib_1.__assign({}, editdata.color);
                            delete editdata.color.image;
                        }
                        if (editdata.isPriceList) {
                            priceList = JSON.parse(JSON.stringify(editdata.priceList));
                            for (_i = 0, _a = editdata.priceList; _i < _a.length; _i++) {
                                pl = _a[_i];
                                if (pl.hasOwnProperty("barcode") &&
                                    pl.barcode.includes("data:image/jpeg;base64,")) {
                                    delete pl.barcode;
                                }
                            }
                        }
                        if (!(barcode !== "")) return [3 /*break*/, 4];
                        imgRef = this.fbStorage.ref("products/" + pid + "/barcode/" + new Date().getTime().toString() + ".png");
                        return [4 /*yield*/, imgRef.putString(barcode, "data_url")];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 2:
                        downloadURL = _c.sent();
                        editdata["barcode"] = downloadURL;
                        return [4 /*yield*/, this.afs.collection("products").doc(pid).update(editdata)];
                    case 3:
                        _c.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.afs.collection("products").doc(pid).update(editdata)];
                    case 5:
                        _c.sent();
                        _c.label = 6;
                    case 6:
                        if (!colorObj.hasOwnProperty("image")) return [3 /*break*/, 10];
                        imgRef = this.fbStorage.ref("products/" + pid + "/color/" + new Date().getTime().toString() + ".png");
                        return [4 /*yield*/, imgRef.putString(colorObj.image, "data_url")];
                    case 7:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 8:
                        downloadURL = _c.sent();
                        return [4 /*yield*/, this.afs
                                .collection("products")
                                .doc(pid)
                                .update({
                                color: {
                                    image: downloadURL,
                                    name: colorObj.name,
                                    code: colorObj.code,
                                },
                            })];
                    case 9:
                        _c.sent();
                        _c.label = 10;
                    case 10:
                        if (!priceList.length) return [3 /*break*/, 17];
                        _b = 0, priceList_2 = priceList;
                        _c.label = 11;
                    case 11:
                        if (!(_b < priceList_2.length)) return [3 /*break*/, 15];
                        pl = priceList_2[_b];
                        if (!(pl.hasOwnProperty("barcode") &&
                            pl.barcode.includes("data:image/jpeg;base64,"))) return [3 /*break*/, 14];
                        imgRef = this.fbStorage.ref("products/" + pid + "/barcode/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(pl.barcode, "data_url")];
                    case 12:
                        _c.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 13:
                        downloadURL = _c.sent();
                        pl.barcode = downloadURL;
                        _c.label = 14;
                    case 14:
                        _b++;
                        return [3 /*break*/, 11];
                    case 15: return [4 /*yield*/, this.afs.collection("products").doc(pid).update({
                            priceList: priceList,
                        })];
                    case 16:
                        _c.sent();
                        _c.label = 17;
                    case 17:
                        if (!(listOfImages.length !== 0)) return [3 /*break*/, 22];
                        _c.label = 18;
                    case 18:
                        _c.trys.push([18, 20, , 21]);
                        return [4 /*yield*/, this.addimgtoFirebase(pid, listOfImages)];
                    case 19:
                        _c.sent();
                        this.events.publish("product:editSuccess", "Success", "Product edited successfully!");
                        return [3 /*break*/, 21];
                    case 20:
                        error_2 = _c.sent();
                        this.events.publish("product:editFailure", "Failure", "Product not edited successfully!");
                        return [3 /*break*/, 21];
                    case 21: return [3 /*break*/, 25];
                    case 22:
                        if (!(!editdata.images.length && !listOfImages.length)) return [3 /*break*/, 24];
                        return [4 /*yield*/, this.afs
                                .collection("products")
                                .doc(pid)
                                .update({
                                coverPic: {
                                    url: "../../assets/img/placeholder-img.png",
                                    mob: "../../assets/img/placeholder-img.png",
                                    thumb: "../../assets/img/placeholder-img.png",
                                },
                            })];
                    case 23:
                        _c.sent();
                        this.events.publish("product:editSuccess", "Success", "Product edited successfully!");
                        return [3 /*break*/, 25];
                    case 24:
                        this.events.publish("product:editSuccess", "Success", "Product edited successfully!");
                        _c.label = 25;
                    case 25: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.addimgtoFirebase = function (pid, imgdataAndSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, mediaDocRef, imgRef, downloadURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < imgdataAndSize.length)) return [3 /*break*/, 6];
                        this.image.url = "";
                        this.image.size = imgdataAndSize[i].size;
                        this.image.uploadedAt = new Date();
                        this.image.productId = pid;
                        return [4 /*yield*/, this.mediaRef
                                .doc("images")
                                .collection("products")
                                .add(this.image)];
                    case 2:
                        mediaDocRef = _a.sent();
                        imgRef = this.fbStorage.ref("products/" + pid + "/images/" + mediaDocRef.id + ".png");
                        return [4 /*yield*/, imgRef.putString(imgdataAndSize[i].base64Img, "data_url")];
                    case 3:
                        _a.sent();
                        if (!(imgdataAndSize[i].cover === true)) return [3 /*break*/, 5];
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 4:
                        downloadURL = _a.sent();
                        this.afs
                            .collection("products")
                            .doc(pid)
                            .update({ coverPic: { imageId: mediaDocRef.id, url: downloadURL } });
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getProducts = function (id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productRef, region_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.productsData = [];
                        productRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("" + type, "array-contains", id)
                                .where("status", "==", true)
                                .orderBy("sortedAt", "desc")
                                .limit(_this.configService.environment.shopProductsLimit);
                        });
                        if (!(type === "vendor")) return [3 /*break*/, 1];
                        productRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("vendorId", "==", id)
                                .where("status", "==", true)
                                .orderBy("sortedAt", "desc")
                                .limit(_this.configService.environment.shopProductsLimit);
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 2:
                        region_1 = _a.sent();
                        if (region_1.vendorId && region_1.vendorId !== "") {
                            productRef = this.afs.collection("products", function (ref) {
                                return ref
                                    .where("" + type, "array-contains", id)
                                    .where("status", "==", true)
                                    .where("vendorId", "==", region_1.vendorId)
                                    .orderBy("sortedAt", "desc")
                                    .limit(_this.configService.environment.shopProductsLimit);
                            });
                        }
                        _a.label = 3;
                    case 3:
                        this.productSub = productRef.snapshotChanges().subscribe(function (response) {
                            if (!response.length) {
                                _this.events.publish("product:noProductAvailable");
                                return false;
                            }
                            _this.productsData = [];
                            _this.lastInResponse = response[response.length - 1].payload.doc;
                            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                                var product = response_1[_i];
                                _this.productsData.push({
                                    id: product.payload.doc.id,
                                    data: product.payload.doc.data(),
                                });
                            }
                            if (_this.productsData.length !== 0) {
                                _this.events.publish("product:publishProducts", _this.productsData);
                            }
                            else {
                                _this.events.publish("product:noProductAvailable");
                            }
                            _this.productSub.unsubscribe();
                        }, function (error) {
                            _this.productSub.unsubscribe();
                            console.dir(error);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.loadMoreProducts = function (id, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loadMoreProductsRef, region_2, loadMoreProductsSub;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadMoreProductsRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("" + type, "array-contains", id)
                                .where("status", "==", true)
                                .orderBy("sortedAt", "desc")
                                .limit(_this.configService.environment.shopProductsLimit)
                                .startAfter(_this.lastInResponse);
                        });
                        if (!(type === "vendor")) return [3 /*break*/, 1];
                        loadMoreProductsRef = this.afs.collection("products", function (ref) {
                            return ref
                                .where("vendorId", "==", id)
                                .where("status", "==", true)
                                .orderBy("sortedAt", "desc")
                                .limit(_this.configService.environment.shopProductsLimit)
                                .startAfter(_this.lastInResponse);
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 2:
                        region_2 = _a.sent();
                        if (region_2.vendorId && region_2.vendorId !== "") {
                            loadMoreProductsRef = this.afs.collection("products", function (ref) {
                                return ref
                                    .where("" + type, "array-contains", id)
                                    .where("status", "==", true)
                                    .where("vendorId", "==", region_2.vendorId)
                                    .orderBy("sortedAt", "desc")
                                    .limit(_this.configService.environment.shopProductsLimit)
                                    .startAfter(_this.lastInResponse);
                            });
                        }
                        _a.label = 3;
                    case 3:
                        loadMoreProductsSub = loadMoreProductsRef.snapshotChanges().subscribe(function (response) {
                            if (!response.length) {
                                _this.events.publish("product:productsLimitReached");
                                return false;
                            }
                            _this.lastInResponse = response[response.length - 1].payload.doc;
                            for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                                var product = response_2[_i];
                                _this.productsData.push({
                                    id: product.payload.doc.id,
                                    data: product.payload.doc.data(),
                                });
                            }
                            _this.events.publish("product:publishProducts", _this.productsData);
                            loadMoreProductsSub.unsubscribe();
                        }, function (error) {
                            loadMoreProductsSub.unsubscribe();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getProductsForAdminProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.productsDataForAdminProducts = [];
                this.afs
                    .collection("products", function (ref) {
                    return ref.orderBy("sortedAt", "desc").limit(environment.shopProductsLimit);
                })
                    .snapshotChanges()
                    .subscribe(function (response) {
                    if (!response.length) {
                        _this.events.publish("product:noProductsAvailable");
                        return false;
                    }
                    _this.productsDataForAdminProducts = [];
                    _this.lastResponseForAdminProducts =
                        response[response.length - 1].payload.doc;
                    for (var _i = 0, response_3 = response; _i < response_3.length; _i++) {
                        var product = response_3[_i];
                        _this.productsDataForAdminProducts.push({
                            id: product.payload.doc.id,
                            data: product.payload.doc.data(),
                        });
                    }
                    _this.events.publish("product:publishProductsForAdminProducts", _this.productsDataForAdminProducts);
                }, function (error) { });
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.loadMoreProductsForAdminProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.afs
                    .collection("products", function (ref) {
                    return ref
                        .orderBy("sortedAt", "desc")
                        .limit(environment.shopProductsLimit)
                        .startAfter(_this.lastResponseForAdminProducts);
                })
                    .snapshotChanges()
                    .subscribe(function (response) {
                    if (!response.length) {
                        _this.events.publish("product:productsForAdminProductsLimitReached");
                        return false;
                    }
                    _this.productsDataForAdminProducts = [];
                    _this.firstResponseForAdminProducts = response[0].payload.doc;
                    _this.lastResponseForAdminProducts =
                        response[response.length - 1].payload.doc;
                    for (var _i = 0, response_4 = response; _i < response_4.length; _i++) {
                        var product = response_4[_i];
                        _this.productsDataForAdminProducts.push({
                            id: product.payload.doc.id,
                            data: product.payload.doc.data(),
                        });
                    }
                    _this.events.publish("product:publishProductsForAdminProducts", _this.productsDataForAdminProducts);
                }, function (error) { });
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.loadPreviousProductsForAdminProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.afs
                    .collection("products", function (ref) {
                    return ref
                        .orderBy("sortedAt", "desc")
                        .endBefore(_this.firstResponseForAdminProducts)
                        .limitToLast(environment.shopProductsLimit);
                })
                    .snapshotChanges()
                    .subscribe(function (response) {
                    if (!response.length) {
                        _this.events.publish("product:previousProductsForAdminProductsLimitReached");
                        return false;
                    }
                    _this.productsDataForAdminProducts = [];
                    _this.firstResponseForAdminProducts = response[0].payload.doc;
                    _this.lastResponseForAdminProducts =
                        response[response.length - 1].payload.doc;
                    for (var _i = 0, response_5 = response; _i < response_5.length; _i++) {
                        var product = response_5[_i];
                        _this.productsDataForAdminProducts.push({
                            id: product.payload.doc.id,
                            data: product.payload.doc.data(),
                        });
                    }
                    _this.events.publish("product:publishProductsForAdminProducts", _this.productsDataForAdminProducts);
                }, function (error) { });
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.getProductsForCategory = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productsData, productRef, productSnap;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                productsData = [];
                productRef = this.afs.collection("products", function (ref) {
                    return ref.orderBy("sortedAt", "desc").where("categories", "array-contains", cid);
                });
                productSnap = productRef.snapshotChanges().pipe(map(function (actions) {
                    return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    });
                }));
                productSnap.subscribe(function (productsData) {
                    if (productsData.length !== 0) {
                        _this.events.publish("product:publishProductsForCategory", productsData);
                    }
                    else {
                        _this.events.publish("product:noProducts");
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.getProductWithId = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs
                            .collection("products")
                            .doc(id)
                            .valueChanges()
                            .pipe(first())
                            .toPromise()];
                    case 1:
                        productData = _a.sent();
                        this.events.publish("product:publishgetProductWithId", productData);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.updateproductsPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.doc("products/" + id).update({ sortedAt: changedDate })];
                    case 1:
                        _a.sent();
                        this.events.publish("product:updateProductPostionSucess");
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.updateCategoriesPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.doc("categories/" + id).update({ sortedAt: changedDate })];
                    case 1:
                        _a.sent();
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        this.events.publish("product:updateCategoriesPostionSucess");
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.updateStatus = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs
                            .collection("products")
                            .doc(id)
                            .valueChanges()
                            .pipe(first())
                            .toPromise()];
                    case 1:
                        uData = _a.sent();
                        if (uData.status === true) {
                            this.afs.doc("products/" + id).update({ status: false });
                        }
                        else {
                            this.afs.doc("products/" + id).update({ status: true });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.deleteProduct = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection("products").doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish("product:deleteSuccess", "Success", "Product deleted successfully!");
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        this.events.publish("product:deleteFailure", "Failure", "Product not deleted successfully!");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.deleteCategory = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection("categories").doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish("product:deleteCategorySuccess");
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        this.events.publish("product:deletecategoryFailure");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.addCategory = function (catgeoryName, categoryImage, categoryStatus, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var categoryMediaImage, categoryDoc, mediaDocRef, imgRef, downloadURL, imgRef, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        categoryMediaImage = {
                            url: null,
                            size: null,
                            uploadedAt: null,
                            categoryId: null,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 10, , 11]);
                        return [4 /*yield*/, this.afs.collection("categories").add({
                                name: catgeoryName,
                                totalProducts: 0,
                                sortedAt: new Date(),
                                image: {},
                                status: categoryStatus,
                            })];
                    case 2:
                        categoryDoc = _a.sent();
                        if (!(categoryImage.length !== 0)) return [3 /*break*/, 7];
                        categoryMediaImage.url = "";
                        categoryMediaImage.size = categoryImage[0].imgSize;
                        categoryMediaImage.uploadedAt = new Date();
                        categoryMediaImage.categoryId = categoryDoc.id;
                        return [4 /*yield*/, this.afs
                                .collection("media")
                                .doc("images")
                                .collection("categories")
                                .add(categoryMediaImage)];
                    case 3:
                        mediaDocRef = _a.sent();
                        imgRef = this.fbStorage.ref("categories/" + categoryDoc.id + "/image/" + mediaDocRef.id + ".png");
                        return [4 /*yield*/, imgRef.putString(categoryImage[0].imgData, "data_url")];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 5:
                        downloadURL = _a.sent();
                        this.afs
                            .collection("media")
                            .doc("images")
                            .collection("categories")
                            .doc(mediaDocRef.id)
                            .update({ url: downloadURL });
                        return [4 /*yield*/, this.afs.doc("categories/" + categoryDoc.id).update({
                                image: { url: downloadURL, size: categoryImage[0].imgSize },
                            })];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        if (!banner.length) return [3 /*break*/, 9];
                        imgRef = this.fbStorage.ref("categoriesBanner/" + categoryDoc.id + "/image/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(banner[0].imgData, "data_url")];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        this.events.publish("product:addCategorySuccess");
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        return [3 /*break*/, 11];
                    case 10:
                        err_1 = _a.sent();
                        console.dir(err_1);
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.editCategory = function (catgeoryData, categoryImage, categoryStatus, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var categoryMediaImage, mediaDocRef, imgRef, downloadURL, imgRef, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        categoryMediaImage = {
                            url: null,
                            size: null,
                            uploadedAt: null,
                            categoryId: null,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 12, , 13]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(catgeoryData.id)
                                .update({ name: catgeoryData.name, status: categoryStatus })];
                    case 2:
                        _a.sent();
                        if (!(categoryImage.length === 0 && banner.length === 0)) return [3 /*break*/, 3];
                        this.events.publish("product:editCategorySuccess");
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        return [3 /*break*/, 11];
                    case 3:
                        if (!categoryImage.length) return [3 /*break*/, 8];
                        categoryMediaImage.url = "";
                        categoryMediaImage.size = categoryImage[0].imgSize;
                        categoryMediaImage.uploadedAt = new Date();
                        categoryMediaImage.categoryId = catgeoryData.id;
                        return [4 /*yield*/, this.afs
                                .collection("media")
                                .doc("images")
                                .collection("categories")
                                .add(categoryMediaImage)];
                    case 4:
                        mediaDocRef = _a.sent();
                        imgRef = this.fbStorage.ref("categories/" + catgeoryData.id + "/image/" + mediaDocRef.id + ".png");
                        return [4 /*yield*/, imgRef.putString(categoryImage[0].imgData, "data_url")];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, imgRef
                                .getDownloadURL()
                                .pipe(first())
                                .toPromise()];
                    case 6:
                        downloadURL = _a.sent();
                        this.afs
                            .collection("media")
                            .doc("images")
                            .collection("categories")
                            .doc(mediaDocRef.id)
                            .update({ url: downloadURL });
                        return [4 /*yield*/, this.afs.doc("categories/" + catgeoryData.id).update({
                                image: { url: downloadURL, size: categoryImage[0].imgSize },
                            })];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8:
                        if (!banner.length) return [3 /*break*/, 10];
                        imgRef = this.fbStorage.ref("categoriesBanner/" + catgeoryData.id + "/image/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(banner[0].imgData, "data_url")];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        this.events.publish("product:editCategorySuccess");
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        _a.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        err_2 = _a.sent();
                        console.dir(err_2);
                        this.events.publish("product:editCategoryFailure");
                        return [3 /*break*/, 13];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getAllCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catgeoryRef, catgeorySnap, catgeoryData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        catgeoryRef = this.afs.collection("categories", function (ref) {
                            return ref.orderBy("sortedAt", "desc");
                        });
                        catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(function (actions) {
                            return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            });
                        }));
                        return [4 /*yield*/, catgeorySnap.pipe(first()).toPromise()];
                    case 1:
                        catgeoryData = _a.sent();
                        if (!catgeoryData.length) {
                            this.events.publish("product:noCategoryAvailable");
                        }
                        else {
                            this.events.publish("product:publishAllCategoriesForAdmin", catgeoryData);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getAllCategoriesForShop = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catgeoryRef, region, regionId_1, catgeorySnap, categoryData, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        catgeoryRef = void 0;
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId_1 = region.regionId;
                        if (regionId_1) {
                            catgeoryRef = this.afs.collection("categories", function (ref) {
                                return ref
                                    .orderBy("sortedAt", "desc")
                                    .where("status", "==", true)
                                    .where("regionId", "array-contains", regionId_1);
                            });
                        }
                        else {
                            catgeoryRef = this.afs.collection("categories", function (ref) {
                                return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                            });
                        }
                        catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(function (actions) {
                            return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            });
                        }));
                        return [4 /*yield*/, catgeorySnap.pipe(first()).toPromise()];
                    case 2:
                        categoryData = _a.sent();
                        if (!categoryData.length) {
                            this.events.publish("product:noCategoryAvailable");
                        }
                        else {
                            this.events.publish("product:publishAllCategoriesForShop", categoryData);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getAllCategoriesForSideMenu = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catgeoryRef, region, regionId_2, catgeorySnap, catgeoryData, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        catgeoryRef = void 0;
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId_2 = region.regionId;
                        if (regionId_2) {
                            catgeoryRef = this.afs.collection("categories", function (ref) {
                                return ref
                                    .orderBy("sortedAt", "desc")
                                    .where("status", "==", true)
                                    .where("regionId", "array-contains", regionId_2);
                            });
                        }
                        else {
                            catgeoryRef = this.afs.collection("categories", function (ref) {
                                return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                            });
                        }
                        catgeorySnap = catgeoryRef.snapshotChanges().pipe(map(function (actions) {
                            return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            });
                        }));
                        return [4 /*yield*/, catgeorySnap.pipe(first()).toPromise()];
                    case 2:
                        catgeoryData = _a.sent();
                        return [2 /*return*/, catgeoryData];
                    case 3:
                        error_6 = _a.sent();
                        console.log(error_6);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getSubcategories = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategoriesRef, subcategoriesSnap;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    subcategoriesRef = this.afs
                        .collection("categories")
                        .doc(id)
                        .collection("subcategories", function (ref) { return ref.orderBy("sortedAt", "desc"); });
                    subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(function (actions) {
                        return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        });
                    }));
                    subcategoriesSnap.subscribe(function (data) {
                        if (!data.length) {
                            _this.events.publish("product:noSubcategories");
                        }
                        else {
                            _this.events.publish("product:publishSubcategories", data);
                        }
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.updateSubcategoriesPosition = function (id, changedDate, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs
                            .collection("categories")
                            .doc(catId)
                            .collection("subcategories")
                            .doc(id)
                            .update({ sortedAt: changedDate })];
                    case 1:
                        _a.sent();
                        this.events.publish("product:updateSubcategoriesPostionSucess");
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.addSubcategory = function (data, categoryImage, catId, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategoryDoc, imgRef, imgRef, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        data["sortedAt"] = firebase.firestore.FieldValue.serverTimestamp();
                        if (categoryImage.length === 0) {
                            data["image"] = { url: "../../assets/img/placeholder-img.png" };
                        }
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(catId)
                                .collection("subcategories")
                                .add(data)];
                    case 1:
                        subcategoryDoc = _a.sent();
                        if (!(categoryImage.length !== 0)) return [3 /*break*/, 3];
                        imgRef = this.fbStorage.ref("subcategories/" + catId + "/image/" + subcategoryDoc.id + "/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(categoryImage[0].imgData, "data_url")];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(banner.length !== 0)) return [3 /*break*/, 5];
                        imgRef = this.fbStorage.ref("subCategoriesBanner/" + catId + "/banner/" + subcategoryDoc.id + "/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(banner[0].imgData, "data_url")];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.events.publish("product:addSubcategorySuccess");
                        return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        console.dir(err_3);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.editSubcategory = function (data, image, subcatId, catId, banner) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategoryDoc, imgRef, imgRef, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(catId)
                                .collection("subcategories")
                                .doc(subcatId)
                                .update({
                                name: data.name,
                                status: data.status,
                            })];
                    case 1:
                        subcategoryDoc = _a.sent();
                        if (!(image.length !== 0)) return [3 /*break*/, 3];
                        imgRef = this.fbStorage.ref("subcategories/" + catId + "/image/" + subcatId + "/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(image[0].imgData, "data_url")];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(banner.length !== 0)) return [3 /*break*/, 5];
                        imgRef = this.fbStorage.ref("subCategoriesBanner/" + catId + "/banner/" + subcatId + "/" +
                            new Date().getTime().toString() +
                            ".png");
                        return [4 /*yield*/, imgRef.putString(banner[0].imgData, "data_url")];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.events.publish("product:editSubcategorySuccess");
                        return [3 /*break*/, 7];
                    case 6:
                        err_4 = _a.sent();
                        console.dir(err_4);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.deleteSubcategory = function (subcatId, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(catId)
                                .collection("subcategories")
                                .doc(subcatId)
                                .delete()];
                    case 1:
                        _a.sent();
                        this.events.publish("product:deleteSubcategorySuccess");
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getProductsForSubcategory = function (subcatId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var productsData, productRef, productSnap;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                productsData = [];
                productRef = this.afs.collection("products", function (ref) {
                    return ref
                        .orderBy("sortedAt", "desc")
                        .where("categories", "array-contains", subcatId);
                });
                productSnap = productRef.snapshotChanges().pipe(map(function (actions) {
                    return actions.map(function (a) {
                        var data = a.payload.doc.data();
                        var id = a.payload.doc.id;
                        return tslib_1.__assign({ id: id }, data);
                    });
                }));
                productSnap.subscribe(function (productsData) {
                    if (productsData.length !== 0) {
                        _this.events.publish("product:publishProductsForSubcategory", productsData);
                    }
                    else {
                        _this.events.publish("product:noProductsForSubcategory");
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.changeSubcategoriesStatus = function (status, catId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection("categories").doc(catId).update({
                                isSubcategories: status,
                            })];
                    case 1:
                        _a.sent();
                        this.events.publish("product:changeSubcategoriesStatusSuccess");
                        this.events.publish("product:getAllCategories");
                        this.events.publish("product:getAllCategoriesForShop");
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getSubcategoriesInNewProduct = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategoriesRef, subcategoriesSnap, subcategoriesData, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        subcategoriesRef = this.afs
                            .collection("categories")
                            .doc(cid)
                            .collection("subcategories", function (ref) { return ref.orderBy("sortedAt", "desc"); });
                        subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(function (actions) {
                            return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            });
                        }));
                        return [4 /*yield*/, subcategoriesSnap
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        subcategoriesData = _a.sent();
                        return [2 /*return*/, subcategoriesData];
                    case 2:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getSubcategoriesForUser = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catg, subcategoriesRef, subcategoriesSnap, error_10;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(id)
                                .valueChanges()
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        catg = _a.sent();
                        if (catg.isSubcategories) {
                            subcategoriesRef = this.afs
                                .collection("categories")
                                .doc(id)
                                .collection("subcategories", function (ref) {
                                return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                            });
                            subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(map(function (actions) {
                                return actions.map(function (a) {
                                    var data = a.payload.doc.data();
                                    var id = a.payload.doc.id;
                                    return tslib_1.__assign({ id: id }, data);
                                });
                            }));
                            subcategoriesSnap.subscribe(function (data) {
                                if (!data.length) {
                                    _this.events.publish("product:noSubcategoriesForUser");
                                }
                                else {
                                    _this.events.publish("product:publishSubcategoriesForUser", data);
                                }
                            });
                        }
                        else {
                            this.events.publish("product:noSubcategoriesForUser");
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_10 = _a.sent();
                        console.dir(error_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getAnalyticsProductsCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.afs
                        .collection("analytics")
                        .doc("products")
                        .valueChanges()
                        .subscribe(function (data) {
                        _this.events.publish("product:publishAnalyticsProductsCount", data.count);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.makeProductCopies = function (copies, product) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var makeCopies;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    makeCopies = firebase
                        .functions()
                        .httpsCallable("products-makeCopies");
                    makeCopies({ copies: copies, product: product }).then(function (response) {
                        if (response.data.success) {
                            _this.events.publish("product:makeProductCopiesSuccess");
                        }
                        else {
                            _this.events.publish("product:makeProductCopiesFailure");
                        }
                    });
                }
                catch (error) {
                    console.dir(error);
                    this.events.publish("product:makeProductCopiesFailure");
                }
                return [2 /*return*/];
            });
        });
    };
    ProductService.prototype.getCategoriesData = function (cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var catData, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(cid)
                                .valueChanges()
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        catData = _a.sent();
                        this.events.publish("product:publishCategoriesData", catData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getSubCategoriesData = function (cid, scid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs
                                .collection("categories")
                                .doc(cid)
                                .collection("subcategories")
                                .doc(scid)
                                .valueChanges()
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        data = _a.sent();
                        this.events.publish("product:publishSubCategoriesData", data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_12 = _a.sent();
                        console.dir(error_12);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getSimilarProducts = function (product, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var similarProducts, keywords, name, similarQuery, region, regionId, vendorId, filters, res, index;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("getSimilarProducts");
                        similarProducts = [];
                        keywords = product.searchKeywords && product.searchKeywords.length
                            ? product.searchKeywords
                            : [];
                        name = product.prodName || "";
                        similarQuery = name;
                        if (keywords.length) {
                            keywords.forEach(function (kw) {
                                similarQuery = similarQuery + " " + kw;
                            });
                        }
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId = region.regionId;
                        vendorId = region.vendorId;
                        filters = "status:true";
                        if (regionId) {
                            filters += " AND (categoryRegions:" + regionId + " OR brandRegions:" + regionId + ")";
                        }
                        if (vendorId) {
                            filters += " AND vendorId:" + vendorId;
                        }
                        if (!this.configService.environment.useTypesense) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.searchEngineService.getSearchProductsFromTypesense(similarQuery, 0, "new_search")];
                    case 2:
                        res = _a.sent();
                        if (res.status === "available") {
                            res.products.forEach(function (product) {
                                product.data = product;
                            });
                            index = res.products.findIndex(function (product) { return product.id === pid; });
                            res.products.splice(index, 1);
                            return [2 /*return*/, res.products];
                        }
                        else {
                            return [2 /*return*/, []];
                        }
                        return [3 /*break*/, 4];
                    case 3: return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
                                this.index = this.client.initIndex(this.APP_PROJECT_ID);
                                this.index
                                    .search("", { similarQuery: similarQuery, filters: filters })
                                    .then(function (result) {
                                    if (result.hits.length) {
                                        for (var _i = 0, _a = result.hits; _i < _a.length; _i++) {
                                            var hit = _a[_i];
                                            if (hit.objectID !== pid) {
                                                similarProducts.push({ id: hit.objectID, data: hit });
                                            }
                                        }
                                    }
                                    resolve(similarProducts);
                                })
                                    .catch(function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        console.log(err);
                                        reject(err);
                                        return [2 /*return*/];
                                    });
                                }); });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getCategoriesWithSubcategoriesForMenu = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var list;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                list = [];
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var storageCategories;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    storageCategories = JSON.parse(localStorage.getItem("categories")) || [];
                                    if (!!storageCategories.length) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.setCategoriesInLocalStorage()];
                                case 1:
                                    list = _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    list = storageCategories;
                                    _a.label = 3;
                                case 3:
                                    resolve(list);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ProductService.prototype.setCategoriesInLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var list, region, regionId, categoryRef, categories, _i, categories_1, c, subcategories, sublist, _a, subcategories_1, sc;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    list = [];
                                    return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                                case 1:
                                    region = _b.sent();
                                    regionId = region.regionId;
                                    if (regionId) {
                                        categoryRef = this.afs.collection("categories", function (ref) {
                                            return ref
                                                .orderBy("sortedAt", "desc")
                                                .where("status", "==", true)
                                                .where("regionId", "array-contains", regionId);
                                        });
                                    }
                                    else {
                                        categoryRef = this.afs.collection("categories", function (ref) {
                                            return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                                        });
                                    }
                                    return [4 /*yield*/, categoryRef
                                            .snapshotChanges()
                                            .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                            .pipe(first())
                                            .toPromise()];
                                case 2:
                                    categories = _b.sent();
                                    _i = 0, categories_1 = categories;
                                    _b.label = 3;
                                case 3:
                                    if (!(_i < categories_1.length)) return [3 /*break*/, 7];
                                    c = categories_1[_i];
                                    if (!c.isSubcategories) return [3 /*break*/, 5];
                                    return [4 /*yield*/, this.getSubCategoriesForMenu(c.id)];
                                case 4:
                                    subcategories = _b.sent();
                                    sublist = [];
                                    if (subcategories.length) {
                                        for (_a = 0, subcategories_1 = subcategories; _a < subcategories_1.length; _a++) {
                                            sc = subcategories_1[_a];
                                            sublist.push({ id: sc.id, name: sc.name });
                                        }
                                    }
                                    list.push({ id: c.id, name: c.name, sublist: sublist });
                                    return [3 /*break*/, 6];
                                case 5:
                                    list.push({ id: c.id, name: c.name, sublist: [] });
                                    _b.label = 6;
                                case 6:
                                    _i++;
                                    return [3 /*break*/, 3];
                                case 7:
                                    localStorage.setItem("categories", JSON.stringify(list));
                                    resolve(list);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ProductService.prototype.getSubCategoriesForMenu = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subcategoriesRef, subcategories, error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        subcategoriesRef = this.afs
                            .collection("categories")
                            .doc(id)
                            .collection("subcategories", function (ref) {
                            return ref.orderBy("sortedAt", "desc").where("status", "==", true);
                        });
                        return [4 /*yield*/, subcategoriesRef
                                .snapshotChanges()
                                .pipe(map(function (actions) {
                                return actions.map(function (a) {
                                    var data = a.payload.doc.data();
                                    var id = a.payload.doc.id;
                                    return tslib_1.__assign({ id: id }, data);
                                });
                            }))
                                .pipe(first())
                                .toPromise()];
                    case 1:
                        subcategories = _a.sent();
                        return [2 /*return*/, subcategories || []];
                    case 2:
                        error_13 = _a.sent();
                        console.dir(error_13);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.saveAnalytics = function (pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        if (uid) {
                            data = {
                                lastAccessAt: new Date(),
                                source: "web",
                            };
                            this.afs
                                .collection("users")
                                .doc(uid)
                                .collection("analytics")
                                .doc("products")
                                .collection("data")
                                .doc(pid)
                                .set(data);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.notifyProduct = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        if (data.parentId) {
                            this.afs
                                .collection("products")
                                .doc(data.parentId)
                                .collection("options")
                                .doc(data.id)
                                .collection("notifications")
                                .doc(uid)
                                .set({ createdAt: new Date() });
                        }
                        else {
                            this.afs
                                .collection("products")
                                .doc(data.id)
                                .collection("notifications")
                                .doc(uid)
                                .set({ createdAt: new Date() });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductService.prototype.getDescriptionSections = function (productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var appSections_1, sectionsDoc, error_14;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    appSections_1 = [];
                                    return [4 /*yield*/, this.afs
                                            .collection("products")
                                            .doc(productId)
                                            .collection("sections")
                                            .doc("productWidgets")
                                            .valueChanges()
                                            .pipe(first())
                                            .toPromise()];
                                case 1:
                                    sectionsDoc = _a.sent();
                                    if (sectionsDoc &&
                                        sectionsDoc.sections &&
                                        sectionsDoc.sections.length) {
                                        sectionsDoc.sections.forEach(function (section) {
                                            if (section.location === "app" || section.location === "all") {
                                                appSections_1.push(section);
                                            }
                                        });
                                    }
                                    resolve(appSections_1);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_14 = _a.sent();
                                    console.dir(error_14);
                                    error_14["location"] = "product-service:getDescriptionSections";
                                    //this.logglyService.log(error);
                                    resolve([]);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ProductService.prototype.removeSubscriptions = function () {
        this.events.unsubscribe("product:addProduct");
        this.events.unsubscribe("product:getProducts");
        this.events.unsubscribe("product:getProductWithId");
        this.events.unsubscribe("product:updateStatus");
        this.events.unsubscribe("product:editProduct");
        this.events.unsubscribe("product:deleteProduct");
        this.events.unsubscribe("product:loadMoreProducts");
        this.events.unsubscribe("product:addCategory");
        this.events.unsubscribe("product:getAllCategories");
        this.events.unsubscribe("product:deleteCategory");
        this.events.unsubscribe("product:editCategory");
        this.events.unsubscribe("product:getAllCategoriesForShop");
        this.events.unsubscribe("product:getProductsForCategory");
        this.events.unsubscribe("product:getProductsForAdminProducts");
        this.events.unsubscribe("product:loadMoreProductsForAdminProducts");
    };
    ProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.AngularFireStorage), i0.ɵɵinject(i6.SharedService), i0.ɵɵinject(i7.SearchEngineService)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
