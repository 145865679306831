<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>UPI Payment</ion-title>
    <ion-buttons slot="end">
      <!-- <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button> -->
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <div class="ump-fields">
    <p>{{'UPI_MANUAL_PAYMENT.upi_id' | translate}}</p><br>
    <p><strong>{{upiManual.upiId}}</strong></p>
  </div>

  <div class="flex-wrapper m-v-16">
    <div>
      <p>{{'UPI_MANUAL_PAYMENT.qr_code' | translate}}</p>
    </div>
    <div>
      <a [href]="upiManual.qrCode" download target="_blank" class="btn-1">
        {{'UPI_MANUAL_PAYMENT.download' | translate}}
      </a>
    </div>
  </div>

  <div class="ion-text-center">
    <img class="ump-qr" [src]="upiManual.qrCode">
  </div>

  <div class="upload-btn-wrapper m-v-16">
    <button class="upload-btn btn-2 i-start" expand="full"> <i class="flaticon-null-16"></i>upload Payment Proof</button>
    <input type="file" name="myfile" (change)="uploadPaymentSS($event.target.files)" />
  </div>

  <div class="ump-fields" *ngIf="userScreenShot">
    <p>{{'UPI_MANUAL_PAYMENT.your_payment_ss' | translate}}</p>
    <div class="img-wrapper">
      <ion-button class="remove-btn" (click)="removeSsreenShot()"  shape="round" color="danger">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
      <img class="ump-ss" src="{{userScreenShot}}" />
    </div>
  </div>

</ion-content>

<ion-footer>
  <ion-button (click)="completePayment()" expand="full" class="btn-2" *ngIf="userScreenShot">
    {{'UPI_MANUAL_PAYMENT.complete_payment' | translate}}
  </ion-button>
</ion-footer>