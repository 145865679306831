import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Events, AlertController, ToastController, IonRouterOutlet, ModalController, MenuController, LoadingController } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import * as firebase from 'firebase/app';
import { Storage } from '@ionic/storage';
import { ConfigService } from '../services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
var HomePage = /** @class */ (function () {
    function HomePage(events, alertController, toastController, modalController, menuController, 
    //private platform: Platform,
    configService, ngZone, loadingController, storage, labelService, userService, translateService, adminSettingsService, multiCountriesService) {
        this.events = events;
        this.alertController = alertController;
        this.toastController = toastController;
        this.modalController = modalController;
        this.menuController = menuController;
        this.configService = configService;
        this.ngZone = ngZone;
        this.loadingController = loadingController;
        this.storage = storage;
        this.labelService = labelService;
        this.userService = userService;
        this.translateService = translateService;
        this.adminSettingsService = adminSettingsService;
        this.multiCountriesService = multiCountriesService;
        this.phoneNo = null;
        this.verificationID = '';
        this.lastTimeBackPress = 0;
        this.timePeriodToExit = 2000;
        this.error = false;
        this.mode = 'mobile';
        this.isLoading = false;
        this.showNameInput = false;
        this.userName = '';
        this.userEmail = '';
        this.showPincode = false;
        this.storeData = {
            allowComment: false,
            allowStoreInfo: false,
            appPackageName: "",
            appVersion: "",
            commentMsg: "",
            facebookUrl: "",
            instagramUrl: "",
            storeAddress: {
                address: "",
                city: "",
                state: "",
                stateCode: ""
            },
            storeInfo: "",
            storeName: "storeName",
            storePhone: "",
            twitterUrl: "",
            welcomeMsg: "",
            youtubeUrl: ""
        };
        this.newUser = false;
        this.socialSigin = false;
        this.phoneLength = this.configService.environment.phoneLength;
        this.onlyIdentity = false;
        this.showSignin = false;
        this.notDeliverable = false;
        this.pincode = '';
        this.maxDate = new Date().toISOString();
        this.birthday = '';
        this.HOME_LABELS = {};
        this.SHARED_LABELS = {};
        this.loginPopupSettings = {
            name: true,
            email: true,
            dob: true,
            gst: false,
            custom: {
                active: false,
                name: ''
            }
        };
        this.taxType = '';
        this.userGst = '';
        this.customInput = '';
        this.showResendBtn = false;
        this.showTimer = true;
        this.countResendBtnClicked = 0;
        this.isTooManyOtp = false;
        this.telInputOptions = {
            autoHideDialCode: false,
            initialCountry: 'in',
            separateDialCode: true,
            onlyCountries: ['in'],
            preferredCountries: ['in']
        };
        this.isNumberValid = false;
        this.defaultCountryCode = '';
    }
    HomePage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deliverySettings, isAllowAllPincodes, checkPincode, region;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setCountriesOptions();
                        return [4 /*yield*/, this.storage.get('deliverySettings')];
                    case 1:
                        deliverySettings = _a.sent();
                        if (!deliverySettings) return [3 /*break*/, 4];
                        isAllowAllPincodes = deliverySettings.isAllowAllPincodes;
                        return [4 /*yield*/, this.storage.get('checkPincode')];
                    case 2:
                        checkPincode = _a.sent();
                        return [4 /*yield*/, this.storage.get('region')];
                    case 3:
                        region = _a.sent();
                        if ((!checkPincode || checkPincode === undefined) && !isAllowAllPincodes && (!region || region === undefined)) {
                            this.showPincode = true;
                        }
                        else {
                            this.showSignin = true;
                        }
                        _a.label = 4;
                    case 4:
                        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                            'size': 'invisible',
                            'badge': 'bottomleft',
                            'callback': function (response) {
                                // reCAPTCHA solved, allow signInWithPhoneNumber.
                                // onSignInSubmit();
                            }
                        });
                        this.recaptchaVerifier.render();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storeSettings;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.socialSigin = this.configService.environment.socialSigin;
                        this.initializeSubscriptions();
                        this.SHARED_LABELS = this.labelService.labels['SHARED'];
                        this.HOME_LABELS = this.labelService.labels['HOME'];
                        this.taxType = this.configService.environment.taxType || "GST";
                        return [4 /*yield*/, this.adminSettingsService.getSettingsData()];
                    case 1:
                        storeSettings = _a.sent();
                        if (storeSettings) {
                            this.loginPopupSettings = storeSettings && storeSettings.loginPopup && Object.keys(storeSettings.loginPopup).length ? storeSettings.loginPopup : this.loginPopupSettings;
                        }
                        this.defaultCountryCode = this.configService.environment.defaultCountryCode;
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    HomePage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.storage.get('storeInfo').then(function (result) {
            _this.storeData = result;
        });
        this.events.subscribe('user:userAlreadyExists', function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = false;
                        return [4 /*yield*/, this.multiCountriesService.setDefaultCountry()];
                    case 1:
                        _a.sent();
                        this.modalDismiss();
                        window.location.reload();
                        this.menuController.close();
                        return [2 /*return*/];
                }
            });
        }); });
        this.events.subscribe('user:userCreatedSuccessfully', function (uid, name, email) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = false;
                        this.uid = uid;
                        this.storage.set('uid', uid);
                        this.userName = name === 'user' ? '' : name;
                        this.userEmail = email;
                        this.showNameInput = true;
                        return [4 /*yield*/, this.multiCountriesService.setDefaultCountry()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        this.events.subscribe('user:updateNameOfNewUserSuccess', function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = false;
                this.modalDismiss();
                window.location.reload();
                this.menuController.close();
                return [2 /*return*/];
            });
        }); });
        this.events.subscribe('auth:phoneAlreadyInUse', function (mode) {
            if (mode === 'otp' || !mode) {
                _this.isLoading = true;
                _this.loadingTxt = _this.HOME_LABELS['sending_otp'];
                _this.sendOtp();
            }
            if (mode === 'facebook') {
                _this.isLoading = true;
                _this.loadingTxt = _this.HOME_LABELS['signing_in'];
                _this.events.publish('auth:fbLogin', _this.defaultCountryCode + _this.phoneNo);
            }
            if (mode === 'google') {
                _this.isLoading = true;
                _this.loadingTxt = _this.HOME_LABELS['signing_in'];
                _this.events.publish('auth:googleLogin', _this.defaultCountryCode + _this.phoneNo);
            }
        });
        this.events.subscribe('auth:newUser', function () {
            if (_this.socialSigin) {
                _this.isLoading = true;
                _this.loadingTxt = _this.HOME_LABELS['submit'];
                _this.newUser = true;
            }
            else {
                _this.sendOtp();
            }
        });
        this.events.subscribe('delivery-settings:pincodeDeliverable', function () {
            //this.btnTxt = this.HOME_LABELS['submit'];
            _this.showPincode = false;
            _this.showSignin = true;
            _this.storage.set('checkPincode', true);
        });
        this.events.subscribe('delivery-settings:pincodeNotDeliverable', function () {
            //this.btnTxt = this.HOME_LABELS['submit'];
            _this.notDeliverable = true;
        });
    };
    HomePage.prototype.checkPincode = function () {
        this.events.publish('delivery-settings:checkPincode', this.pincode);
    };
    HomePage.prototype.skipPincodeCheck = function () {
        //this.btnTxt = this.HOME_LABELS['submit'];
        this.showPincode = false;
        this.showSignin = true;
        this.notDeliverable = false;
    };
    HomePage.prototype.pincodeChange = function () {
        this.notDeliverable = false;
    };
    HomePage.prototype.signIn = function () {
        // if(!this.isNumberValid) {
        //     this.presentAlert('Please enter a valid phone number');
        //     return;
        // }
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS['verifying'];
        this.events.publish('auth:checkUserStatus', this.defaultCountryCode + this.phoneNo);
    };
    HomePage.prototype.reSendOtp = function () {
        this.showResendBtn = false;
        this.sendOtp();
    };
    HomePage.prototype.resendCodeTimer = function () {
        var _this = this;
        this.timer = 5;
        var interval = setInterval(function () {
            _this.timer -= 1;
            if (_this.timer === 0) {
                clearInterval(interval);
                _this.showTimer = false;
                _this.showResendBtn = true;
            }
        }, 1000);
    };
    HomePage.prototype.sendOtp = function () {
        var _this = this;
        this.resendCodeTimer();
        this.newUser = false;
        this.isLoading = true;
        var appVerifier = this.recaptchaVerifier;
        var phoneNumberString = this.defaultCountryCode + this.phoneNo;
        console.log('phoneNumberString', phoneNumberString);
        firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
            .then(function (result) {
            _this.ngZone.run(function () {
                // console.log(result);
                _this.confirmationResult = result;
                _this.mode = 'otp';
                _this.isLoading = false;
            });
        })
            .catch(function (error) {
            _this.ngZone.run(function () {
                _this.error = true;
                _this.isLoading = false;
                if (error === 'We have blocked all requests from this device due to unusual activity. Try again later.') {
                    _this.isTooManyOtp = true;
                    _this.errorMsg = "Invalid Phone Number. Please check your phone number and try again.";
                }
                _this.errorMsg = "Too many attempts. try again later";
            });
        });
    };
    HomePage.prototype.verifyLoginCode = function () {
        var _this = this;
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS['verifying'];
        this.confirmationResult
            .confirm(this.verificationCode)
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = result.user;
                this.uid = this.user.uid;
                this.userService.checkUserAlreadyExistsOrNot(result.user.uid);
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.error = true;
            _this.errorMsg = 'Incorrect OTP entered';
            _this.isLoading = false;
            _this.loadingTxt = _this.HOME_LABELS['login'];
        });
    };
    HomePage.prototype.checkError = function () {
        this.error = false;
    };
    HomePage.prototype.googleLogin = function () {
        this.newUser = false;
        this.events.publish('auth:googleLogin', this.defaultCountryCode + this.phoneNo);
    };
    HomePage.prototype.fbLogin = function () {
        this.newUser = false;
        this.events.publish('auth:fbLogin', this.defaultCountryCode + this.phoneNo);
    };
    HomePage.prototype.updateUser = function () {
        //// console.log('username:', this.userName);
        var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.userEmail && !this.userEmail.match(mailFormat)) {
            this.presentAlert(this.translateService.instant('HOME.enter_valid_email'));
            return;
        }
        if (this.userName) {
            this.isLoading = true;
            this.loadingTxt = this.HOME_LABELS['saving'];
            this.events.publish('user:updateNameOfNewUser', { name: this.userName, email: this.userEmail, birthday: this.birthday, lowercaseName: this.userName.toLowerCase(), customerGstNo: this.userGst, customInput: this.customInput }, this.uid);
        }
        else {
            this.presentAlert('Please Enter Name');
        }
    };
    HomePage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            color: 'medium',
                            message: msg,
                            duration: 2000,
                            showCloseButton: true,
                            cssClass: 'toast',
                            animated: true
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.presentAlert = function (desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: desc,
                            buttons: [{
                                    text: 'Ok',
                                    handler: function () {
                                        //// console.log('Confirm Okay');
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.presentLoading = function (msg, drtn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg,
                                duration: drtn
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    HomePage.prototype.setCountriesOptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var countries, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        countries = [];
                        this.multiCountriesService.multiCountriesSettings.countries.forEach(function (country) {
                            countries.push(country.countryCode);
                        });
                        this.telInputOptions.onlyCountries = countries;
                        // this.telInputOptions.initialCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry.countryCode;
                        _a = this.telInputOptions;
                        return [4 /*yield*/, this.multiCountriesService.getDefaultCountry()];
                    case 1:
                        // this.telInputOptions.initialCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry.countryCode;
                        _a.initialCountry = (_b.sent()).countryCode;
                        return [2 /*return*/];
                }
            });
        });
    };
    HomePage.prototype.onCountryChange = function (e) {
        var _this = this;
        console.log(e);
        this.defaultCountryCode = "+" + e.dialCode;
        this.multiCountriesService.countryInLogin = this.multiCountriesService.multiCountriesSettings.countries.find(function (c) { return c.dialCode === _this.defaultCountryCode; });
    };
    HomePage.prototype.getNumber = function (e) {
        console.log(e);
    };
    HomePage.prototype.telInputObject = function (e) {
        console.log(e);
    };
    HomePage.prototype.hasError = function (e) {
        console.log(e);
        this.isNumberValid = e;
    };
    HomePage.prototype.phoneChange = function () {
        this.isTooManyOtp = false;
        this.newUser = false;
        this.isNumberValid = false;
    };
    HomePage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('user:userAlreadyExist');
        this.events.unsubscribe('user:userCreatedSuccessfully');
        this.events.unsubscribe('user:updateNameOfNewUserSuccess');
        this.events.unsubscribe('auth:phoneAlreadyInUse');
        this.events.unsubscribe('auth:newUser');
        this.events.unsubscribe('delivery-settings:pincodeDeliverable');
        this.events.unsubscribe('delivery-settings:pincodeNotDeliverable');
    };
    return HomePage;
}());
export { HomePage };
