/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./image-modal.page";
import * as i6 from "../services/utils/utils.service";
var styles_ImageModalPage = [i0.styles];
var RenderType_ImageModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageModalPage, data: {} });
export { RenderType_ImageModalPage as RenderType_ImageModalPage };
function View_ImageModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-spinner", [["name", "lines-small"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(2, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "lines-small"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ImageModalPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-fab", [["horizontal", "start"], ["slot", "fixed"], ["vertical", "center"]], null, null, null, i2.View_IonFab_0, i2.RenderType_IonFab)), i1.ɵdid(1, 49152, null, 0, i3.IonFab, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { horizontal: [0, "horizontal"], vertical: [1, "vertical"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-fab-button", [["class", "fab-nav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevSlide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(3, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "ion-icon", [["name", "arrow-back"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(5, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "start"; var currVal_1 = "center"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.isFirstSlide; _ck(_v, 3, 0, currVal_2); var currVal_3 = "arrow-back"; _ck(_v, 5, 0, currVal_3); }, null); }
function View_ImageModalPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-fab-button", [["class", "fab-nav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextSlide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(1, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "arrow-forward"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLastSlide; _ck(_v, 1, 0, currVal_0); var currVal_1 = "arrow-forward"; _ck(_v, 3, 0, currVal_1); }, null); }
function View_ImageModalPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-fab", [["horizontal", "end"], ["slot", "fixed"], ["vertical", "center"]], null, null, null, i2.View_IonFab_0, i2.RenderType_IonFab)), i1.ɵdid(1, 49152, null, 0, i3.IonFab, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { horizontal: [0, "horizontal"], vertical: [1, "vertical"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageModalPage_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "end"; var currVal_1 = "center"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.imgs.length > 1); _ck(_v, 3, 0, currVal_2); }, null); }
function View_ImageModalPage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-slide", [], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "div", [["class", "img-wrap swiper-zoom-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "swiper-lazy"]], [[8, "src", 4], [8, "hidden", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.url, ""); var currVal_1 = _co.showLoader; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_ImageModalPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ion-fab", [["horizontal", "end"], ["slot", "fixed"], ["vertical", "top"]], null, null, null, i2.View_IonFab_0, i2.RenderType_IonFab)), i1.ɵdid(2, 49152, null, 0, i3.IonFab, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { horizontal: [0, "horizontal"], vertical: [1, "vertical"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "ion-fab-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(4, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(6, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageModalPage_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageModalPage_4)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "ion-slides", [], null, [[null, "ionSlideDidChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionSlideDidChange" === en)) {
        var pd_0 = (_co.slideChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(13, 49152, [[1, 4], ["modalslider", 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageModalPage_6)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 7, "ion-fab", [["class", "fab-zoom"], ["horizontal", "end"], ["slot", "fixed"], ["vertical", "bottom"]], null, null, null, i2.View_IonFab_0, i2.RenderType_IonFab)), i1.ɵdid(17, 49152, null, 0, i3.IonFab, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { horizontal: [0, "horizontal"], vertical: [1, "vertical"] }, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "ion-fab-button", [["class", "fab-zoom-btn fab-btn-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoom(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(19, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(20, 0, null, 0, 0, "i", [["class", "flaticon-zoom-in fab-zoom-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "ion-fab-button", [["class", "fab-zoom-btn fab-btn-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoom(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(22, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(23, 0, null, 0, 0, "i", [["class", "flaticon-zoom-out fab-zoom-icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "end"; var currVal_1 = "top"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "close"; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.imgs.length > 1); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.imgs.length > 1); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.sliderOpts; _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.imgs; _ck(_v, 15, 0, currVal_6); var currVal_7 = "end"; var currVal_8 = "bottom"; _ck(_v, 17, 0, currVal_7, currVal_8); }, null); }
export function View_ImageModalPage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalslider: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageModalPage_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageModalPage_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoader; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.showLoader; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ImageModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-modal", [], null, null, null, View_ImageModalPage_0, RenderType_ImageModalPage)), i1.ɵdid(1, 114688, null, 0, i5.ImageModalPage, [i3.ModalController, i6.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageModalPageNgFactory = i1.ɵccf("app-image-modal", i5.ImageModalPage, View_ImageModalPage_Host_0, { index: "index" }, {}, []);
export { ImageModalPageNgFactory as ImageModalPageNgFactory };
