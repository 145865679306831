import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Events, NavController, ModalController } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { first, map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { OrderMsg } from 'src/app/models/order-msg';
import { ChatMsg } from 'src/app/models/message';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { firestore } from 'firebase';
import * as moment from 'moment';
import { Router, NavigationExtras } from '@angular/router';
import * as firebase from 'firebase';
import {AutoConfirmPaymentPage  } from "../../auto-confirm-payment/auto-confirm-payment.page";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userId: string = '';
  userRef: AngularFirestoreCollection<unknown>;
  phoneNo: string;
  userName: string;
  user: User = {
    name: null,
    email: null,
    phoneNo: null,
    createdAt: null,
    lastAccessAt: null,
    active: null,
    dP: null,
    role: null,
    vacations: null,
    paymentInfo: null,
    defaultAddress: null,
    readTerms: null,
    loginMode: '',
    setFromUI: true,
    wallet: {
      balance: 0,
      cashback: 0,
      lastTransactions: {}
    },
    lowercaseName: ''
  };
  orderMsg: OrderMsg = {
    type: null,
    status: null,
    createdAt: null,
    author: null,
    isRead: null,
    published: null,
    products: null
  };
  msg: ChatMsg = {
    type: null,
    message: null,
    createdAt: null,
    images: null,
    isRead: null,
    author: null,
    published: null,
    mob: null,
    thumb: null,
    imageCount: null
  };
  orders: any[] = [];
  lastOrderData: any;
  lastMsgData: any;
  usersDataForAdminProducts: any[] = [];
  lastResponseForUserProducts: any;
  mediaRef: AngularFirestoreCollection<unknown>;
  pendingOrdersForAdmin: any = [];
  lastResponseOfPendingOrdersForAdmin: any;
  completedOrdersForAdmin: any = [];
  lastResponseOfCompletedOrdersForAdmin: any;
  productsNeedToDeliverForAdmin: any = [];
  lastResponseOfProductsNeedToDeliverForAdmin: any;
  userEmail: string;
  constructor(private afs: AngularFirestore, private fireAuth: AngularFireAuth, private events: Events,
              private fbStorage: AngularFireStorage, private storage: Storage,
              private router: Router, private ngZone: NgZone, private navController: NavController,
              private modalController: ModalController
              ) { }
  initializeSubscriptions() {
    this.events.subscribe('user:getUserInfo', (uid) => {
      this.getUserInfo(uid);
       });
    this.events.subscribe('user:addUserImage', (base64Image) => {
      this.addUserImage(base64Image);
       });
    this.events.subscribe('user:getUserDetails', () => {
      this.getUserDetails();
       });
    this.events.subscribe('user:setActiveVacation', (vacationDetails) => {
      this.setActiveVacation(vacationDetails);
       });
    this.events.subscribe('user:getVacationsDetails', () => {
      this.getVacationsDetails();
       });
    this.events.subscribe('user:getAllUsers', () => {
        this.getAllUsers();
      });
    this.events.subscribe('user:getUsersForAdminUsers', () => {
        this.getUsersForAdminUsers();
      });
    this.events.subscribe('user:loadMoreUsersForAdminUsers', () => {
        this.loadMoreUsersForAdminUsers();
      });
    this.events.subscribe('user:getAllUsersCount', () => {
        this.getAllUsersCount();
      });
    this.events.subscribe('user:changeRole', (role, id) => {
        this.changeRole(role, id);
      });
    this.events.subscribe('user:updateUserDetails', (data) => {
        this.updateUserDetails(data);
      });
    this.events.subscribe('user:completeOrder', (uid, oid) => {
        this.completeOrder(uid, oid);
      });
    this.events.subscribe('user:cancelOrder', (oid) => {
        this.cancelOrder(oid);
      });
    this.events.subscribe('user:setPaytmNo', (paytmNo) => {
        this.setPaytmNo(paytmNo);
      });
    this.events.subscribe('user:setPhonePeNo', (phonepeNo) => {
        //// console.log('in setPhonePeNo sub..');
        this.setPhonePeNo(phonepeNo);
      });
    this.events.subscribe('user:setUpiId', (upiId) => {
        this.setUpiId(upiId);
      });
    this.events.subscribe('user:deletePrdouctFromChatAndOrders', (orderId, msgId, productId) => {
      //// console.log('in deletePrdouctFromChatAndOrders subscribe');
      this.deletePrdouctFromChatAndOrders(orderId, msgId, productId);
    });
    this.events.subscribe('user:saveNewAddress', (addressInfo, type) => {
      this.saveNewAddress(addressInfo, type);
    });
    this.events.subscribe('user:editSavedAddress', (addressInfo, type) => {
      this.editSavedAddress(addressInfo, type);
    });
    this.events.subscribe('user:deleteAddress', (address) => {
      this.deleteAddress(address);
    });
    
    this.events.subscribe('user:getAllSavedAddresses', () => {
      this.getAllSavedAddresses();
    });
    
    this.events.subscribe('user:getOrderDetailsWithOrderId', (orderId) => {
      this.getOrderDetailsWithOrderId(orderId);
    });
    this.events.subscribe('user:getAllOrdersOfUser', (uid) => {
      this.getAllOrdersOfUser(uid);
    });
    this.events.subscribe('user:getPendingOrdersForAdmin', () => {
      this.getPendingOrdersForAdmin();
    });
    this.events.subscribe('user:loadMorePendingOrdersForAdmin', () => {
      this.loadMorePendingOrdersForAdmin();
    });
    this.events.subscribe('user:getCompletedOrdersForAdmin', () => {
      this.getCompletedOrdersForAdmin();
    });
    this.events.subscribe('user:loadMoreCompletedOrdersForAdmin', () => {
      this.loadMoreCompletedOrdersForAdmin();
    });
    this.events.subscribe('user:getProductsNeedToDeliverForAdmin', () => {
      this.getProductsNeedToDeliverForAdmin();
    });
    this.events.subscribe('user:loadMoreProductsNeedToDeliverForAdmin', () => {
      this.loadMoreProductsNeedToDeliverForAdmin();
    });
    this.events.subscribe('user:rejectOrderByAdmin', (orderId) => {
      this.rejectOrderByAdmin(orderId);
    });
    this.events.subscribe('user:confirmOrderByAdmin', (orderDetails, orderId) => {
      this.confirmOrderByAdmin(orderDetails, orderId);
    });
    this.events.subscribe('user:cancelOrderByAdmin', (orderId) => {
      this.cancelOrderByAdmin(orderId);
    });
    this.events.subscribe('user:dispatchOrderByAdmin', (orderId) => {
      this.dispatchOrderByAdmin(orderId);
    });
    this.events.subscribe('user:deliverOrderByAdmin', (orderId) => {
      this.deliverOrderByAdmin(orderId);
    });
    this.events.subscribe('user:returnOrderByAdmin', (orderId) => {
      this.returnOrderByAdmin(orderId);
    });
    this.events.subscribe('user:cancelOrderByUser', (orderId, cancelReason) => {
      this.cancelOrderByUser(orderId, cancelReason);
    });
    this.events.subscribe('user:setPaymentModeOfOrderByUser', (paymentMode, orderId) => {
      this.setPaymentModeOfOrderByUser(paymentMode, orderId);
    });
    this.events.subscribe('user:blockUser', (uid) => {
      this.blockUser(uid);
    });
    this.events.subscribe('user:blockAndDeleteData', (uid) => {
      this.blockAndDeleteData(uid);
    });
    this.events.subscribe('user:unblockUser', (uid) => {
      this.unblockUser(uid);
    });
    this.events.subscribe('user:acceptTermsAndConds', (uid) => {
      this.acceptTermsAndConds(uid);
    });
    this.events.subscribe('user:updateNameOfNewUser', (data, uid) => {
      this.updateNameOfNewUser(data, uid);
    });
    this.events.subscribe('user:getAllDeliveryAgents', () => {
      this.getAllDeliveryAgents();
    });
    this.events.subscribe('user:assignDeliveryAgent', (agentId, orderId) => {
      this.assignDeliveryAgent(agentId, orderId);
    });
    this.events.subscribe('user:getAddressFromLatLng', (lat, lng) => {
      this.getAddressFromLatLng(lat, lng);
    });
    this.events.subscribe('user:getTotalUsers', () => {
      this.getTotalUsers();
    });


    this.userRef = this.afs.collection('users');
    this.mediaRef = this.afs.collection('media');
  }

  addUserId(uid: string) {
    //// console.log('uid:', uid);
    this.userId = uid;
  }
  addPhoneNo(phoneNo: string) {
    //// console.log(phoneNo, typeof phoneNo);
    this.phoneNo = phoneNo;
  }
  getUserId() {
    //// console.log('serice getUserId', this.userId)
    return this.userId;
  }
  async getStorageUid(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.storage.get('uid').then((val: string) => {
        resolve(val);
      });
    });
  }
  getPhoneNo() {
    return this.phoneNo;
  }
  addUserName(userName: string) {
    this.userName = userName;
  }
  getUserName() {
    return this.userName;
  }

  addUserEmail(email: string) {
    this.userEmail = email;
  }

  getUserEmail() {
    return this.userEmail || '';
  }


  async addUser(uid: string, name: string, email: string, phoneNo: string, type: string) {
    //// console.log('in add user...');
    this.user.name = name;
    this.user.lowercaseName = name.toLowerCase();
    this.user.email = email;
    this.user.phoneNo = phoneNo;
    this.user.createdAt = new Date();
    this.user.lastAccessAt = new Date();
    this.user.active = true;
    this.user.role = 'user';
    this.user.vacations = {active: false, start: null, end: null};
    this.user.dP = 'assets/img/user-pic.gif';
    this.user.readTerms = false;
    this.user.loginMode = type;
    try {
      this.userRef.doc(uid).set(this.user);
      this.storage.set('userName', this.user.name);
      this.addUserName(this.user.name);
      this.events.publish('user:userCreatedSuccessfully', uid, name, email);
    } catch (err) {
      console.dir(err);
    }
  }

  async checkUserAlreadyExistsOrNot(uid: string) {
    const usersRef = this.afs.collection('users').doc(uid);
    usersRef.get().subscribe(async (docSnap) => {
      if (docSnap.exists) {
        //// console.log('user exists already...');
        //// console.log('docSnap data...', docSnap.data());
        if (docSnap.data().setFromUI) {
          this.events.publish('user:userAlreadyExists', uid);
        } else {
          //// console.log('setFromUI is false');
          this.addUser(uid, 'user', '', this.getPhoneNo(), 'otp');
        }
      } else {
        //// console.log('user NOT exists...');
        this.addUser(uid, 'user', '', this.getPhoneNo(), 'otp');
      }
    });
  }


  async socialSignInUserCheck(uid: string, name: string, email: string, phoneNo: string, loginType: string) {
    name = name ? name : 'user';
    phoneNo = phoneNo ? phoneNo : '';
    email = email ? email : '';
    this.storage.set('uid', uid);
    //// console.log('uid in socialSignInUserCheck', uid);
    const usersRef: any = this.afs.collection('users').doc(uid);
    usersRef.get().subscribe(async (docSnap) => {
      if (docSnap.exists) {
        //// console.log('user exists already...');
        //// console.log('docSnap data...', docSnap.data());
        if (docSnap.data().setFromUI) {
          this.events.publish('user:userAlreadyExists', uid);
        } else {
          //// console.log('setFromUI is false');
          this.addUser(uid, name, email, phoneNo, loginType);
        }
      } else {
        //// console.log('user NOT exists...');
        this.addUser(uid, name, email, phoneNo, loginType);
      }
    });
  }

  async updateNameOfNewUser(data, uid: string) {
    try {
      await this.afs.collection('users').doc(uid).update(data);
      this.events.publish('user:updateNameOfNewUserSuccess');
      await this.afs.collection('chats').doc(this.getUserId()).update({name: data.name});
      this.addUserName(data.name);
      this.addUserEmail(data.email);
      this.storage.set('userName', data.name);
    } catch (error) {
      console.dir(error);
    }
  }
  async getUserInfo(uid) {
    const userData = await this.userRef.doc(uid).valueChanges().pipe(first()).toPromise();
    this.events.publish('user:publishUserInfo', userData);
  }
  async addUserImage(base64Image: any) {
    try {
      const imgRef: any = this.fbStorage.ref(`profile/${this.userId}/images/` + new Date().getTime().toString() + '.png');
      await imgRef.putString(base64Image, 'data_url');
      const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
      //// console.log('user image download url');
      //// console.log(downloadURL);
      await this.afs.collection('users').doc(this.userId).update({dP: downloadURL});
      this.events.publish('user:uploadProdilePicSuccess', downloadURL);
      this.storage.set('userPic', downloadURL);

    } catch (err) {
      console.dir(err);
      this.events.publish('user:uploadProdilePicFailure');
    }
  }
  async getUserDetails(callType?, userId?: string) {
    const uid = userId ? userId : await this.getStorageUid();
    const usersData: any = await this.afs.doc(`users/${uid}`).valueChanges().pipe(first()).toPromise();
    if(callType === 'return') {
      return usersData;
    } else {
      this.events.publish('user:publishUserDetails', usersData);
    }
  }
  async setActiveVacation(vacationDetails: any) {
      await this.userRef.doc(this.getUserId()).update({vacations: vacationDetails});
      if (vacationDetails.active === false) {
        this.events.publish('user:vacationsSuccess');
      } else {
        this.events.publish('user:vacationsSuccess');
      }
  }
  async setPaytmNo(paytmNo: string) {
    try {
      await this.afs.collection('config').doc('paytm').set({paytmNo: paytmNo});
      this.events.publish('user:setPaytmNoSuccess');
    } catch (err) {
      console.dir(err);
    }
  }
  async setPhonePeNo(phonepeNo: string) {
    try {
      await this.afs.collection('config').doc('phonepe').set({phonepeNo: phonepeNo});
      this.events.publish('user:setPhonePeNoSuccess');
    } catch (err) {
      console.dir(err);
    }
  }
  async setUpiId(upiId: string) {
    try {
      await this.afs.collection('config').doc('upi').set({upiId: upiId});
      this.events.publish('user:setUpiIdSuccess');
    } catch (err) {
      console.dir(err);
    }
  }
  async getVacationsDetails() {
    const userData: any = await this.userRef.doc(this.getUserId()).valueChanges().pipe(first()).toPromise();
    this.events.publish('user:publishVacationsDetails', userData.vacations, userData.name);
  }

  async getAllUsers() {
    try {
      const allUsersRef = this.afs.collection('users');
      const allUsers = allUsersRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
      allUsers.subscribe((res) => {
        if (!res.length) {
          this.events.publish('user:noUsers');
        } else {
          this.events.publish('user:publishAllUsersData', res);
        }
      });
    } catch (err) {
      console.dir(err);
    }
  }
  async getUsersForAdminUsers() {
    try{
      this.usersDataForAdminProducts = [];
    this.afs.collection('users', ref => ref
      .limit(200).orderBy('lastAccessAt', 'desc')
    ).snapshotChanges()
      .subscribe((response: any) => {
        if (!response.length) {
          //// console.log('No Data Available');
          this.events.publish('user:noUsers');
          return false;
        }
        this.usersDataForAdminProducts = [];
        this.lastResponseForUserProducts = response[response.length - 1].payload.doc;
        for (const user of response) {
            this.usersDataForAdminProducts.push({id: user.payload.doc.id, data: user.payload.doc.data()});
        }
        //// console.log('users in getUsersForAdminUsers', this.usersDataForAdminProducts);
        this.events.publish('user:publishUsersForAdminUsers', this.usersDataForAdminProducts);
      }, error => {
      });
    } catch(err){
      console.dir(err);
    }
  }
  async loadMoreUsersForAdminUsers() {
    try{
      //// console.log('in loadMoreProducts service...', this.lastResponseForUserProducts.id);
      this.afs.collection('users', ref => ref
        .limit(200)
        .startAfter(this.lastResponseForUserProducts)
      ).snapshotChanges()
        .subscribe((response: any) => {
          if (!response.length) {
            //// console.log('No Data Available');
            this.events.publish('user:usersForAdminProductsLimitReached');
            return false;
          }
          this.lastResponseForUserProducts = response[response.length - 1].payload.doc;
          //// console.log('response in loadmore', response);
          for (const user of response) {
              this.usersDataForAdminProducts.push({id: user.payload.doc.id, data: user.payload.doc.data()});
          }
          //// console.log('load more users in loadMorepublishUsersForAdminUsers', this.usersDataForAdminProducts);
          this.events.publish('user:publishUsersForAdminUsers', this.usersDataForAdminProducts);
        }, error => {
        });
    }catch(err) {
      console.dir(err);
    }
  }
  async getAllUsersCount() {
    try {
      const users = await this.afs.collection('users').valueChanges().pipe(first()).toPromise();
      this.events.publish('user:publishAllUsersCount', users.length);
    } catch (err) {
      console.dir(err);
    }
  }
  async changeRole(role: string, id: string) {
    try {
      if (role === 'delivery agent') {
        await this.afs.doc(`users/${id}`).update({role: 'deliveryAgent'});
        this.events.publish('user:changeRoleSuccess', role);
      } else {
        await this.afs.doc(`users/${id}`).update({role: role});
        this.events.publish('user:changeRoleSuccess', role);
      }
    } catch (err) {
      console.dir(err);
    }
  }
  async updateUserDetails(data) {
    try {
      await this.userRef.doc(this.getUserId()).update(data);
      if(data.role === 'user') {
        await this.afs.collection('chats').doc(this.getUserId()).update(data);
      }
      this.events.publish('user:updateUserDetailsSuccess');
      this.storage.set('userName', data.name);
      this.addUserName(data.name);
      this.addUserEmail(data.email);
    } catch (err) {
      //// console.log(err);
    }

  }
  async getOrdersWithId(uid: string) {
    //// console.log('id of user', uid);
    const ordersRef = this.afs.collection('users').doc(uid).collection('orders');
    const ordersData = ordersRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).pipe(first());
    ordersData.subscribe((orders) => {
      //// console.log('orders:::', orders);
      if (!orders.length) {
        return false;
      } else {
        this.orders = orders;
      }
    });
    return this.orders;
  }
  async completeOrder(uid: string, oid: string) {
    //// console.log('oid and uid', oid, uid);
    try {
      await this.afs.doc(`users/${uid}`).collection('orders').doc(oid).update({status: 'Completed'});
      const msgRef = this.afs.collection('chats').doc(uid).collection('messages', ref => ref.where('orderId', '==', oid));
      const msg = msgRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first());
    msg.subscribe(async(res: any) => {
      //// console.log('my msg', res);
      await this.afs.collection('chats').doc(uid).collection('messages').doc(res[0].id).update({status: 'Completed'});
    });
      this.events.publish('user:completeOrderSuccess');
    } catch (err) {
      console.dir(err);
    }
  }
  async cancelOrder(oid: string) {
    try{
      await this.userRef.doc(this.getUserId()).collection('orders').doc(oid).update({status: 'Cancelled'});
      const msgRef = this.afs.collection('chats').doc(this.getUserId()).collection('messages', ref => ref.where('orderId', '==', oid));
      const msg = msgRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first());
    msg.subscribe(async(res: any) => {
      //// console.log('my msg', res);
      await this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(res[0].id).update({status: 'Cancelled'});
    });
      this.events.publish('user:cancelOrderSuccess');
    } catch (err) {
      console.dir(err);
    }
  }
  async deletePrdouctFromChatAndOrders(orderId: string, msgId: string, productId: string) {
    //// console.log('productId', productId);
    //// console.log('orderId', orderId);
    //// console.log('msgId', msgId);
    const orderData:any = await this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise();
    const msgData:any = await this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).valueChanges().pipe(first()).toPromise();
    //// console.log('order Data for delete', orderData);
    //// console.log('msg Data for delete', msgData);
    for(let i=0; i<orderData.products.length; i++) {
      if(orderData.products[i].productId === productId) {
        orderData.products.splice(i, 1);
      }
      if(msgData.products[i].productId === productId) {
        msgData.products.splice(i, 1);
      }
    }
    if(orderData.products.length === 0) {
      await this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).delete();
    } else {
      await this.afs.collection('users').doc(this.getUserId()).collection('orders').doc(orderId).update({products: orderData.products});
    }
    if(msgData.products.length === 0){
      await this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).delete();
    } else {
      await this.afs.collection('chats').doc(this.getUserId()).collection('messages').doc(msgId).update({products: msgData.products});
    }
    this.events.publish('user:deleteProductSuccesss');

  }
  async saveNewAddress(addressInfo: any, type: string) {
    try {
      const addressRef = await this.afs.collection('users').doc(this.getUserId()).collection('addresses').add(addressInfo);
      if(addressInfo.defaultAddress === true && type !== 'billing') {
        this.afs.collection('users').doc(this.getUserId()).update({defaultAddress: addressInfo});
        this.storage.set('userDefaultAddress', addressInfo);
        const allAddressData = await this.afs.collection('users').doc(this.getUserId()).collection('addresses').snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
        for (let index = 0; index < allAddressData.length; index++) {
          if(allAddressData[index].id !== addressRef.id) {
            this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(allAddressData[index].id).update({defaultAddress: false})
          }
        }
      }
      if(type === 'billing') {
        this.storage.set('userBillingAddress', addressInfo);
      }
      this.events.publish('user:newAddressSaved');

    } catch(err) {
      console.dir(err);
    }
  }
  async editSavedAddress(addressInfo: any, type: string) {
    //// console.log('type', type);
    try {
      await this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(addressInfo.id).update(addressInfo);
      if(addressInfo.defaultAddress === true && type !== 'billing') {
        this.afs.collection('users').doc(this.getUserId()).update({defaultAddress: addressInfo});
        this.storage.set('userDefaultAddress', addressInfo);
        const allAddressData = await this.afs.collection('users').doc(this.getUserId()).collection('addresses').snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
        for (let index = 0; index < allAddressData.length; index++) {
          if(allAddressData[index].id !== addressInfo.id) {
            this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(allAddressData[index].id).update({defaultAddress: false})
          }
        }
      }
      if(type === 'billing') {
        this.storage.set('userBillingAddress', addressInfo);
      }
      this.events.publish('user:addressEditSuccess');
    } catch(err) {
      console.dir(err);
    }
  }
  async deleteAddress(address: any) {
    try {
      await this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(address.id).delete();
      if(address.defaultAddress === true) {
        const allAddressData: any = await this.afs.collection('users').doc(this.getUserId()).collection('addresses', ref => ref.orderBy('createdAt', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
        if(allAddressData.length) {
          const addressInfo: any = {
            name: allAddressData[0].name,
            address: allAddressData[0].address,
            city: allAddressData[0].city,
            state: allAddressData[0].state,
            pincode: allAddressData[0].pincode,
            phoneNo: allAddressData[0].phoneNo,
            defaultAddress: true
          }
          this.afs.collection('users').doc(this.getUserId()).update({defaultAddress: addressInfo});
          this.storage.set('userDefaultAddress', addressInfo);
          this.afs.collection('users').doc(this.getUserId()).collection('addresses').doc(allAddressData[0].id).update({defaultAddress: true})
        } else {
          this.storage.set('userDefaultAddress', null);
          this.afs.collection('users').doc(this.getUserId()).update({defaultAddress: null});
        }
      }
      this.events.publish('user:deleteAddressSuccess');
    } catch(err) {
      console.dir(err);
    }
  }
  async getAllSavedAddresses() {
    try {
      const addressRef = this.afs.collection('users').doc(this.getUserId()).collection('addresses', ref => ref.orderBy('createdAt', 'desc'));
      const addressSnap = addressRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
      addressSnap.subscribe((result) => {
        this.events.publish('user:publishAllSavedAddresses', result);
      });
    } catch(err) {
      console.dir(err);
    }
  }

  async getOrderDetailsWithOrderId(orderId: number) {
    try {
      const uid = await this.getStorageUid();
      const orderData:any = await this.afs.collection('orders', ref => ref.where('orderId', '==', orderId)
      .where('userId', '==', uid)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      this.events.publish('user:publishOrderDetailsWithOrderId', orderData);
    } catch (error) {
      console.dir(error);
    }
  }
  async getAllOrdersOfUser(uid?) {
    try {
      let userId: string = '';
      if(uid) {
        userId = uid;
      } else {
        userId = await this.getStorageUid();
      }
      const ordersRef = this.afs.collection('orders', ref => ref
      .where('userId', '==', userId)
      .orderBy('createdAt', 'desc'));
      const ordersSnap = ordersRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
      ordersSnap.subscribe((orders) => {
        if(!orders.length) {
          this.events.publish('user:noOrderHistoryOfUser');
        } else {
          this.events.publish('user:publishAllOrdersOfUser', orders);
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }
  getPendingOrdersForAdmin() {
    try {
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .where('status', 'in', ['Pending', 'Confirmed', 'Dispatched'])
      .limit(environment.scrollLimit));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        this.pendingOrdersForAdmin = [];
        if(!orders.length) {
          this.events.publish('user:noPendingOrdersForAdmin');
        } else {
          this.lastResponseOfPendingOrdersForAdmin = orders[orders.length - 1].payload.doc;
          for(let order of orders) {
            this.pendingOrdersForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
            this.events.publish('user:publishPendingOrdersForAdmin', this.pendingOrdersForAdmin);
          }
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }
  loadMorePendingOrdersForAdmin() {
    try {
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .where('status', 'in', ['Pending', 'Confirmed', 'Dispatched'])
      .limit(environment.scrollLimit)
      .startAfter(this.lastResponseOfPendingOrdersForAdmin));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        if(!orders.length) {
          this.events.publish('user:noMorePendingOrdersForAdmin');
          return false;
        }
        this.lastResponseOfPendingOrdersForAdmin = orders[orders.length - 1].payload.doc;
        for(let order of orders) {
          this.pendingOrdersForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
          this.events.publish('user:publishPendingOrdersForAdmin', this.pendingOrdersForAdmin);
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }
  getCompletedOrdersForAdmin() {
    try {
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .where('status', 'in', ['Rejected', 'Cancelled', 'Delivered', 'Returned'])
      .limit(environment.scrollLimit));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        this.completedOrdersForAdmin = [];
        if(!orders.length) {
          this.events.publish('user:noCompletedOrdersForAdmin');
        } else {
          this.lastResponseOfCompletedOrdersForAdmin = orders[orders.length - 1].payload.doc;
          for(let order of orders) {
            this.completedOrdersForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
            this.events.publish('user:publishCompletedOrdersForAdmin', this.completedOrdersForAdmin);
          }
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }
  loadMoreCompletedOrdersForAdmin() {
    try {
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .limit(environment.scrollLimit)
      .startAfter(this.lastResponseOfCompletedOrdersForAdmin));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        if(!orders.length) {
          this.events.publish('user:noMoreCompletedOrdersForAdmin');
          return false;
        }
        this.lastResponseOfCompletedOrdersForAdmin = orders[orders.length - 1].payload.doc;
        for(let order of orders) {
          this.completedOrdersForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
          this.events.publish('user:publishCompletedOrdersForAdmin', this.completedOrdersForAdmin);
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }

  getProductsNeedToDeliverForAdmin() {
    try {
      this.productsNeedToDeliverForAdmin = [];
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .where('status', 'in', ['Pending', 'Confirmed'])
      .limit(environment.scrollLimit));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        if(!orders.length) {
          this.events.publish('user:noProductsNeedToDeliverForAdmin');
        } else {
          this.lastResponseOfProductsNeedToDeliverForAdmin = orders[orders.length - 1].payload.doc;
          for(let order of orders) {
            this.productsNeedToDeliverForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
            this.events.publish('user:publishProductsNeedToDeliverForAdmin', this.productsNeedToDeliverForAdmin);
          }
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }
  loadMoreProductsNeedToDeliverForAdmin() {
    try {
      const ordersRef = this.afs.collection('orders', ref => ref
      .orderBy('createdAt', 'desc')
      .where('status', 'in', ['Pending', 'Confirmed'])
      .limit(environment.scrollLimit)
      .startAfter(this.lastResponseOfProductsNeedToDeliverForAdmin));
      const ordersSnap = ordersRef.snapshotChanges();
      ordersSnap.subscribe((orders) => {
        if(!orders.length) {
          this.events.publish('user:noMoreProductsNeedToDeliverForAdmin');
          return false;
        }
        this.lastResponseOfProductsNeedToDeliverForAdmin = orders[orders.length - 1].payload.doc;
        for(let order of orders) {
          this.productsNeedToDeliverForAdmin.push({id: order.payload.doc.id, ...order.payload.doc.data() as {}})
          this.events.publish('user:publishProductsNeedToDeliverForAdmin', this.productsNeedToDeliverForAdmin);
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async rejectOrderByAdmin(orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({status: 'Rejected'});
      const rejectChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Rejected',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', rejectChatMsg, orderData[0].userId);
      this.events.publish('user:rejectedOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async confirmOrderByAdmin(orderDetails, orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({
        status: 'Confirmed',
        products: orderDetails.products,
        productsPrice: orderDetails.productsPrice,
        totalAmountToPaid: orderDetails.totalAmountToPaid
      });
      const confrimChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Confirmed',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', confrimChatMsg, orderData[0].userId);
      this.events.publish('user:confirmedOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async cancelOrderByAdmin(orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({status: 'Cancelled'});
      const cancelChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Cancelled',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', cancelChatMsg, orderData[0].userId);
      this.events.publish('user:cancelledOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async dispatchOrderByAdmin(orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({status: 'Dispatched'});
      const dispatchChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Dispatched',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', dispatchChatMsg, orderData[0].userId);
      this.events.publish('user:dispatchedOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async deliverOrderByAdmin(orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      const updateObj = {
        status: 'Delivered'
      }; 
      if(orderData[0].payment.mode === 'cash' && !orderData[0].payment.completed) {
        updateObj['payment.completed'] = true;
      }
      await this.afs.collection('orders').doc(orderData[0].id).update(updateObj);
      const deliverChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Delivered',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', deliverChatMsg, orderData[0].userId);
      this.events.publish('user:deliveredOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async returnOrderByAdmin(orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({status: 'Returned'});
      const returnChatMsg = {
        author: 'admin',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Returned',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', returnChatMsg, orderData[0].userId);
      this.events.publish('user:returnedOrderSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async cancelOrderByUser(orderId: number, cancelReason: string, callType: string = 'event') {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();

      const updateObj = {
        cancelData: {
          reason: cancelReason,
          by: `${this.getUserName()} (User)`
        },
        status: 'Cancelled'
      }

      await this.afs.collection('orders').doc(orderData[0].id).update(updateObj);
      const cancelChatMsg = {
        author: 'user',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'Cancelled',
        type: 'order'
      }
      this.events.publish('chat:sendMsg', cancelChatMsg, orderData[0].userId);
      
      if(callType === 'return') {
        return true;
      } else {
        this.events.publish('user:cancelledOrderByUserSuccessfully');
      }
      
    } catch (error) {
      console.dir(error);
    }
  }
  async setPaymentModeOfOrderByUser(paymentMode, orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({payment: {mode: paymentMode}});
      const paymentChatMsg = {
        author: 'user',
        createdAt: new Date(),
        isRead: true,
        orderId: orderData[0].orderId,
        published: true,
        status: 'PaymentMsg',
        type: 'order',
        paymentMode: paymentMode
      }
      this.events.publish('chat:sendMsg', paymentChatMsg, orderData[0].userId);
      this.events.publish('user:setPaymentModeOfOrderByUserSuccessfully');
    } catch (error) {
      console.dir(error);
    }
  }
  async blockUser(uid:string) {
    try {
      await this.userRef.doc(uid).update({active: false});
      await this.afs.collection('block').doc(uid).set({deleteData: false});
      this.events.publish('user:userBlockedSuccessfully');
    } catch (error) {
      console.dir(error.message);
    }
  }
  async unblockUser(uid:string) {
    try {
      await this.userRef.doc(uid).update({active: true});
      await this.afs.collection('block').doc(uid).delete();
      this.events.publish('user:userUnblockedSuccessfully');
    } catch (error) {
      console.dir(error.message);
    }
  }
  async blockAndDeleteData(uid:string) {
    try {
      await this.afs.collection('block').doc(uid).set({deleteData: true});
      this.events.publish('user:userBlockedAndDeleteDataSuccessfully');
    } catch (error) {
      console.dir(error.message);
    }
  }
  async acceptTermsAndConds(uid:string) {
    try {
      await this.afs.collection('users').doc(uid).update({readTerms: true});
      this.events.publish('user:termsAndCondsAcceptedSuccess');
    } catch (error) {
      console.dir(error.message);
    }
  }
  async getAllDeliveryAgents() {
    try {
      const allDeliveryAgentsRef = this.afs.collection('users', ref => ref.where('role', '==', 'deliveryAgent'));
      const allDeliveryAgents = allDeliveryAgentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
      allDeliveryAgents.subscribe((res) => {
        if (!res.length) {
          this.events.publish('user:noDeliveryAgents');
        } else {
          this.events.publish('user:publishAllDeliveryAgents', res);
        }
      });
    } catch (err) {
      console.dir(err);
    }
  }
  async assignDeliveryAgent(agentId, orderId) {
    try {
      const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
      const orderData: any = await orderRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      await this.afs.collection('orders').doc(orderData[0].id).update({deliveryAgentId: agentId, deliveryStatus: 'notStarted'});
      this.events.publish('user:assignDeliveryAgentSuccess');
    } catch (error) {
      console.dir(error);
    }
  }

  async getAddressFromLatLng(lat, lng) {
    try {
      let getAddressFromLatLng = firebase.functions().httpsCallable('location-getAddressFromLatLng');
      getAddressFromLatLng({lat: lat, lng: lng}).then((response) => {
        //// console.log('response', response.data);
        if(response.data.status !== "OK" || response.data.success === false) {
          this.events.publish('user:errorInGettingAddress');
        } else {
          this.events.publish('user:addressValueFromLatLng', response.data);
        }
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async getTotalUsers() {
    try {
       this.afs.collection('analytics').doc('users').valueChanges().subscribe((data: any) => {
        this.events.publish('user:publishTotalUsers', data.count);
      });
    } catch (error) {
      console.dir(error);
    }

  }


  removeSubscriptions(){
    this.events.unsubscribe('user:getUserInfo');
    this.events.unsubscribe('user:addUserImage');
    this.events.unsubscribe('user:getTotalActiveUsers');
    this.events.unsubscribe('user:getUserDetails');
    this.events.unsubscribe('user:setActiveVacation');
    this.events.unsubscribe('user:getVacationsDetails');
    this.events.unsubscribe('user:getAllUsers');
    this.events.unsubscribe('user:getUsersForAdminUsers');
    this.events.unsubscribe('user:loadMoreUsersForAdminUsers');
    this.events.unsubscribe('user:changeRole');
    this.events.unsubscribe('user:updateUserDetails');
    this.events.unsubscribe('user:completeOrder');
    this.events.unsubscribe('user:cancelOrder');
    this.events.unsubscribe('user:setPaytmNo');
    this.events.unsubscribe('user:setPhonePeNo');
    this.events.unsubscribe('user:setUpiId');
    this.events.unsubscribe('user:getPaytmNo');
    this.events.unsubscribe('user:getPhonePeNo');
    this.events.unsubscribe('user:getUpiId');
    this.events.unsubscribe('user:saveNewAddress');
    this.events.unsubscribe('user:editSavedAddress');
    this.events.unsubscribe('user:deleteAddress');
    this.events.unsubscribe('user:getAllSavedAddresses');
    this.events.unsubscribe('user:getOrderDetailsWithOrderId');
    this.events.unsubscribe('user:getAllOrdersOfUser');
    this.events.unsubscribe('user:getAllOrdersForAdmin');
    this.events.unsubscribe('user:rejectOrderByAdmin');
    this.events.unsubscribe('user:confirmOrderByAdmin');
    this.events.unsubscribe('user:cancelOrderByAdmin');
    this.events.unsubscribe('user:dispatchOrderByAdmin');
    this.events.unsubscribe('user:deliverOrderByAdmin');
    this.events.unsubscribe('user:returnOrderByAdmin');
    this.events.unsubscribe('user:cancelOrderByUser');
    this.events.unsubscribe('user:setPaymentModeOfOrderByUser');
  }
}
