import { Component } from '@angular/core';

import { Platform, Events, NavController, ModalController } from '@ionic/angular';
import { AuthService } from './services/auth/auth.service';
import { ProductService } from './services/product/product.service';
import { MediaService } from './services/media/media.service';
import { ChatService } from './services/chat.service';
import { UserService } from './services/user/user.service';
import { SearchEngineService } from './services/search-engine/search-engine.service';
import { DeliveryService } from './services/delivery/delivery.service';
import { AdminSettingsService } from './services/admin-settings/admin-settings.service';
import { OrderService } from './services/order/order.service';
import { BannersService } from './services/banners/banners.service';
import { BestSellersService } from './services/best-sellers/best-sellers.service';
import { CouponCodesService } from './services/coupon-codes/coupon-codes.service';
import { DeliverySettingsService } from './services/delivery-settings/delivery-settings.service';
import { HelpService } from './services/help/help.service';
import { VariantsService } from './services/variants/variants.service';
import { ProductOptionsService } from './services/product-options/product-options.service';
import { OfferService } from './services/offer/offer.service';
import { PriceRequestService } from './services/price-request/price-request.service';
import * as firebase from 'firebase';
import { WalletService } from './services/wallet/wallet.service';
import { BrandsService } from './services/brands/brands.service';
import { FeedbackService } from './services/feedback/feedback.service';
import { ServicesFeatureService } from './services/services-feature/services-feature.service';
import { WishlistService } from './services/wishlist/wishlist.service';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { LabelService } from './services/label/label.service';
import { ConfigService } from './services/config/config.service';
import { RatingService } from './services/rating/rating.service';
import { WidgetsService } from './services/widgets/widgets.service';
import { ProductSubscriptionsService } from './services/product-subscriptions/product-subscriptions.service';
import { FiltersService } from './services/filters/filters.service';
import { ReferralService } from './services/referral/referral.service';
import { MultiRegionService } from './services/multi-region/multi-region.service';
import { MembershipService } from './services/membeship/membership.service';
import { LanguageService } from './services/language/language.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { DesignStudioService } from './services/design-studio/design-studio.service';
import { MultiCountriesService } from './services/multi-countries/multi-countries.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isSplashScreenVisible:boolean = true;
  navigate: any;
  constructor(
    private platform: Platform,
    private ratingService: RatingService,
    private authService: AuthService,
    private productService: ProductService,
    private mediaService: MediaService,
    private chatService: ChatService,
    private userService: UserService,
    private searchEngineService: SearchEngineService,
    private deliveryService: DeliveryService,
    private adminSettingsService: AdminSettingsService,
    private orderService: OrderService,
    private bannerService: BannersService,
    private bestSellersService: BestSellersService,
    private couponCodesService: CouponCodesService,
    private deliverySettingsService: DeliverySettingsService,
    private helpService: HelpService,
    private variantsService: VariantsService,
    private productOptionsService: ProductOptionsService,
    private priceRequestService: PriceRequestService,
    private walletService: WalletService,
    private offerService: OfferService,
    private events:Events,
    private modalController: ModalController,
    private brandsService:BrandsService,
    private feedbackService:FeedbackService,
    private servicesFeatureService:ServicesFeatureService,
    private wishlistService:WishlistService,
    private storage: Storage,
    private translateService: TranslateService,
    private labelService: LabelService,
    private configService: ConfigService,
    private filtersService:FiltersService,
    private productSubscriptionsService: ProductSubscriptionsService,
    private referralService: ReferralService,
    private widgetsService: WidgetsService,
    private membershipService:MembershipService,
    private languageService: LanguageService,
    private themeService:ThemeService,
    private designStudioService:DesignStudioService,
    private multiRegionService: MultiRegionService,
    private multiCountriesService: MultiCountriesService,) {
    this.initializeApp();
  }

  initializeApp() {
    this.handleSplashScreen();
    this.productSubscriptionsService.initializeSubscriptions();
    this.authService.initializeSubscriptions();
    this.filtersService.initializeSubscriptions();
    this.widgetsService.initializeSubscriptions();
    this.productService.initializeSubscriptions();
    this.mediaService.initializeSubscriptions();
    this.chatService.initializeSubscriptions();
    this.userService.initializeSubscriptions();
    this.searchEngineService.initializeSubscriptions();
    this.deliveryService.initializeSubscriptions();
    this.adminSettingsService.initializeSubscriptions();
    this.orderService.initializeSubscriptions();
    this.bannerService.initializeSubscriptions();
    this.bestSellersService.initializeSubscriptions();
    this.couponCodesService.initializeSubscriptions();
    this.deliverySettingsService.initializeSubscriptions();
    this.helpService.initializeSubscriptions();
    this.variantsService.initializeSubscriptions();
    this.productOptionsService.initializeSubscriptions();
    this.offerService.initializeSubscriptions();
    this.priceRequestService.initializeSubscriptions();
    this.walletService.initializeSubscriptions();
    this.brandsService.initializeSubscriptions();
    this.feedbackService.initializeSubscriptions();
    this.servicesFeatureService.initializeSubscriptions();
    this.wishlistService.initializeSubscriptions();
    this.ratingService.initializeSubscriptions();
    this.referralService.initializeSubscriptions();
    this.multiRegionService.initializeSubscriptions();
    this.mediaService.initializeSubscriptions();
    this.membershipService.initializeSubscriptions();
    this.languageService.initializeSubscriptions();
    //this.themeService.initializeThemeSettings();
    this.designStudioService.initializeService();
    
    this.platform.ready().then(async () => {
     
      this.deliverySettingsService.deliverySettingsDataInStorage();
      this.multiCountriesService.getMultiCountries();
      
      //this.callBackendApis();
      //console.log('version 2 | last update 11 28 2021')
    });



    this.storage.get('appLang').then(lang => {
      if (lang && lang !== undefined) {
        this.translateService.use(lang);
        this.labelService.intializeSubscriptions();
      } else {
        this.translateService.setDefaultLang(this.configService.environment.DEFAULT_APP_LANG);
        this.translateService.use(this.configService.environment.DEFAULT_APP_LANG);
        this.labelService.intializeSubscriptions();
      }
    });

  }
  handleSplashScreen(){//this.splashScreen.hide();
 }

 

// callBackendApis() {
//   //cart.js
//   let updatedCartProducts = firebase.functions().httpsCallable('cart-getUpdatedCartProducts');
//   updatedCartProducts('').then((res) => {console.log(res.data);});

//   //orders.js
//   let orderPaymentDetails = firebase.functions().httpsCallable('orders-getOrderPaymentDetails');
//   orderPaymentDetails().then((res) => {
//     console.log(res.data);
//   });
// }



}
