import { Component, OnInit } from '@angular/core';
import { Events, PopoverController, AlertController, LoadingController, NavParams, ModalController } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NewAddressPage } from "../new-address/new-address.page";
import { UtilsService } from '../services/utils/utils.service';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.page.html',
  styleUrls: ['./select-address.page.scss'],
})
export class SelectAddressPage implements OnInit {
  addressesData: any[] = [];
  showLoader: boolean = true;
  loading: any;
  showAddAddressBtn: boolean = false;
  selectedAddress: any;
  addressFromStorage: any;
  addressType: string = 'shipping';
  constructor(private events: Events,
              private router: Router,
              private alertController: AlertController,
              private loadingController: LoadingController,
              private storage: Storage,
              public navParams: NavParams,
              private UtilsService:UtilsService,
              private modalController: ModalController,
              private route: ActivatedRoute,) {
                this.addressType = navParams.get('type');
               }

  ngOnInit() {
    this.initializeSubscription();
    this.events.publish('user:getAllSavedAddresses');
    setTimeout(() => {
      this.showAddAddressBtn = true;
    }, 500);
  }
  ionViewWillEnter() {
    
    this.storage.get('userDefaultAddress').then((address) => {
      this.addressFromStorage = address;
    });
  }
  ngOnDestroy() {
    this.removeSubscription();
  }
  initializeSubscription() {
    this.events.subscribe('user:publishAllSavedAddresses', (allAddresses) => {
      this.addressesData = allAddresses;
      this.UtilsService.consoleLog('address modal address', this.addressesData)
      
      this.showLoader = false;
    })
  }
  
  /*radioSelect(event) {
    //// console.log(event.target.value);
    this.selectedAddress = event.target.value;
  }*/

  modalDismiss(){
    this.modalController.dismiss();
  }

  selectAddress(address){
    this.selectedAddress = address;
  }

  goToPage(page: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        routeFromSelectAddress: true,
        type: this.addressType
      }
    };
    this.router.navigate([page], navigationExtras);
  }

  async addNewAddress() {
    const modal = await this.modalController.create({
    component: NewAddressPage,
    cssClass: 'custom-modal',
    componentProps: { 
      routeFromSelectAddress: true,
      type: this.addressType
    }
    });
    await modal.present();
  }

  async editAddress(address: any) {
    const modal = await this.modalController.create({
      component: NewAddressPage,
      cssClass: 'custom-modal',
      componentProps: { 
        addressData: address,
        addressLength: this.addressesData.length,
        routeFromSelectAddress: true,
        type: this.addressType
      }
      });
      await modal.present();
  }
  
  onClickDeliverHere() {
    if(!this.selectedAddress) {
      this.selectedAddress = this.addressesData[0];
    }
    if(this.addressType === 'shipping') {
      this.storage.set('userDefaultAddress', this.selectedAddress);
    } else {
      this.storage.set('userBillingAddress', this.selectedAddress);
    }
    this.modalDismiss();
  }
  
  removeSubscription() {
    this.events.unsubscribe('user:publishAllSavedAddresses');
  }

}
