<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{customOption.name}}</ion-title>
    <ion-buttons slot="end">
      <!-- <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button> -->
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  
  <div class="cpo-fields" *ngIf="customOption.details">
    <p>{{'CUSTOM_PAYMENT_OPTION.details_for' | translate}} {{customOption.name}}</p>
    <p><strong>{{customOption.details}}</strong></p>
  </div>

  <div class="cpo-fields m-v-16" *ngIf="customOption.image?.url">
    <div class="flex-wrapper">
      <div>
        <p>{{'CUSTOM_PAYMENT_OPTION.image_for' | translate}} {{customOption.name}}</p>
      </div>
      <div>
        <a [href]="customOption.image.url" download target="_blank" class="btn-1">
          {{'UPI_MANUAL_PAYMENT.download' | translate}}
        </a>
      </div>
    </div>
    <div class="ion-text-center">
      <img class="cpo-qr" [src]="customOption.image.url">
    </div>
  </div>

  <div class="cpo-fields">
    <p>{{'CUSTOM_PAYMENT_OPTION.add_your_details' | translate}}</p>
    <ion-textarea rows="4" [(ngModel)]="textDetails" placeholder="{{'CUSTOM_PAYMENT_OPTION.text_details_ph' | translate}}"></ion-textarea>
  </div>

  <div class="upload-btn-wrapper m-v-16">
    <button class="upload-btn btn-2 i-start" expand="full"> <i class="flaticon-null-16"></i>{{'CUSTOM_PAYMENT_OPTION.upload' | translate}}</button>
    <input type="file" name="myfile" (change)="uploadPaymentSS($event.target.files)" />
  </div>

  <div class="cpo-fields" *ngIf="userScreenShot">
    <p>{{'CUSTOM_PAYMENT_OPTION.uploaded_image' | translate}}</p>
    <div class="img-wrapper">
      <ion-button class="remove-btn" (click)="removeSS()"  shape="round" color="danger">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
      <ion-img class="cpo-ss" src="{{userScreenShot}}"></ion-img>
    </div>
  </div>

</ion-content>

<ion-footer>
  <ion-button (click)="completePayment()" expand="full" class="btn-2">
    {{'CUSTOM_PAYMENT_OPTION.complete_payment' | translate}}
  </ion-button>
</ion-footer>