import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LabelService } from '../services/label/label.service';
import { SharedService } from '../services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { WidgetsService } from '../services/widgets/widgets.service';
import { encodeURL } from '../services/parseURL';

@Component({
    selector: 'app-promo-modal',
    templateUrl: './promo-modal.page.html',
    styleUrls: ['./promo-modal.page.scss'],
})
export class PromoModalPage implements OnInit {
    @ViewChild('imageBox', {
        static: false
    }) imageBox;

    popup: any;
    PROMO_POPUP_LABELS: any;
    CO_CATEGORY_GRID_LABELS: any;
    constructor(private router: Router,
        private utilsService: UtilsService,
        private modalController: ModalController,
        private labelService: LabelService,
        private ngZone: NgZone,
        private sharedService: SharedService,
        private widgetService: WidgetsService) { }

    ngOnInit() {
        // console.log(this.popup);
        this.PROMO_POPUP_LABELS = this.labelService.labels['PROMO_POPUP'];
        this.CO_CATEGORY_GRID_LABELS = this.labelService.labels['CO_CATEGORY_GRID'];
    }

    onClickImgDocument(event) {
        console.log('onClickImgDocument')
        if (!this.imageBox.nativeElement.contains(event.target)) { // or some similar check
            this.ngZone.run(() => {
                this.closePopup();
            });
        }
    }

    closePopup() {
        this.modalController.dismiss();
    }


    async onClickPopup() {
        this.closePopup();
        const linkType = this.popup.link.type;
        const id = this.popup.link.id;
        const name = this.popup.link.name;
        if (linkType === this.PROMO_POPUP_LABELS['category']) {
            // const category: any = await this.widgetService.getCBSDataFromId(id, 'categories');
            // this.sharedService.manageExclusiveCategories(category.isExclusive || false, id, 'parentCategory');
            let cname = encodeURL(name);
            if (this.popup.link.hasOwnProperty('isSubcategories') && this.popup.link.isSubcategories) {
                this.router.navigate([`shop-subcategories/${cname}/${id}`]);
            } else {
                this.router.navigate([`shop/category/${cname}/${id}`]);
            }
        }

        else if (linkType === this.PROMO_POPUP_LABELS['brand']) {
            let brandname = encodeURL(name);
            this.router.navigate([`shop/brand/${brandname}/${id}`]);
        }

        else if (linkType === this.PROMO_POPUP_LABELS['service']) {
            const res = await this.sharedService.checkLoginStatus(`${this.CO_CATEGORY_GRID_LABELS['must_be_user_to_access_services_msg']}`);
            if (res) {
                const navigationExtras: NavigationExtras = {
                    state: {
                        serviceData: this.popup.link.serviceData
                    }
                };
                this.router.navigate(['service-response'], navigationExtras);
            }
        }

        else if (linkType === this.PROMO_POPUP_LABELS['refer_and_earn'] || linkType === 'referEarn') {
            const userId = await this.sharedService.getStorageUid();
            if (!userId) {
                this.utilsService.openLoginModal();
            } else {
                this.router.navigate(['user-referral']);
            }
        }

        else if (linkType === this.PROMO_POPUP_LABELS['search']) {
            const navigationExtras: NavigationExtras = {
                state: {
                    searchTxt: name
                }
            };
            this.router.navigate(['search-items'], navigationExtras);


        } else if (linkType === this.PROMO_POPUP_LABELS['product']) {
            let productName = encodeURL(name);
            this.router.navigate([`product-details/${productName}/${id}`]);
        }

        else if (linkType === this.PROMO_POPUP_LABELS['membership'] || linkType === 'membership') {
            const userId = await this.sharedService.getStorageUid();
            if (!userId) {
                this.utilsService.openLoginModal();
            } else {
                this.router.navigate(['membership']);
            }
        } else if (linkType === 'external') {
            if (this.popup.link.url) {
                window.open(this.popup.link.url, '_blank');
            }
        } else if (linkType === 'contactUs') {
            this.router.navigate(['contact']);
        } else if (linkType === 'feedback') {
            const userId = await this.sharedService.getStorageUid();
            if (!userId) {
                this.utilsService.openLoginModal();
            } else {
                this.router.navigate(['user-feedback']);
            }
        } else if (linkType === 'offers') {
            this.router.navigate(['all-offers']);
        } else {
            return null;
        }
    }

}
